import React, {useEffect} from "react";
import Auth from "services/Auth";

const LogOut = () => {
    const logUserOut = () => {
        Auth.logout()
            .then((res) => {
                window.location = "#/login";
            }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        let logoutTimer = setTimeout(() => logUserOut(), 1000);
        return () => {
            clearTimeout(logoutTimer);
        };
    })
    return (
        <h1>Uitloggen. We gaan nu naar de login pagina...</h1>
    )
}

export default LogOut;
