import React, {Fragment} from "react";

// Layout
import AppHeader from "../../../../Layout/AppHeader";


// Components
import ThemeOptions from "../../../../Layout/ThemeOptions";
import AppFooter from "Layout/AppFooter";
import HistoryTable from "./HistoryTable";

const Substitutions = ({match}) => {

    return (
        <Fragment>
            <AppHeader/>
            <ThemeOptions/>
                <div>
                    <HistoryTable/>
                </div>
            <AppFooter/>
        </Fragment>
    )
};

export default Substitutions;
