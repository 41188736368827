import React, { useState } from 'react'
import { ButtonDropdown, DropdownMenu, DropdownToggle, Nav, NavLink } from 'reactstrap'
import { iconSwitch } from '../utils'
import { BsCheckCircle } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

const GroupStatus = ({ data, changeStatus }) => {
    const [isOpen, setOpen] = useState(false);

    const handleSelect = (id, action) => {
        changeStatus(id, action);
        setOpen(false);
    };
    return (
        <div className='ms-5'>
            <ButtonDropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
                <DropdownToggle color="link" className="p-0" disabled={data.row.original.locked} onClick={() => setOpen(true)}>
                    {iconSwitch(data.row.original.vValue, "bestuur", data.row.original.locked)}
                </DropdownToggle>
                {!data.row.original.locked && (
                    <DropdownMenu end className="rm-pointers dropdown-menu-lg functies-dropdown-width">
                        <Nav vertical className="dropdown-custom-link">
                            <NavLink className="link-gap" href="#" onClick={() => handleSelect(data.row.original.id, '+')}>
                                <span><BsCheckCircle color='#39A825' fontSize={"21px"} /></span>
                            </NavLink>

                            <NavLink className="link-gap navlink-border" href="#" onClick={() => handleSelect(data.row.original.id, '?')}>
                                <span><BsQuestionCircle color='#F2983E' fontSize={"21px"} /></span>
                            </NavLink>

                            <NavLink className="link-gap" href="#" onClick={() => handleSelect(data.row.original.id, '-')}>
                                <span><TiDeleteOutline color='#FB2E5F' fontSize={"28px"} /></span>
                            </NavLink>
                        </Nav>
                    </DropdownMenu>
                )}
            </ButtonDropdown>
        </div>
    )
}

export default GroupStatus