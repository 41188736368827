import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import React from "react"; // optional
import UserInfoModal from "Components/Modal/UserInfoModal/UserInfoModal";
import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";
import {Link} from "react-router-dom";
import { parseJSON } from 'date-fns';
import TextLimit from 'Api/TextLimit';

function AbsentCell({value}) {
    let text = " ";
    let longText = "";
    var id = 0;
    var substitutionId = 0;
    var status = "";

    if (value && value.length !== 0) {
        if (value.length > 1) {
            text = "Multiple";
        }

        if (value.length === 1) {
            longText = value[0].vFirstName + " " + value[0].vLastName;
            text = value[0].vFirstName + " " + (value[0].vLastName ? value[0].vLastName.charAt(0) : "");
            id = value[0].iSubstitutorAdminId; 
            substitutionId = value[0].iSubstitutionId;
            status = value[0].eSubStatus;
        }
    }
    return <Tippy
    
        content={ 
            longText === 'X null' || id === 0 ||status === 'Aangevraagd' || status === 'Geannuleerd'
                ?  'Openstaand' 
                : longText
        } 
        placement="bottom">

        {
            ( ( status === 'Aangevraagd' || status === 'Geannuleerd' || id === 0 ) && substitutionId > 0 )  
            ? <span><HiOutlineDotsCircleHorizontal font-size={"24px"} /></span> 
            : <span><UserInfoModal  id={id} name={text}  substitutionId={substitutionId} /></span>
        }
    </Tippy>
}

function AfwezigeCell({value}) {    
    console.log("Test");
    console.log(value);
    return <Tippy content={value} placement="bottom"><a href="/verzoeken/">{value}</a></Tippy>
}

export function convertHeaders(tableHeaders) {

    let formattedHaeders = tableHeaders.map((n, index) => {
        return {
            Header: () => n.date,
            id: index + 1,
            columns: [
                {
                    Header: "Ochtend",
                    id: n.day.toLowerCase() + "_ochtend",
                    accessor: n.day.toLowerCase() + "_ochtend",
                    Cell: AbsentCell
                },
                {
                    Header: "Middag",
                    id: n.day.toLowerCase() + "_middag",
                    accessor: n.day.toLowerCase() + "_middag",
                    Cell: AbsentCell
                }
            ]
        }
    });
    formattedHaeders.unshift({
        Header: ' ',
        id: 0,
        columns: [
            {
                Header: 'Afwezige',
                accessor: value => (<Link to={`/verzoeken/${value.iSubstitutionId}`}>
                    {
                       window.innerWidth > 1250 ? (TextLimit.AfwezigeLimitOptionOne(value.vFirstName, value.vMiddleName, value.vLastName) == '  ' ? '---' : TextLimit.AfwezigeLimitOptionOne(value.vFirstName, value.vMiddleName, value.vLastName))
                        : (TextLimit.AfwezigeLimitOptionTwo(value.vFirstName, value.vMiddleName, value.vLastName) == '  ' ? '---' : TextLimit.AfwezigeLimitOptionTwo(value.vFirstName, value.vMiddleName, value.vLastName))
                    }
                </Link>)
            },
            {
                Header: 'Code',
                accessor: value => (
                    TextLimit.codeLimitOptionOne(value.code !== undefined ? value.code : '')
                )
            },
            {
                Header: 'School',
                accessor: value => (
                    window.innerWidth > 1250 ?
                    TextLimit.limitStringLargeScreen(value.school)
                    : TextLimit.limitStringSmallScreen(value.school)
                )
            }
        ]
    });

    return formattedHaeders;
}

