import { Table, NavLink, Nav, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, Input,Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import Tippy from "@tippyjs/react";
import { ReactComponent as Memo } from "../../../../../assets/utils/images/svgs/Memo.svg";
import { ReactComponent as Football } from "../../../../../assets/utils/images/svgs/Coorfootball.svg";
import { ReactComponent as Calendar } from "../../../../../assets/utils/images/svgs/Calendar.svg";
import { ReactComponent as BlueDelete } from "../../../../../assets/utils/images/svgs/bluedelete.svg";
import { ReactComponent as Thumbsup } from "../../../../../assets/utils/images/svgs/thumbsup.svg";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextLimit from "Api/TextLimit";
import { ReactComponent as Small_Arrow } from "../../../../../assets/utils/images/svgs/Small_Arrow.svg"
import { ReactComponent as Save } from "../../../../../assets/utils/images/svgs/save_two.svg";
import VerzoekenService from "Api/Verzoeken";

const RequestList = (props) => {

    const [tableData, setTableData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [opmerkingId, setOpmerkingId] = useState(0);
    const [opmerkingValue, setOpmerkingValue] = useState('');
    const [uniqueIds, setUniqueIds] = useState([]);

    useEffect(() => {
        moment.locale('fr');
        if(props.statusCount == 0){
            setUniqueIds([])
        }
    }, [props.statusCount]);

    useEffect(() => {
        if (props.list) {
            setTableData(props.list);
        }
    }, [props.list]);
    
    const params = useParams();
    const substitutionId = params.id || '';

    const iconSwitch = (param) => {
        switch (param) {
            case 'Ingepland':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"18px"} /></span></Tippy>;
            case 'Aangevraagd':
                return <Tippy content={param} placement="bottom"><span><BsQuestionCircle color='#F2983E' font-size={"18px"} /></span></Tippy>;
            case 'Geannuleerd':
                return <Tippy content={param} placement="bottom"><span><TiDeleteOutline color='#FB2E5F' font-size={"24px"} /></span></Tippy>;
            default:
                return <Tippy content={'Openstaand'} placement="bottom"><span>----</span></Tippy>;
        }
    }

    const updateShiftdayType = (iSubsDateId, iDayTypeId) => {
        let formData = new FormData();
        formData.append('iSubstitutionId', substitutionId);
        formData.append('iSubsDateId', iSubsDateId);
        formData.append('iDayTypeId', iDayTypeId);

        VerzoekenService.updateShiftType(formData).then((response) => {
            if (response.data.status === true) {
                toast["success"]("ploegendaggedeelte succesvol bijgewerkt")
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const updateWtfValue = (iSubsDateId, value) => {
        let formData = new FormData();
        formData.append('iSubstitutionId', substitutionId);
        formData.append('iSubsDateId', iSubsDateId);
        formData.append('value', value);

        VerzoekenService.updateWtfValue(formData).then((response) => {
            if (response.data.status === true) {
                // toast["success"]("wtf-waarde is succesvol bijgewerkt")
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const updateWtfWithCheck = (iSubsDateId, wtf, wtfValue, vHour, vHourLang, vHourParttimers) => {

        let formData = new FormData();
        formData.append('iSubstitutionId', substitutionId);
        formData.append('iSubsDateId', iSubsDateId);

        if (wtf == 'K') {
            formData.append('value', vHour);
        } else if (wtf == 'L') {
            formData.append('value', vHourLang);
        } else if (wtf == 'P') {
            formData.append('value', vHourParttimers);
        } else if (wtf == 'H') {
            formData.append('value', wtfValue);
        } else if (wtf == '?') {
            formData.append('value', '-');
        }
        
        VerzoekenService.updateWtfValue(formData).then((response) => {
            if (response.data.status === true) {
                toast["success"]("wtf-waarde is succesvol bijgewerkt")
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const updateColumnValue = (id, columnName, newValue) => {
        setTableData(prevData => {
            return prevData.map(row => {
                if (row.data.Ochtend !== undefined && row.data.Ochtend.iSubsDateId === id) {
                    return { ...row, data: { ...row.data, Ochtend: { ...row.data.Ochtend, [columnName]: newValue } } };
                }

                if (row.data.Middag !== undefined && row.data.Middag.iSubsDateId === id) {
                    return { ...row, data: { ...row.data, Middag: { ...row.data.Middag, [columnName]: newValue } } };
                }
                return row;
            });
        });
    };

    const updateColumnValueWithWtfCheck = (id, columnName, type) => {
        setTableData(prevData => {
            return prevData.map(row => {
                if (row.data.Ochtend !== undefined && row.data.Ochtend.iSubsDateId === id && type == 'K') {
                    return { ...row, data: { ...row.data, Ochtend: { ...row.data.Ochtend, [columnName]: row.data.Ochtend.vHour } } };
                } else if (row.data.Ochtend !== undefined && row.data.Ochtend.iSubsDateId === id && type == 'L') {
                    return { ...row, data: { ...row.data, Ochtend: { ...row.data.Ochtend, [columnName]: row.data.Ochtend.vHourLang } } };
                } else if (row.data.Ochtend !== undefined && row.data.Ochtend.iSubsDateId === id && type == 'P') {
                    return { ...row, data: { ...row.data, Ochtend: { ...row.data.Ochtend, [columnName]: row.data.Ochtend.vHourParttimers } } };
                } else if (row.data.Ochtend !== undefined && row.data.Ochtend.iSubsDateId === id && type == '?') {
                    return { ...row, data: { ...row.data, Ochtend: { ...row.data.Ochtend, [columnName]: '-' } } };
                }   

                if (row.data.Middag !== undefined && row.data.Middag.iSubsDateId === id && type == 'K') {
                    return { ...row, data: { ...row.data, Middag: { ...row.data.Middag, [columnName]: row.data.Ochtend.vHour } } };
                } else if (row.data.Middag !== undefined && row.data.Middag.iSubsDateId === id && type == 'L') {
                    return { ...row, data: { ...row.data, Middag: { ...row.data.Middag, [columnName]: row.data.Middag.vHourLang } } };
                } else if (row.data.Middag !== undefined && row.data.Middag.iSubsDateId === id && type == 'P') {
                    return { ...row, data: { ...row.data, Middag: { ...row.data.Middag, [columnName]: row.data.Middag.vHourParttimers } } };
                } else if (row.data.Middag !== undefined && row.data.Middag.iSubsDateId === id && type == '?') {
                    return { ...row, data: { ...row.data, Middag: { ...row.data.Middag, [columnName]: '-' } } };
                }
                return row;
            });
        });
    };

    const openOpmerkingModel = (id, value) => {
        setOpmerkingId(id);
        setOpmerkingValue(value);
        setIsOpen(true)
    }

    const updateMemo = () => {
        let formData = new FormData();
        formData.append('id', substitutionId);
        formData.append('iSubsDateId', opmerkingId);
        formData.append('type', 5);
        formData.append('memo', opmerkingValue);

        VerzoekenService.updateMemo(formData).then((response) => {
            if (response.status === 200) {
                toast["success"]("Memo succesvol opgeslagen")
                setIsOpen(false);
                props.getRequests();
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const handleChangedStatus = (currentStatus, newStatus, substitutionId) => {
        if (newStatus !== currentStatus) {
            if (!uniqueIds.includes(substitutionId)) {
                setUniqueIds([...uniqueIds, substitutionId]);
                if (!props.changedStatusIds.includes(substitutionId)) {
                    props.setChangedStatusIds([...props.changedStatusIds, substitutionId]);
                }
            }
        }
    }

    if (!props.list) return null;

    return (
        <>
            <Table hover size="sm" className="mb-0 coor-verzoeken-table">
                <thead>
                    <tr>
                        <th className="text-lef w-32 table-right-border" colSpan="2">&nbsp;</th>
                        <th colSpan="6" className="table-right-border">Ochtend</th>
                        {/* <th className="w-6">&nbsp;</th> */}
                        <th colSpan="6" className="">Middag</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th className="text-left table-right-border" style={{width: '80px'}}>Datum</th>
                        <th>Vervanger</th>
                        <th>DT</th>
                        <th>WTF</th>
                        <th>Info</th>
                        <th>Status</th>
                        <th className="table-right-border">Actie</th>


                        <th>Vervanger</th>
                        <th>DT</th>
                        <th>WTF</th>
                        <th>Info</th>
                        <th>Status</th>
                        <th>Actie</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((block, i) => {
                        return (
                            <tr >
                                <td class="text-capitalize">
                                    {new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}
                                </td>
                                <td className="cursor-pointer table-right-border ml-0" style={{width: '80px'}}>
                                    {new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", {
                                        year: '2-digit',
                                        month: '2-digit',
                                        day: '2-digit'
                                    })}
                                </td>
                                {
                                    block.data.Ochtend ?
                                        <>
                                            <td>
                                                {
                                                    block.data.Ochtend.Name !== '' && block.data.Ochtend.Name !== null ?
                                                        window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(block.data.Ochtend.vFirstName, block.data.Ochtend.vMiddleName, block.data.Ochtend.vLastName)
                                                            : TextLimit.AfwezigeLimitOptionTwo(block.data.Ochtend.vFirstName, block.data.Ochtend.vMiddleName, block.data.Ochtend.vLastName) : '----'
                                                }
                                            </td>
                                            <td>
                                                {block.data.Ochtend.iSubstitutorAdminId != 0 ?
                                                    <select onChange={(e) => updateShiftdayType(block.data.Ochtend.iSubsDateId, e.target.value)}>
                                                        <option value="0">&nbsp;</option>
                                                        {props.dayTypeList.map((key) => {
                                                            return (
                                                                <option value={key.id} selected={key.id == block.data.Ochtend.iDayTypeId ? true : false}>{key.abbr}</option>
                                                            )
                                                        })
                                                        }
                                                    </select> : block.data.Ochtend.Name != '' ? block.data.Ochtend.ctDefaultValue : '----'
                                                }

                                            </td>
                                            <td className="coord-request-table-td">
                                                <div style={{ display: 'flex', alignItems: 'center', borderBottom: 'none', }}>
                                                {
                                                    block.data.Ochtend.Name !== '' ?
                                                        (
                                                            <>
                                                                <span>
                                                                    <Input
                                                                        style={{ width: '60px', border: 'none' }}
                                                                        value={block.data.Ochtend.wtfValue !== null ? block.data.Ochtend.wtfValue : '-'}
                                                                        onChange={(e) => {
                                                                            updateWtfValue(block.data.Ochtend.iSubsDateId, e.target.value)
                                                                            updateColumnValue(block.data.Ochtend.iSubsDateId, 'wtfValue', e.target.value)
                                                                        }}
                                                                        min="0"
                                                                        max="1"
                                                                        size="5"
                                                                        maxlength="7"
                                                                        readOnly={block.data.Ochtend.wtf != 'H' ? true : false}
                                                                    />
                                                                </span>
                                                                <span>
                                                                    <select onChange={(e) => {
                                                                        updateWtfWithCheck(block.data.Ochtend.iSubsDateId, e.target.value, block.data.Ochtend.wtfValue, block.data.Ochtend.vHour, block.data.Ochtend.vHourLang, block.data.Ochtend.vHourParttimers)
                                                                        props.updateWtf(block.data.Ochtend.iSubsDateId, e.target.value)
                                                                        updateColumnValue(block.data.Ochtend.iSubsDateId, 'wtf', e.target.value)
                                                                        updateColumnValueWithWtfCheck(block.data.Ochtend.iSubsDateId, 'wtfValue', e.target.value)
                                                                    }}>
                                                                        <option value="?" selected={block.data.Ochtend.wtf == '?' ? true : false}>?</option>
                                                                        <option value="K" selected={block.data.Ochtend.wtf == 'K' ? true : false}>K</option>
                                                                        <option value="L" selected={block.data.Ochtend.wtf == 'L' ? true : false}>L</option>
                                                                        <option value="P" selected={block.data.Ochtend.wtf == 'P' ? true : false}>P</option>
                                                                        <option value="H" selected={block.data.Ochtend.wtf == 'H' ? true : false}>H</option>
                                                                    </select>
                                                                </span>
                                                            </>
                                                        ) : '----'
                                                }
                                                </div>
                                            </td>
                                            <td className="icon-styling">
                                                {
                                                    block.data.Ochtend.Name == '' && block.data.Ochtend.eGym !== '+' && '----'
                                                }
                                                {
                                                    block.data.Ochtend.Name !== '' && (block.data.Ochtend.vMemo ?
                                                        <>
                                                            <span className="me-3" onClick={() => openOpmerkingModel(block.data.Ochtend.iSubsDateId, block.data.Ochtend.vMemo)}><Memo /></span>
                                                        </> : '')
                                                }
                                                {
                                                    block.data.Ochtend.eGym == '+' &&
                                                    <Football />
                                                }

                                            </td>
                                            <td>
                                                {
                                                    block.data.Ochtend.iSubstitutorAdminId !== 0 ?
                                                        <UncontrolledButtonDropdown >
                                                            <DropdownToggle color="link" className="p-0">

                                                                {iconSwitch(block.data.Ochtend.eSubStatus)}
                                                            </DropdownToggle>
                                                            <DropdownMenu end className="rm-pointers dropdown-menu-lg functies-dropdown-width">

                                                                <Nav vertical className="dropdown-custom-link">

                                                                    <NavLink className="link-gap" onClick={() => {
                                                                        handleChangedStatus(block.data.Ochtend.eSubStatus, 'Ingepland', block.data.Ochtend.iSubstitutorAdminId);
                                                                        props.changeStatus(block.data.Ochtend.iSubsDateId, 'Ingepland')
                                                                    }}>
                                                                        <span><BsCheckCircle color='#39A825' font-size={"18px"} /> </span>
                                                                    </NavLink>
                                                                    <NavLink className="link-gap" onClick={() => {
                                                                        handleChangedStatus(block.data.Ochtend.eSubStatus, 'Aangevraagd', block.data.Ochtend.iSubstitutorAdminId);
                                                                        props.changeStatus(block.data.Ochtend.iSubsDateId, 'Aangevraagd');
                                                                    }}>
                                                                        <span><BsQuestionCircle color='#F2983E' font-size={"18px"} /> </span>
                                                                    </NavLink>
                                                                    <NavLink className="link-gap" onClick={() => {
                                                                        handleChangedStatus(block.data.Ochtend.eSubStatus, 'Geannuleerd', block.data.Ochtend.iSubstitutorAdminId);
                                                                        props.changeStatus(block.data.Ochtend.iSubsDateId, 'Geannuleerd');
                                                                    }}>
                                                                        <span><TiDeleteOutline color='#FB2E5F' font-size={"24px"} /> </span>
                                                                    </NavLink>
                                                                </Nav>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                        : '----'
                                                }
                                            </td>
                                            <td className="icon-styling table-right-border">
                                                {
                                                    block.data.Ochtend.iSubstitutorAdminId == 0 ?
                                                        <>
                                                            <span className="me-3 svg-pointer"><Calendar /></span>
                                                            <span className="svg-pointer" onClick={() => props.confirmDelete(block.data.Ochtend.iSubsDateId)}><BlueDelete /></span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className="me-3 svg-pointer"><Thumbsup /></span>
                                                            <span className="svg-pointer" onClick={() => props.confirmDelete(block.data.Ochtend.iSubsDateId)}><BlueDelete /></span>
                                                        </>
                                                }

                                            </td>
                                        </>
                                        : <td colSpan="6" className="text-center table-right-border"></td>
                                }

                                {
                                    block.data.Middag ?
                                        <>
                                            <td>
                                                {
                                                    block.data.Middag.Name !== '' && block.data.Middag.Name !== null ?
                                                        (window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(block.data.Middag.vFirstName, block.data.Middag.vMiddleName, block.data.Middag.vLastName)
                                                            : TextLimit.AfwezigeLimitOptionTwo(block.data.Middag.vFirstName, block.data.Middag.vMiddleName, block.data.Middag.vLastName)) : '----'
                                                }
                                            </td>
                                            <td>
                                                {block.data.Middag.iSubstitutorAdminId != 0 ?
                                                    <select onChange={(e) => updateShiftdayType(block.data.Middag.iSubsDateId, e.target.value)}>
                                                        <option value="0">&nbsp;</option>
                                                        {props.dayTypeList.map((key) => {
                                                            return (
                                                                <option value={key.id} selected={key.id == block.data.Middag.iDayTypeId ? true : false}>{key.abbr}</option>
                                                            )
                                                        })
                                                        }
                                                    </select> : block.data.Middag.Name != '' ? block.data.Middag.ctDefaultValue : '----'
                                                }

                                            </td>
                                            <td className="coord-request-table-td">
                                                <div style={{ display: 'flex', alignItems: 'center', borderBottom: 'none', height: '100%' }}>
                                                {
                                                    block.data.Middag.Name !== '' ?
                                                        (
                                                            <>
                                                                <Input
                                                                    style={{ width: '60px', border: 'none' }}
                                                                    value={block.data.Middag.Name !== '' && block.data.Middag.wtfValue !== null ? block.data.Middag.wtfValue : '-'}
                                                                    onChange={(e) => {
                                                                        updateWtfValue(block.data.Middag.iSubsDateId, e.target.value)
                                                                        updateColumnValue(block.data.Middag.iSubsDateId, 'wtfValue', e.target.value)
                                                                    }}
                                                                    min="0"
                                                                    max="1"
                                                                    size="5" 
                                                                    maxlength="7"
                                                                    readOnly={block.data.Middag.wtf != 'H' ? true : false}
                                                                />

                                                                <select onChange={(e) => {
                                                                    updateWtfWithCheck(block.data.Middag.iSubsDateId, e.target.value, block.data.Middag.wtfValue, block.data.Middag.vHour, block.data.Middag.vHourLang, block.data.Middag.vHourParttimers)
                                                                    props.updateWtf(block.data.Middag.iSubsDateId, e.target.value)
                                                                    updateColumnValue(block.data.Middag.iSubsDateId, 'wtf', e.target.value)
                                                                    updateColumnValueWithWtfCheck(block.data.Middag.iSubsDateId, 'wtfValue', e.target.value)
                                                                }}>
                                                                    <option value="?" selected={block.data.Middag.wtf == '?' ? true : false}>?</option>
                                                                    <option value="K" selected={block.data.Middag.wtf == 'K' ? true : false}>K</option>
                                                                    <option value="L" selected={block.data.Middag.wtf == 'L' ? true : false}>L</option>
                                                                    <option value="P" selected={block.data.Middag.wtf == 'P' ? true : false}>P</option>
                                                                    <option value="H" selected={block.data.Middag.wtf == 'H' ? true : false}>H</option>
                                                                </select>
                                                            </>
                                                        ) : '----'
                                                }
                                                </div>
                                            </td>
                                            <td className="icon-styling">
                                                {
                                                    block.data.Middag.Name == '' && block.data.Middag.eGym !== '+' && '----'
                                                }
                                                {
                                                    block.data.Middag.Name !== '' && (block.data.Middag.vMemo  ?
                                                        <>
                                                            <span className="me-3" onClick={() => openOpmerkingModel(block.data.Middag.iSubsDateId, block.data.Middag.vMemo)}><Memo /></span>
                                                        </> : '')
                                                }
                                                {
                                                    block.data.Middag.eGym == '+' &&
                                                    <Football />
                                                }
                                            </td>
                                            <td>
                                                {
                                                    block.data.Middag.iSubstitutorAdminId !== 0 ?
                                                        <UncontrolledButtonDropdown >
                                                            <DropdownToggle color="link" className="p-0">

                                                                {iconSwitch(block.data.Middag.eSubStatus)}
                                                            </DropdownToggle>
                                                            <DropdownMenu end className="rm-pointers dropdown-menu-lg functies-dropdown-width">

                                                                <Nav vertical className="dropdown-custom-link">

                                                                    <NavLink className="link-gap" onClick={() => {
                                                                        handleChangedStatus(block.data.Middag.eSubStatus, 'Ingepland', block.data.Middag.iSubstitutorAdminId);
                                                                        props.changeStatus(block.data.Middag.iSubsDateId, 'Ingepland');
                                                                    }}>
                                                                        <span><BsCheckCircle color='#39A825' font-size={"18px"} /> </span>
                                                                    </NavLink>
                                                                    <NavLink className="link-gap" onClick={() => {
                                                                        handleChangedStatus(block.data.Middag.eSubStatus, 'Aangevraagd', block.data.Middag.iSubstitutorAdminId);
                                                                        props.changeStatus(block.data.Middag.iSubsDateId, 'Aangevraagd');
                                                                    }}>
                                                                        <span><BsQuestionCircle color='#F2983E' font-size={"18px"} /> </span>
                                                                    </NavLink>
                                                                    <NavLink className="link-gap" onClick={() => {
                                                                        handleChangedStatus(block.data.Middag.eSubStatus, 'Geannuleerd', block.data.Middag.iSubstitutorAdminId);
                                                                        props.changeStatus(block.data.Middag.iSubsDateId, 'Geannuleerd');
                                                                    }}>
                                                                        <span><TiDeleteOutline color='#FB2E5F' font-size={"24px"} /> </span>
                                                                    </NavLink>
                                                                </Nav>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                        : '----'
                                                }
                                            </td>
                                            <td className="icon-styling">
                                                {
                                                    block.data.Middag.iSubstitutorAdminId == 0 ?
                                                        <>
                                                            <span className="me-3 svg-pointer"><Calendar /></span>
                                                            <span className="svg-pointer" onClick={() => props.confirmDelete(block.data.Middag.iSubsDateId)}><BlueDelete /></span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className="me-3 svg-pointer"><Thumbsup /></span>
                                                            <span className="svg-pointer" onClick={() => props.confirmDelete(block.data.Middag.iSubsDateId)}><BlueDelete /></span>
                                                        </>
                                                }

                                            </td>
                                        </>
                                        : <td colSpan="6" className="text-center"></td>
                                }
                            </tr>
                        )
                    })
                    }
                </tbody>
            </Table>

            <Modal id={"tooltip"} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling">
                <ModalHeader>
                    <div className="modal-header-styling">
                        Opmerking
                    </div>
                    <div className="cross-icon-color">
                        <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setIsOpen(false)}> </i>
                    </div>
                </ModalHeader>

                <ModalBody className="d-flex bg-white rounded">
                    <textarea
                        value={opmerkingValue}
                        className="form-style opmerking-input-styling"
                        rows="6"
                        onChange={(e) => setOpmerkingValue(e.target.value)}
                    ></textarea>
                </ModalBody>
                <ModalFooter>
                    <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" onClick={() => updateMemo()}>
                        <Save />
                        Opslaan
                    </Button>
                    <Button className="d-flex align-items-center btn-color-style back-btn" size="sm" outline color="primary" onClick={() => setIsOpen(false)}>
                        <Small_Arrow />
                        Terug
                    </Button>
                </ModalFooter>
            </Modal>
        </>
        
    );
};

export default RequestList;