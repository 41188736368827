import axiosInstance from 'Api';

const contractType = (id, iAdminId) => {
    return axiosInstance.get(`contracttypes?iCommunityId=${id}&iAdminId=${iAdminId}`);
};

const updateInstellingenDetail = (data) => {
    return axiosInstance.post('gebruiker/save-instellingen', data)
};

const contractUpdate = (data) => {
    return axiosInstance.post('gebruiker/save-contract', data)
};

const GebruikerInstellingenService = {
    contractType,
    updateInstellingenDetail,
    contractUpdate
};
export default GebruikerInstellingenService;