import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';

const ContactDetails = ({ data, changeHandler, errorField }) => {
    const defaultValues = {
        iMobileNumber: data?.iMobileNumber || '',
        vEmail: data?.vEmail || '',
        vPhonePrivate: data?.vPhonePrivate || '',
        vBusinessEmail: data?.vBusinessEmail || '',
        vPhoneWork: data?.vPhoneWork || ''
    };
    
    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Contactgegevens</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <FormGroup className={`form-element ${errorField === 'iMobileNumber' ? 'error' : ''}`}>
                                <Label for="iMobileNumber">Mobiele telefoon</Label>
                                <Input
                                    name="iMobileNumber"
                                    type="text"
                                    value={values.iMobileNumber}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('iMobileNumber', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vEmail' ? 'error' : ''}`}>
                                <Label for="vEmail">E-mail privé</Label>
                                <Input
                                    name="vEmail"
                                    type="text"
                                    value={values.vEmail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vEmail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vPhonePrivate' ? 'error' : ''}`}>
                                <Label for="vPhonePrivate">Telefoon privé</Label>
                                <Input
                                    name="vPhonePrivate"
                                    type="text"
                                    value={values.vPhonePrivate}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vPhonePrivate', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vBusinessEmail' ? 'error' : ''}`}>
                                <Label for="vBusinessEmail">E-mail werk</Label>
                                <Input
                                    name="vBusinessEmail"
                                    type="text"
                                    value={values.vBusinessEmail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vBusinessEmail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vPhoneWork' ? 'error' : ''}`}>
                                <Label for="vPhoneWork">Telefoon werk</Label>
                                <Input
                                    name="vPhoneWork"
                                    type="text"
                                    value={values.vPhoneWork}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vPhoneWork', e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default ContactDetails;
