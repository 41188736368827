import React, {Fragment} from "react";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Card, CardBody} from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import List from "./list";

 const TabsExample =()=> {
 
        return (
            <Fragment>
                <PageHeading/>
                <TransitionGroup>
                    <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                                   timeout={1500} enter={false} exit={false}>
                        <Card className="main-card mb-3">
                            <CardBody>
                                <List/>
                            </CardBody>
                        </Card>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        )
    }
    export default TabsExample
