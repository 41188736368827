import PageHeading from "Layout/AppMain/PageHeading";
import React, {Fragment} from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Card, CardBody, Col, Row} from "reactstrap";

const Default = () => {
    return (
        <Fragment>
            <PageHeading/>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                                timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12" lg="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3">
                                    Vervanger Page
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
};

export default Default;