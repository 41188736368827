import axiosInstance from 'Api';

const saveFeatureApi = (data) => {
    return axiosInstance.post('functie/save', data)
};

const fetchFunctieData = (id) => {
    return axiosInstance.get(`functie?communityId=${id}`);
};

const deleteFunctie = (iSubGroupId, iCommunityId)=>{
    return axiosInstance.get(`functie/delete/${iSubGroupId}?iCommunityId=${iCommunityId}`)
}

const toggleFunctieValue = (data)=>{
    return axiosInstance.post("functie/toggle", data)
}

const communityList = () => {
    return axiosInstance.get("travelcost/community-list");
};

const CoordinatorInstellingenService = {
    communityList,
    saveFeatureApi,
    fetchFunctieData,
    deleteFunctie,
    toggleFunctieValue
};
export default CoordinatorInstellingenService;