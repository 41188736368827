import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import TitleComponent1 from "./PageTitleExamples/Variation1";
import TitleComponent2 from "./PageTitleExamples/Variation2";
import TitleComponent3 from "./PageTitleExamples/Variation3";
import AppBreadcrumb from "../AppBreadcrumb";
import {Button, UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
class PageTitle extends Component {


  render() {
    let {
      setChatActive,
        chatActive
    } = this.props;

    return (
      <>
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
                <AppBreadcrumb />
            </div>
            <div className="page-title-actions">

             
            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
