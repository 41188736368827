import { Button, Card, CardBody, CardFooter, FormGroup, Label } from 'reactstrap'
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import Features from './Features';
import Loader from 'react-loaders';
import { userTabs } from '../utils';
import { useEffect, useState } from 'react';
import GebruikerFunctiesService from 'Api/Gebruikers/GebruikersView/Functies';
import GebruikerViewService from 'Api/Gebruikers/GebruikersView';

const Functies = ({ setActiveTab, adminCommunityList, currentUser, filterCommunity, setFilterCommunity }) => {
    const [functiesData, setFunctiesData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [gymnastiekValue, setGymnastiekValue] = useState(functiesData?.eGym || '');

    useEffect(() => {
        setIsLoading(true);
        fetchFunctiesData();
    }, [filterCommunity]);

    const fetchFunctiesData = async () => {
        const data = {
            iCommunityId: String(filterCommunity[0].iCommunityId)
        };
        try {
            const response = await GebruikerFunctiesService.getFunctiesData(data, currentUser.data.clooserId);
            if (response.data.status) {
                setFunctiesData(response.data.data)
                setIsLoading(false);
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    };

    const updateGymnastiekValue = async (value) => {
        const data = {
            iAdminId: currentUser.data.clooserId,
            attr: 'eGym',
            eGym: value,
        }
        try {
            const response = await GebruikerViewService.updateGebruikerDetail(data);
            console.log(response.data)
        } catch (error) {
            console.error(`API error:`, error);
        }
    };

    return (
        <>
            {isLoading ?
                <Card className="main-card">
                    <CardBody className="d-flex flex-column">
                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                    </CardBody>
                </Card> : <>
                    <div className='row functies'>
                        <div className='col-lg-6'>
                            <Features filterCommunity={filterCommunity} data={functiesData} currentUser={currentUser} fetchFunctiesData={fetchFunctiesData}/>
                        </div>
                        <div className='col-lg-6'>
                            <Card className="main-card">
                                <CardBody className="d-flex flex-column">
                                    <h5 className="mb-4">Gymbevoegdheid</h5>
                                    <FormGroup className="form-element">
                                        <Label for="link">Gymnastiek</Label>
                                        <select className="form-select" value={gymnastiekValue || functiesData?.eGym} onChange={(e) => { setGymnastiekValue(e.target.value); updateGymnastiekValue(e.target.value) }}>
                                            <option value={''}>Select</option>
                                            <option value='Nee'>Nee</option>
                                            <option value='Ja'>Ja</option>
                                            <option value='i.o.'>In opleiding</option>
                                        </select>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="chat-layout-footer">
                        <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                            <div className="d-flex gap-3">
                                <select className={`form-select`} value={filterCommunity[0]?.iCommunityId} onChange={(e) => { setFilterCommunity(adminCommunityList.filter(item => item?.iCommunityId === Number(e.target.value))) }}>
                                    <option value={''} disabled>Alle besturen</option>
                                    {adminCommunityList.map(item => {
                                        return <option value={item.iCommunityId} key={item.iCommunityId}>{item.vCommunityName}</option>
                                    })}
                                </select>
                                <Button
                                    outline
                                    className="btn-outline-primary-blue ms-auto"
                                    onClick={() => {
                                        setActiveTab(userTabs.Documenten)
                                    }}
                                    type="button"
                                    color="primary"
                                >
                                    <BackIcon />
                                </Button>
                            </div>
                        </CardFooter>
                    </div>
                </>
            }
        </>
    )
}

export default Functies