import { combineReducers } from 'redux'
import ThemeOptions from "./ThemeOptions";
import UserProfile from "./UserProfile";
import UserPermission from './UserPermission';
import MenuCount from './MenuCount';
import VervangerMenuCount from './VervangerMenuCount';
import formReducer from './formReducer';


export default combineReducers({
  ThemeOptions,
  UserProfile,
  UserPermission,
  MenuCount,
  VervangerMenuCount,
  formReducer,
})
