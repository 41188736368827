import { Table } from "reactstrap";
import moment from "moment";
import React from "react";
import {
    Col,
    FormGroup,
    InputGroup,
    Label,
    Row,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    Nav,

    Button,
    Input
} from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
registerLocale("nl", nl);

const PeriodeOverzicht = ({ arrTotal, communityList, saveStartDate, saveEndDate, startDate, endDate, saveCommunityId, show, isOpen, communityId }) => {

    const columns = React.useMemo(
        () => [
            {
                header: () => "Periode",
                accessorKey: "wtf",
                cell: () => { return (startDate + ' t/m ' + endDate) },
            },
            {
                header: () => "Uren  totaal",
                accessorKey: "uren",
            },
        ],
        []
    );
    return (
        <Row className="begindatum-datepicker-style">
            <Col lg={3} sm={5} className="p-0">
                <FormGroup row className="align-items-center">
                    <Label sm={4} className="overview-page-label">{"Begindatum"}</Label>
                    <Col sm={6} className="p-0">
                        <InputGroup className="periode-input-style">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt} /> 
                            </div>

                            <DatePicker
                                locale="nl"
                                dateFormat="dd-MM-yyyy"
                                // selected={new Date(startDate)}
                                selected={(startDate !== '' && startDate !== undefined) ? new Date(startDate) : new Date()}
                                onChange={(date) => saveStartDate(date)}
                            />

                        </InputGroup>
                    </Col>
                </FormGroup>
            </Col>

            <Col lg={3} sm={5} className="p-0">
                <FormGroup row className="align-items-center">
                    <Label sm={4} className="overview-page-label">{"Einddatum"}</Label>
                    <Col sm={6} className="p-0">
                        <InputGroup className="periode-input-style">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>

                            <DatePicker
                                locale="nl"
                                dateFormat="dd-MM-yyyy"
                                selected={(endDate !== '' && endDate !== undefined) ? new Date(endDate) : new Date()}
                                onChange={(date) => saveEndDate(date)}
                            />

                        </InputGroup>
                    </Col>
                </FormGroup>
            </Col>

            <Col lg={6} sm={2} className="text-end">
                <UncontrolledButtonDropdown isOpen={show}>
                    <DropdownToggle color="link" className="p-0">
                        <Button className="d-flex align-items-center mb-2 me-2 float-end" size="sm" outline onClick={() => isOpen()}>
                            Bestuur filter
                        </Button>
                    </DropdownToggle>
                    <DropdownMenu end className="rm-pointers dropdown-menu-lg  bestuur-dropdown-width periode-dropdown-style">

                        <Nav vertical>
                            <li>
                                <FormGroup
                                    check
                                    inline
                                >
                                    <Label check>
                                        Alle Besturen
                                    </Label>
                                    <Input type="checkbox" value="all" onChange={(value) => saveCommunityId(value.target.value)} className={communityId == 'all' ? "background-color-orange" : ""} />
                                </FormGroup>
                            </li>
                            {communityList.map((value) => {
                                return (
                                    <li>
                                        <FormGroup
                                            check
                                            inline
                                        >
                                            <Label check>
                                                {value.name !== undefined ? value.name : ''}
                                            </Label>
                                            <Input type="radio" value={value.iCommunityId !== undefined ? value.iCommunityId : ''} onChange={(value) => saveCommunityId(value.target.value)} className={communityId == value.iCommunityId ? "background-color-orange" : ""} />
                                        </FormGroup>
                                    </li>
                                )
                            })
                            }
                        </Nav>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </Col>
            <Col sm={6}>
                <Table size="sm" className="mb-0 table table-bordered table-sm table-hover">
                    <thead>
                        <tr>
                            <th>Periode</th>
                            <th>Uren  totaal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{moment(startDate).format('DD-MM-yyyy') + ' t/m ' + moment(endDate).format('DD-MM-yyyy')}</td>
                            <td>{arrTotal['uren']}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default PeriodeOverzicht;