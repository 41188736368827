import React from "react";
import { ReactComponent as Delete } from "../../../../assets/utils/images/svgs/Delete.svg";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { Card, CardBody } from "reactstrap";
import TextLimit from "Api/TextLimit";

const Records = ({ data, deleteDistance, updateDistance }) => {

    const columns = [
        {
            header: () => 'Vervanger / School',
            accessorKey: 'vFirstName',
            cell: value => (
                <div className="d-flex justify-content-between mx-1">
                    <span>{window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                        : TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)}
                    </span>
                    <span className="d-flex align-items-center cursor-pointer" onClick={() => {value.row.original.distance.length != 0 && deleteDistance(value.row.original.iAdminId) }}>
                        <Delete className="ms-2" />
                    </span>
                </div>
            )
        },
        ...data.schoolList.map(school => ({
            header: () => (
                <div className="vertical-header">
                    {school.vSchoolName}
                </div>
            ),
            accessorKey: 'distance',
            cell: (value) => (
                <div className="d-flex">
                    <input className="distance-input" defaultValue={value.row.original.distance[school.iSchoolId]}
                        onChange={(e) => {
                            updateDistance(e.target.value, value.row.original.iAdminId, school.iSchoolId)
                        }}
                    />
                </div>
            )
        }))
    ];
    
    return (
        <>
            <Card className="main-card mb-3">
                <CardBody className="p-3 table-responsive">
                    <ReactTableGeneric data={data.adminData} columns={columns} tableClassName="mb-0 table table-borderless table-sm table-hover distance-table"
                        filters={{ search: true, pagination: true, sorting: true }}
                    />
                </CardBody>
            </Card>
        </>
    );
};
export default Records;