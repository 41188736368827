import { Table, Card, CardBody, Button, DropdownMenu, Nav, NavItem, NavLink, Alert, UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import moment from "moment";
import React, { useState } from "react";
import UserInfoModal from "Components/Modal/UserInfoModal/UserInfoModal";
import { BsCheckCircle } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import { ReactComponent as Football } from "../../../../../assets/utils/images/svgs/Football.svg";
import { ReactComponent as GreyFootball } from "../../../../../assets/utils/images/svgs/Football2.svg";
import InformationBlue from "../../../../../assets/utils/images/svgs/InformationBlue.svg";
import TextLimit from "Api/TextLimit";

const VervangerTable = ({ substitutionDays, id, changeStatus }) => {


    const iconSwitch = (param, color) => {
        switch (param) {
            case 'Aangevraagd':
                return <Tippy content={param} placement="bottom"><span><BsQuestionCircle color={color ? color : '#F2983E'} font-size={"21px"} /></span></Tippy>;
            case 'Ingepland':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color={color ? color : '#39A825'} font-size={"21px"} /></span></Tippy>;
            case 'Geannuleerd':
                return <Tippy content={param} placement="bottom"><span><TiDeleteOutline color={color ? color : '#FB2E5F'} font-size={"28px"} /></span></Tippy>;
            default:
                return '';
        }
    }

    const bsIconSwitch = (param, color) => {
        switch (param) {
            case '0':
                return <Tippy content={'Not Available'} placement="bottom"><span><BsQuestionCircle color={color ? color : '#F2983E'} font-size={"21px"} /></span></Tippy>;
            case '1':
                return <Tippy content={'Available'} placement="bottom"><span><BsCheckCircle color={color ? color : '#39A825'} font-size={"21px"} /></span></Tippy>;
            default:
                return '';
        }
    }

    function getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
    
        return year + '-' + month + '-' + day ;
      }


    return (
        <Card className="main-card mb-3 sub-detail-table-style">
            <CardBody className="p-3">
                <Table size="sm" borderless className="mb-0 subs-main-table table-background hidden-row-style homedetail-page-style">
                    <thead>
                        <tr>
                            <th className="text-left">Week</th>
                            <th className="text-center">Datum</th>
                            <th>&nbsp;</th>
                            <th colSpan="2">Ochtend</th>
                            <th className={"text-center"}>Id</th>
                            <th className={'text-center'}>Status</th>
                            <th className={"text-center"}>Gym</th>
                            <th className={"text-center"}>Opm</th>
                            <th className={"text-center"}>Bs</th>

                            <th>&nbsp;</th>
                            <th colSpan="2">Middag</th>
                            <th className={"text-center"}>Id</th>
                            <th className={'text-center'}>Status</th>
                            <th className={"text-center"}>Gym</th>
                            <th className={"text-center"}>Opm</th>
                            <th className={"text-center"}>Bs</th>
                        </tr>
                    </thead>
                    <tbody className="table-left-border table-right-border vervanger-table">
                        {/* {Object.keys(substitutionDays).map((key, index) => { */}
                        {Object.keys(substitutionDays).map(function (key, index) {
                            return (
                                <tr className="custom-border-top">
                                    <td className="table-right-border" >{key.split("-")[1]}</td>
                                    <td className="text-center">
                                        <table className={'vervanger-table-style'}>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                return (
                                                    <tr>
                                                        <td>{new Date(moment(day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })} {day}</td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>
                                    <td className="custom-border-left vervanger-cell-styler">
                                        <table>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                return (
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                return (
                                                    <tr>
                                                        <td className={((substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) ? ' text-color-grey ' : '')}>{substitutionDays[key][day]['Ochtend'] !== undefined ? 
                                                        (
                                                            window.innerWidth > 1250 ?
                                                            TextLimit.limitStringLargeScreen(substitutionDays[key][day]['Ochtend'].schoolName) 
                                                            : TextLimit.limitStringSmallScreen(substitutionDays[key][day]['Ochtend'].schoolName) 
                                                        )
                                                        
                                                        : ''}</td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>
                                    <td className="vervanger-cell-style">
                                        <table>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                return (
                                                    <tr>
                                                        <td className={((substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) ? ' text-color-grey ' : '')}>&nbsp;</td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>
                                    <td className="vervanger-cell-style text-center">
                                        <table>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                return (
                                                    <tr>
                                                        <td className={((substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) ? ' text-color-grey ' : '')}>{substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== undefined ? substitutionDays[key][day]['Ochtend'].substitutionId : ''}</td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>

                                    <td className="text-center">
                                        <table>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                  const parts = day.split('-');
                                                  const originalDate = new Date(`20${parts[2]}`, parts[1] -1 , parts[0]);
                                                  console.log('-------', originalDate < getCurrentDate());
                                                return (
                                                    <tr>
                                                        <td className={((substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) ? ' text-color-grey ' : '')}>
                                                            {(substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId === id && substitutionDays[key][day]['Ochtend'].status === 'Aangevraagd') && originalDate >= new Date(getCurrentDate()) ?
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle color="link" className="p-0">

                                                                        {iconSwitch(substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].status !== undefined ? substitutionDays[key][day]['Ochtend'].status : '')}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu end className="rm-pointers dropdown-menu-lg  dropdown-width">

                                                                        <Nav vertical>
                                                                            <NavLink className="link-gap" href="#" onClick={() => changeStatus(
                                                                                substitutionDays[key][day]['Ochtend'].id, 'Ingepland'
                                                                            )}>
                                                                                <span><BsCheckCircle color='#39A825' font-size={"21px"} />  </span>
                                                                                <span>Accepteer dit dagdeel</span>
                                                                            </NavLink>

                                                                            <NavLink className="link-gap navlink-border" href="#">
                                                                                <span><BsQuestionCircle color='#F2983E' font-size={"21px"} />  </span>
                                                                                <span>Nog geen keuze gemaakt</span>
                                                                            </NavLink>

                                                                            <NavLink className="link-gap" href="#" onClick={() => changeStatus(
                                                                                substitutionDays[key][day]['Ochtend'].id, 'Geannuleerd'
                                                                            )}>
                                                                                <span><TiDeleteOutline color='#FB2E5F' font-size={"28px"} /> </span>
                                                                                <span style={{ marginLeft: '-5px' }}>Weiger dit dagdeel</span>
                                                                            </NavLink>
                                                                        </Nav>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                                :
                                                                (iconSwitch(
                                                                    (substitutionDays[key][day]['Ochtend'] !== undefined &&
                                                                        substitutionDays[key][day]['Ochtend'].status !== undefined ? substitutionDays[key][day]['Ochtend'].status : ''),
                                                                    ((substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) || originalDate < new Date(getCurrentDate()) && '#AFAFAF')))

                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>

                                    <td className="text-center">
                                        <table>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                return (
                                                    <tr>
                                                        <td className={((substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) ? ' text-color-grey football-icon-style' : 'football-icon-style')}>{substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].gym == true && <span>{(substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) ? <GreyFootball /> : <Football />}</span>}</td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>
                                    <td className="text-center">
                                        <table>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                return (
                                                    <tr>
                                                        <td className={((substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) ? ' text-color-grey ' : '')}>{substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].note !== false ? <Tippy content={substitutionDays[key][day]['Ochtend'].note} placement="bottom"><span><img src={InformationBlue} /></span></Tippy> : ''}</td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>

                                    <td className="text-center">
                                        <table>
                                            {Object.keys(substitutionDays[key]).map(function (day) {
                                                return (
                                                    <tr>
                                                        <td className={((substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id) ? ' text-color-grey ' : '')}>
                                                            {(bsIconSwitch(
                                                                (substitutionDays[key][day]['Ochtend'] !== undefined &&
                                                                    substitutionDays[key][day]['Ochtend'].bsStatus !== undefined ? substitutionDays[key][day]['Ochtend'].bsStatus : ''),
                                                                (substitutionDays[key][day]['Ochtend'] !== undefined && substitutionDays[key][day]['Ochtend'].substitutionId !== id && '#AFAFAF')))}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </td>


                                    <>

                                        <td className="custom-border-left vervanger-cell-styler">
                                            <table>
                                                {Object.keys(substitutionDays[key]).map(function (day) {
                                                    return (
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </td>
                                        <td >
                                            <table>
                                                {Object.keys(substitutionDays[key]).map(function (day) {
                                                    return (
                                                        <tr>
                                                            <td className={((substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) ? ' text-color-grey ' : '')}>{substitutionDays[key][day]['Middag'] !== undefined ? 
                                                            (
                                                                window.innerWidth > 1250 ?
                                                                TextLimit.limitStringLargeScreen(substitutionDays[key][day]['Middag'].schoolName)
                                                                : TextLimit.limitStringSmallScreen(substitutionDays[key][day]['Middag'].schoolName)
                                                            )
                                                            
                                                            : ''}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </td>
                                        <td className="vervanger-cell-style">
                                            <table>
                                                {Object.keys(substitutionDays[key]).map(function (day) {
                                                    return (
                                                        <tr>
                                                            <td className={((substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) ? ' text-color-grey ' : '')}>&nbsp;</td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </td>
                                        <td className="vervanger-cell-style text-center">
                                            <table>
                                                {Object.keys(substitutionDays[key]).map(function (day) {
                                                    return (
                                                        <tr>
                                                            <td className={((substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) ? ' text-color-grey ' : '')}>{substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== undefined ? substitutionDays[key][day]['Middag'].substitutionId : ''}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </td>

                                        <td className="text-center">
                                            <table>
                                                {Object.keys(substitutionDays[key]).map(function (day) {
                                                     const parts = day.split('-');

                                                     const originalDate = new Date(`20${parts[2]}`, parts[1] - 1, parts[0]);
                                                     
                                                    return (
                                                        <tr>
                                                            <td className={((substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) ? ' text-color-grey ' : '')}>
                                                                {(substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId === id && substitutionDays[key][day]['Middag'].status === 'Aangevraagd') && originalDate >= new Date(getCurrentDate()) ?
                                                                    <UncontrolledButtonDropdown>
                                                                        <DropdownToggle color="link" className="p-0">

                                                                            {iconSwitch(substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].status !== undefined ? substitutionDays[key][day]['Middag'].status : '')}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu end className="rm-pointers dropdown-menu-lg  dropdown-width">

                                                                            <Nav vertical>
                                                                                <NavLink className="link-gap" href="#" onClick={() => changeStatus(
                                                                                    substitutionDays[key][day]['Middag'].id, 'Ingepland'
                                                                                )}>
                                                                                    <span><BsCheckCircle color='#39A825' font-size={"21px"} />  </span>
                                                                                    <span>Accepteer dit dagdeel</span>
                                                                                </NavLink>

                                                                                <NavLink className="link-gap navlink-border" href="#">
                                                                                    <span><BsQuestionCircle color='#F2983E' font-size={"21px"} />  </span>
                                                                                    <span>Nog geen keuze gemaakt</span>
                                                                                </NavLink>

                                                                                <NavLink className="link-gap" href="#" onClick={() => changeStatus(
                                                                                    substitutionDays[key][day]['Middag'].id, 'Geannuleerd'
                                                                                )}>
                                                                                    <span><TiDeleteOutline color='#FB2E5F' font-size={"28px"} /> </span>
                                                                                    <span style={{ marginLeft: '-5px' }}>Weiger dit dagdeel</span>
                                                                                </NavLink>
                                                                            </Nav>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                    :
                                                                    (iconSwitch(
                                                                        (substitutionDays[key][day]['Middag'] !== undefined &&
                                                                            substitutionDays[key][day]['Middag'].status !== undefined ? substitutionDays[key][day]['Middag'].status : ''),
                                                                        ((substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) || originalDate < new Date(getCurrentDate())  && '#AFAFAF')))

                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </td>

                                        <td className="text-center">
                                            <table>
                                                {Object.keys(substitutionDays[key]).map(function (day) {
                                                    return (
                                                        <tr>
                                                            <td className={((substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) ? ' text-color-grey football-icon-style' : 'football-icon-style')}>{substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].gym == true && <span>{(substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) ? <GreyFootball /> : <Football />}</span>}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </td>
                                        <td className="text-center">
                                            <table>
                                                {Object.keys(substitutionDays[key]).map(function (day) {
                                                    return (
                                                        <tr>
                                                            <td className={((substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) ? ' text-color-grey ' : '')}>{substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].note !== false ? <Tippy content={substitutionDays[key][day]['Middag'].note} placement="bottom"><span><img src={InformationBlue} /></span></Tippy> : ''}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </td>

                                        <td className="text-center">
                                            <table>
                                                {Object.keys(substitutionDays[key]).map(function (day) {
                                                    return (
                                                        <tr>
                                                            <td className={((substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id) ? ' text-color-grey ' : '')}>
                                                                {(bsIconSwitch(
                                                                    (substitutionDays[key][day]['Middag'] !== undefined &&
                                                                        substitutionDays[key][day]['Middag'].bsStatus !== undefined ? substitutionDays[key][day]['Middag'].bsStatus : ''),
                                                                    (substitutionDays[key][day]['Middag'] !== undefined && substitutionDays[key][day]['Middag'].substitutionId !== id && '#AFAFAF')))}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </td>
                                    </>
                                </tr>)
                        })}
                        {/* })} */}
                    </tbody>
                </Table>


            </CardBody>
        </Card>
    );
};

export default VervangerTable;