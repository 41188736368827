import axiosInstance from 'Api';

const organisationList = ()=>{
    return axiosInstance.get('organisation-link');
}

const saveOrganisationLink = (formData) => {
    return axiosInstance.post('organisation-link/save', formData);
};

const updateOrganisationLink = (formData)=>{
    return axiosInstance.post('organisation-link/update', formData);
}

const communityIdList = ()=>{
    return axiosInstance.get('organisation-link/community-list');
}

const deleteOrganisationLink = (id)=>{
    return axiosInstance.get(`organisation-link/delete/${id}`);
}

const powerBiApi = (data)=>{
    return axiosInstance.post("power-bi/update", data)
}

const communityList = ()=>{
    return axiosInstance.get("power-bi/community-lists")
}
const endpoinList = ()=>{
    return axiosInstance.get("power-bi/endpoints-list")
}

const smsYears = ()=>{
    return axiosInstance.get("site-years")
}
const smsStatistics = (year)=>{
    return axiosInstance.get(`get-sms-statistics?year=${year}`)
}
const AdminService = {
    organisationList,
    saveOrganisationLink,
    updateOrganisationLink,
    communityIdList,
    deleteOrganisationLink,
    powerBiApi,
    communityList,
    endpoinList,
    smsYears,
    smsStatistics
};
export default AdminService;