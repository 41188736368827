import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, CardFooter, CardHeader, Col, Row, Button } from "reactstrap";
import { Table } from "reactstrap";
import Tippy from "@tippyjs/react";
import { BsCheckCircle, BsQuestionCircle } from "react-icons/bs";
import { ReactComponent as Football } from "../../../../assets/utils/images/svgs/Football.svg";
import InformationBlue from "../../../../assets/utils/images/svgs/InformationBlue.svg";
import axiosInstance from 'Api';
import moment from "moment";
import { Link } from "react-router-dom";
import Loader from "react-loaders";
import TextLimit from "Api/TextLimit";


const Default = () => {

    const [substitutorData, setSubstitutorData] = useState([]);
    const [firstDayAssigned, setFirstDayAssigned] = useState(undefined);
    const [planboardData, setPlanboardData] = useState([]);
    const [schools, setSchools] = useState([]);
    const [schoolArray, setSchoolArray] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [sumOfWeeks, setSumOfWeeks] = useState(4);
    const [showLoader, setShowLoader] = useState(false);
    var n = 1;

    
    useEffect(() => {
        axiosInstance.get('substitutor/substitutions').then((response) => {
            if (response.data.date != undefined) {
                if (response.data.date.substitutions !== undefined) {
                    setSubstitutorData(response.data.date.substitutions);
                }
                setFirstDayAssigned(response.data.date.firstDayAssigned);
            }
        }).catch(() => console.error);

    }, []);

    useEffect(() => {
        getSubstitutionData(0);

    }, []);

    const getSubstitutionData = (increment) => {
        setShowLoader(true);
        let unique = [];
        let uniqueWeek = [];
        let num = parseInt(sumOfWeeks) + increment;
        axiosInstance.get('substitutor/planboard?weeks=' + num).then((response) => {
            if (response.status != undefined && response.status == 200) {
                if (response.data.date.planBoard !== undefined) {
                    setShowLoader(false);
                    setPlanboardData(response.data.date.planBoard);
                }

                let array = response.data.date.planBoard
                Object.keys(array).map((key, index) => {
                    let week = array[key].weekNumber;
                    weeks.push(week)
                    if (array[key]['Ochtend'].schoolId !== undefined) {
                        let id = array[key]['Ochtend'].schoolId
                        schoolArray.push(id);
                    }
                    if (array[key]['Middag'].schoolId !== undefined) {
                        let id = array[key]['Middag'].schoolId
                        schoolArray.push(id);
                    }
                })
                unique = schoolArray.filter(onlyUnique);
                uniqueWeek = weeks.filter(onlyUnique);

                setSumOfWeeks(uniqueWeek.length);
                setSchools(unique);
            }
        }).catch(() => console.error);
    }

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }


    const iconSwitch = (param) => {
        switch (param) {
            case 'Aangevraagd':
                return <Tippy content={param} placement="bottom"><span><BsQuestionCircle color='#F2983E' font-size={"18px"} /></span></Tippy>;
            case 'Ingepland':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"21px"} /></span></Tippy>;
            default:
                return '';
        }
    }


    return (
        <Fragment>
            <div className="app-main__inner">
                <PageHeading />
                <TransitionGroup>
                    <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                        timeout={1500} enter={false} exit={false}>

                        <div>
                            <Row className="home-table-styling">
                                <Col md="12" sm="12" lg="12" xxl="8" className="w-70 table-1">
                                    <Card className="main-card mb-3">
                                        <CardBody className="p-3">
                                            {planboardData.length === 0 ?
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div class="text-center">Geen data beschikbaar</div>
                                                    </div>
                                                </div>
                                                : (
                                                    <Table size="sm" borderless className="mb-0 subs-main-table table-background hidden-row-style">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-left">Week</th>
                                                                <th className="text-center">Datum</th>

                                                                <th colSpan="2">Ochtend</th>
                                                                <th className={"text-center"}>Bestuur</th>
                                                                <th className={'text-center'}>Status</th>
                                                                <th className={"text-center"}>Gym</th>
                                                                <th className={"text-center"}>Opm</th>

                                                                <th>&nbsp;</th>

                                                                <th colSpan="2">Middag</th>
                                                                <th className={"text-center"}>Bestuur</th>
                                                                <th className={'text-center'}>Status</th>
                                                                <th className={"text-center"}>Gym</th>
                                                                <th className={"text-center"}>Opm</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="table-left-border table-right-border ">
                                                            {planboardData !== undefined && Object.keys(planboardData).map((key, index) => (

                                                                <tr className={(index % 5 == 0 ? "custom-border-top " : '') + " varvanger_row_color-" + (parseInt(Object.keys(schools).find((key1) => schools[key1] === planboardData[key]['Ochtend'].schoolId)) + 1)} >
                                                                    <td className="table-right-border">{index % 5 == 0 ? planboardData[key].weekNumber : ''}</td>
                                                                    <td className="table-right-border text-center">{new Date(moment(key, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })} {key}</td>
                                                                    <>
                                                                        <td className={'custom-border-left vervanger-cell-style'}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Ochtend'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {
                                                                                     window.innerWidth > 1250 ?
                                                                                     TextLimit.limitStringLargeScreen(planboardData[key]['Ochtend'].label)
                                                                                     : TextLimit.limitStringSmallScreen(planboardData[key]['Ochtend'].label)
                                                                                }
                                                                            </Link>
                                                                        </td>
                                                                        <td className={'vervanger-cell-style'}>&nbsp;</td>
                                                                        <td className={'vervanger-cell-style text-center'}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Ochtend'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {TextLimit.limitStringSmallScreen(planboardData[key]['Ochtend'].organisation)}
                                                                            </Link>

                                                                        </td>
                                                                        <td className={"text-center"}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Ochtend'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {iconSwitch(planboardData[key]['Ochtend'].status)}
                                                                            </Link>
                                                                        </td>
                                                                        <td className={"text-center football-icon-style-home"}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Ochtend'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {planboardData[key]['Ochtend'].gym == true && <Football />}
                                                                            </Link>
                                                                        </td>
                                                                        <td className={"text-center"}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Ochtend'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {planboardData[key]['Ochtend'].note == true && <span><img src={InformationBlue} /></span>}
                                                                            </Link>
                                                                        </td>
                                                                    </>

                                                                    <td className="table-right-border">&nbsp;</td>
                                                                    <>
                                                                        <td className={'custom-border-left vervanger-cell-style'}>

                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Middag'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {
                                                                                     window.innerWidth > 1250 ?
                                                                                     TextLimit.limitStringLargeScreen(planboardData[key]['Middag'].label)
                                                                                     : TextLimit.limitStringSmallScreen(planboardData[key]['Middag'].label)
                                                                                }
                                                                            </Link>

                                                                        </td>
                                                                        <td className={'vervanger-cell-style'}></td>
                                                                        <td className={'vervanger-cell-style text-center'}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Middag'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {TextLimit.limitStringSmallScreen(planboardData[key]['Middag'].organisation)}
                                                                            </Link>

                                                                        </td>
                                                                        <td className={"text-center"}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Middag'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {iconSwitch(planboardData[key]['Middag'].status != undefined && planboardData[key]['Middag'].status)}
                                                                            </Link>
                                                                        </td>
                                                                        <td className={"text-center football-icon-style-home"}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Middag'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {planboardData[key]['Middag'].gym == true && <Football />}
                                                                            </Link>
                                                                        </td>
                                                                        <td className={"text-center"}>
                                                                            <Link
                                                                                to={`/home/${planboardData[key]['Middag'].id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {planboardData[key]['Middag'].note == true && <span><img src={InformationBlue} /></span>}
                                                                            </Link>
                                                                        </td>
                                                                    </>
                                                                </tr>

                                                            ))}

                                                        </tbody>
                                                    </Table>

                                                )}

                                            {
                                                showLoader === false &&
                                                    <Button color="link" className="btn-color-style mt-3 d-flex mx-auto" onClick={() => getSubstitutionData(4)} >
                                                        Meer
                                                    </Button>
                                            }

                                            {
                                                planboardData.length !== 0 && showLoader === true &&
                                                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                                            }

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="12" sm="12" lg="12" xxl="4">
                                    <Card className="main-card mb-3 table-2">
                                        {substitutorData.length === 0 && firstDayAssigned === undefined ?
                                            <>
                                                <CardBody>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div class="text-center">Geen data beschikbaar</div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </>
                                            : (

                                                <>

                                                    <CardHeader className="row m-0">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <span className="col-md-12 ps-0 head-text-color menu-header-title planbord">
                                                                    <span className="text-planbord">Aangevraagde Verzoeken</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody className="p-3">

                                                        <Table size="sm" borderless className="mb-0 subs-main-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Verzoek</th>
                                                                    <th>School</th>
                                                                    <th>1e Dag</th>
                                                                    <th>Dagdelen</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {substitutorData !== undefined && substitutorData.map((block, i) => {
                                                                    return (<tr className="border-bottom-0">
                                                                        <td>
                                                                            <Link
                                                                                to={`/home/${block.id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {block.id}
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                to={`/home/${block.id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {
                                                                                     window.innerWidth > 1250 ?
                                                                                     TextLimit.limitStringLargeScreen(block.school.name)
                                                                                     : TextLimit.limitStringSmallScreen(block.school.name)
                                                                                }
                                                                            </Link>

                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                to={`/home/${block.id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {block.firstDayRequested}
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                to={`/home/${block.id}`}
                                                                                key={'id'}
                                                                            >
                                                                                {block.daysRequested}
                                                                            </Link>
                                                                        </td>
                                                                    </tr>)
                                                                })
                                                                }

                                                            </tbody>
                                                        </Table>


                                                    </CardBody>
                                                    <CardFooter className="row m-0 border-top-0">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <span className="col-md-12 ps-0 pe-0 head-text-color menu-header-title planbord">
                                                                    <Table size="sm" borderless className="mb-0 subs-main-table">
                                                                        <tbody>
                                                                            <tr className="custom-border">
                                                                                <th>Eerstvolgende werkdag</th>
                                                                                <td className={"custom-td"} >{firstDayAssigned != '' ? moment(firstDayAssigned).format('DD-MM-yyyy') : 'Na'}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </CardFooter>
                                                </>
                                            )}
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                    </CSSTransition>
                </TransitionGroup>
            </div>
        </Fragment>
    )
};

export default Default;