import React, { Fragment, useEffect } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import DropdownList from "react-widgets/DropdownList";
import classNames from "classnames";


const BasicInformation = ({
    formikProps,
    formDataState,
    setFormDataState,
    isDisabled,
    data,
    codeListData,
    schools,
    absentsData,
    groupsData,
    getSchoolsByCommunityId,
    getAbsentsList
}) => {
    const { setFieldValue, errors, values } = formikProps;
    const organisationId = values.organisationId;

    // autoselect for organisation
    useEffect(() => {
        if (data.length === 1) {
            setFieldValue('organisationId', data[0].iCommunityId);
            getSchoolsByCommunityId(data[0].iCommunityId);
        }
    }, [data]);

    // autoselect for school
    useEffect(() => {
        if (schools.length === 1) {
            setFieldValue('schoolId', schools[0].iSchoolId)
        }
    }, [schools]);

    useEffect(() => {
        setFormDataState({ ...formDataState, school: '' })
        setFieldValue('schoolId', '');
    }, [organisationId])

    return (
        <Fragment>
            <FormGroup row>
                <Label sm={3}>
                    Organisatie *
                </Label>
                <Col sm={7} className="me-sm-4">
                    <DropdownList dataKey='iCommunityId' textField='name'
                        placeholder="Kies organisatie"
                        className={classNames({ "border border-danger rounded": errors.organisationId && isDisabled })}
                        onChange={(nextValue) => {
                            getSchoolsByCommunityId(nextValue.iCommunityId)
                            setFieldValue('organisationId', nextValue.iCommunityId)
                            setFormDataState({ ...formDataState, organisation: nextValue.name })
                        }}
                        name="organisationId"
                        data={data}
                        value={formikProps.values.organisationId}
                        required={true}
                    />
                    {errors.organisationId && isDisabled && <div className="text-danger">{errors.organisationId}</div>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3}>
                    School *
                </Label>
                <Col sm={7} className="me-sm-4">
                    <DropdownList dataKey='iSchoolId' textField='vSchoolName'
                        placeholder="Kies school"
                        className={classNames({ "border border-danger rounded": errors.schoolId && isDisabled })}
                        onChange={(nextValue) => {
                            getAbsentsList(nextValue.iSchoolId)
                            setFieldValue('schoolId', nextValue.iSchoolId)
                            setFormDataState({ ...formDataState, school: nextValue.vSchoolName })
                        }}
                        name="schoolId"
                        data={schools}
                        value={formikProps.values.schoolId}
                    />
                    {errors.schoolId && isDisabled && <div className="text-danger">{errors.schoolId}</div>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3}>
                    Afwezige
                </Label>
                <Col sm={7} className="me-sm-4">
                    <Typeahead id="iAdminId" labelKey="name" multiple={false} options={absentsData}
                        placeholder="Kies afwezige" name="absentId"
                        defaultInputValue={formDataState.absent}
                        onChange={e => {
                            if (e.length !== 0) {
                                setFieldValue('absentId', e[0].iAdminId)
                                setFormDataState({ ...formDataState, absent: e[0].name })
                            } return
                        }} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3}>
                    Groepen *
                </Label>
                <Col sm={7} className="me-sm-5">
                    <Typeahead id="iSubGroupId" labelKey="vGroup" multiple={true}
                        options={groupsData} placeholder="Kies groepen" name="groupsId"
                        defaultSelected={formDataState.groups}
                        className={classNames({ "border border-danger rounded": errors.groupsId && isDisabled })}
                        onChange={e => {
                            setFieldValue('groupsId', e.sort((x, y) => x.iSubGroupId - y.iSubGroupId).map(e => e.iSubGroupId))
                            setFormDataState({ ...formDataState, groups: e.map(group => group.vGroup) })
                        }}
                    />
                    {errors.groupsId && isDisabled && <div className="text-danger">{errors.groupsId}</div>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3}>
                    Code *
                </Label>
                <Col sm={7} className="me-sm-5">
                    <DropdownList dataKey='iCodeId' textField='codeName'
                        placeholder="Kies code afwezigheid" name="codeId"
                        className={classNames({ "border border-danger rounded": errors.codeId && isDisabled })}
                        onChange={e => {
                            setFieldValue('codeId', e.iCodeId)
                            setFormDataState({ ...formDataState, code: e.codeName })
                        }}
                        data={codeListData}
                        value={formDataState.code}
                    />
                    {errors.codeId && isDisabled && <div className="text-danger">{errors.codeId}</div>}
                </Col>
            </FormGroup>
        </Fragment>
    )
};

export default BasicInformation;
