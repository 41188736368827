import React, { useEffect, useState } from "react";
import axiosInstance from "Api";
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    Row,
    Button
} from "reactstrap";
import WeekReportDetails from "Pages/Melder/WeekReport/Show/Partials/WeekReportDetails";
import PageHeading from "Layout/AppMain/PageHeading";
import WeekReportTables from "./WeekReportTables";
import { useLocation, useNavigate } from "react-router";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import WeekReportForm from "./WeekReportForm";
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import { BsCheckCircle } from "react-icons/bs";
import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";

const Default = (props) => {
    const [weekReportData, setWeekReportData] = useState(null);
    let { state } = useLocation();
    const navigate = useNavigate()
    useEffect(() => {
        if (!weekReportData) {
            axiosInstance.get('weekstaat/' + state.wid + '?wsid=' + state.wsId + '&schoolId=' + state.schoolId).then((response) => {
                if (response?.status == 200) {
                    setWeekReportData(response.data.data);
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }, []);

    const columns = [
        {
            header: () => 'Vervanger',
            accessorKey: 'vSubstName',
        },
        {
            header: () => 'Salaris ID',
            accessorKey: 'vSalarisId',
        },
        {
            header: () => 'Begindatum',
            accessorKey: 'dDateStart',
        },
        {
            header: () => 'Einddatum',
            accessorKey: 'dDateEnd',
        },
        {
            header: () => 'WTF Ma',
            accessorKey: 'iWtfMa',
        },
        {
            header: () => 'WTF Di',
            accessorKey: 'iWtfDi',
        },
        {
            header: () => 'WTF Wo',
            accessorKey: 'iWtfWo',
        },
        {
            header: () => 'WTF Do',
            accessorKey: 'iWtfDo',
        },
        {
            header: () => 'WTF Vr',
            accessorKey: 'iWtfVr',
        },
        {
            header: () => 'Total',
            accessorKey: 'iWtfSum',
        },
        {
            header: () => 'Salaris',
            accessorKey: 'vFake',
            cell: (value) => (
                <>
                    {
                       weekReportData?.disableSalary === "N" ? (value.row.original.ePAStatus === "open" ? (
                        <input className="mx-3" type="checkbox" value={value.row.original.id}/>
                       ) : (<BsCheckCircle color='#39A825' fontSize={"14px"} />)) : (
                        value.row.original.ePAStatus !== "open" ? (<BsCheckCircle color='#39A825' fontSize={"14px"} />) : 
                        (<HiOutlineDotsCircleHorizontal fontSize={"18px"} />)
                       )
                    }
                </>
            )
        },
    ];


    return (
        <>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div className="app-inner-layout" >
                        <PageHeading />
                        <div className="app-inner-layout__wrapper">
                            <Card className="app-inner-layout__content">
                                <Row className="mb-4"> 
                                    <Col md={window.innerWidth < 1500 ? "12" : "9"} sm="12">
                                        {weekReportData && weekReportData.data.length === 0 &&
                                            <CardBody className="p-0">
                                                <p className="alert alert-secondary">Geen verzoeken met dagdelen en/of langdurige verzoeken gevonden binnen de gewenste periode.</p>
                                            </CardBody>
                                        }

                                        {weekReportData && weekReportData.data.map((data, id) => (
                                            <WeekReportTables key={id} data={data} columns={columns} />
                                        ))}

                                    </Col>
                                    <Col sm="12" md={window.innerWidth < 1500 ? "12" : "3"}>
                                        <WeekReportDetails reportData={weekReportData} />
                                        <WeekReportForm reportData={weekReportData} />
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer" style={{marginBottom: '-5px'}}>
                <CardFooter className="chat-layout-footer__inner d-flex justify-content-end">
                    <Button
                        outline
                        className="btn-outline-primary-blue btn-color-style d-flex justify-content-center align-items-center gap-2"
                        onClick={() =>{navigate('/weekstaat')}}
                        type="button"
                        color="primary"
                    >
                         <BackIcon/>
                    </Button>
                </CardFooter>
            </div>
        </>

    )
}
export default Default;

