import React, { Fragment } from "react";
import { useNavigate } from "react-router";


const Forbidden = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="fs-3 fw-bolder">
                    403 Forbidden
                </div>
                <div className="cursor-pointer" onClick={() => navigate("/")}><u>Go Back</u></div>
            </div>
        </Fragment>
    )
};

export default Forbidden;