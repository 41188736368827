import classNames from "classnames";
import { parseJSON } from "date-fns";
import React, {useEffect} from 'react'
import {useTable, useFilters, useGlobalFilter, usePagination, useAsyncDebounce, filterTypes, defaultColumn, useSortBy } from "react-table";
import {Card, CardBody, Pagination, PaginationItem, PaginationLink} from "reactstrap";

const AbsentReactTable = ({columns, data, filters, school, tableClassName}) => {

    if(school !== null){
        var index_class = 1;
        school.forEach(function (value, i) {
            index_class = (i % 12) == 0 && i >= 12 ? 1 :  index_class ;
            school[i].index = index_class;
            index_class = index_class + 1;
        });
    }
    
    if( data !== null ){
        data.forEach(function (dataRow, i1) {
            school.forEach(function (dataSchool, i2) {
                if(dataSchool.id ==  dataRow.iSchoolId){
                    data[i1].index = dataSchool.index;
                }
            });
        });
    }
    
    const tableClass =  tableClassName || "mb-0 table table-sm table-hover";
    const {search = true, columnFilter=false, pagination = true, sorting=true } = filters || {};
    var name = "";

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        // which has only the rows for the active page
        state,
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
        visibleColumns,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes
        },
        useGlobalFilter,
        columnFilter ? useFilters : '',
        useSortBy,
        usePagination


    )

    useEffect(() => {
        setPageSize(10000)
    }, [pageSize]);

    return (
        <>
            { search ?
                <div className="form-group pull-right">
                    <input
                        type="text"
                        value=""
                        className="form-control mb-2 search"
                        placeholder="Search ... "
                        onChange={()=>(console.log("Just stumpb for refactoring"))}
                    />
                </div> : null
            }


        <table {...getTableProps()} className={tableClass}>
            <thead>
                {headerGroups.map((headerGroup, id) => (
                    <tr className="absent-table-tr text-center" {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th className={classNames({"text-center": id == 0 })} {...column.getHeaderProps( sorting ? column.getSortByToggleProps() : '')}>
                                {column.render('Header')}
                                {/* Render the columns filter UI */}
                                { sorting?
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                    </span> : null
                                }
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>

            <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} data={ data[i].index } className={"row_color-" + data[i].index + " " + ((i % 2) !== 0 ? "odd-color" : "")}>
                        
                        {row.cells.map((cell, id) => {
                            const backgroundColor = cell && cell.value && cell.value.length === 1 && cell.value[0].vColor
                            ? `#${cell.value[0].vColor}`
                            : cell && cell.value && cell.value.length > 1
                            ? '#ff6961'
                            : '';

                            return (
                                <td {...cell.getCellProps()} className={cell.value !== null && cell.value !== undefined && id > 2 ? "cell-color-style" : ""}
                                style={{
                                    backgroundColor: cell.getCellProps().key.includes("middag") || cell.getCellProps().key.includes("ochtend") ? backgroundColor : '',
                                }}
                                >
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
            { pagination ?
            <Card className="main-card mb-3">
                <CardBody className="d-flex flex-wrap justify-content-end align-items-start ">
                    <form className="d-flex justify-content-end me-2 h-75">
                        <div className="form-group col-md-4">
                            <div className="input-group input-group-sm ">
                                <div className="input-group-text font-icon-wrapper font-icon">
                                    <i className="pe-7s-file"> {" "} </i></div>
                                <input
                                    className="form-control form-control-sm me-2"
                                    type="number"
                                    min="0"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                            <select className="form-select form-select-sm "
                                    value={10}
                            >
                               
                            </select>
                        </div>

                    </form>

                    <nav aria-label="Table navigation" className="nav" >
                        <ul className="pagination">

                            <li>
                                <a className="page-link">
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </a>
                            </li>

                            <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <a className="page-link">First</a>
                            </li>
                            <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <a className="page-link">{'<'}</a>
                            </li>
                            <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                                <a className="page-link">{'>'}</a>
                            </li>
                            <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                <a className="page-link">Last</a>
                            </li>

                        </ul>
                    </nav>

                </CardBody>
            </Card>
            : null }
        </>
    )
}

export default AbsentReactTable;
