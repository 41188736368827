import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import Loader from "react-loaders";
import { ReactComponent as DeleteIcon } from "../../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import { BsCheckCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { toast, ToastContainer } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import { Formik } from "formik";
import * as Yup from "yup";
import CoordinatorInstellingenService from "Api/Instellingen/Features";

const validationSchema = Yup.object().shape({
    functies: Yup.string().required('Selecteer een optie'),
    eExclude_wwz: Yup.string().required('Selecteer een optie'),
});

const Features = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [communityId, setCommunityId] = useState('');
    const [communityList, setCommunityList] = useState([]);
    const [featuresData, setFeaturesData] = useState();
    const [functionModal, setFuntionModal] = useState(false);
    const [currentSubGrpId, setCurrentSubGrpId] = useState('');
    useEffect(() => {
        setLoading(true);
        fetchCommunityList();
    }, []);

    useEffect(() => {
        if (!communityId) return;
        fetchFunctiesData();
    }, [communityId])

    const fetchFunctiesData = () => {
        setLoading(true);
        CoordinatorInstellingenService.fetchFunctieData(communityId).then((response) => {
            if (response.status) {
                setFeaturesData(response.data.data)
            }
            setLoading(false);
        }).catch(() => console.error);
    }

    const fetchCommunityList = () => {
        CoordinatorInstellingenService.communityList().then((response) => {
            if (response.status) {
                if (response.data.communityList?.length > 0) {
                    setCommunityList(response.data.communityList);
                    setCommunityId(response.data.communityList[0]?.iCommunityId);
                }
            }
        }).catch(() => console.error);
    }

    const toggleFunctieHandler = (rowData, communityId) => {
        const data = {
            iSubGroupId: rowData.iSubGroupId,
            iCommunityId: communityId,
            value: rowData.eExclude_wwz === "Yes" ? "No" : "Yes"
        };
        CoordinatorInstellingenService.toggleFunctieValue(data).then((response) => {
            if (response.status) {
                fetchFunctiesData();
            }else {
                toast.error("Kan gegevens niet updaten");
            }
        }).catch(() => console.error);
    }

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Functies',
                accessorKey: 'vGroup',
            },
            {
                header: () => 'Uitzondering WWZ',
                accessorKey: 'eExclude_wwz',
                cell: (value) => {
                    return <div onClick={() => toggleFunctieHandler(value.row.original, communityId)} className="cursor-pointer d-inline-flex justify-content-center">
                        {
                            value.row.original.eExclude_wwz === "Yes" ?
                                <BsCheckCircle color='#39A825' fontSize={"18px"} className="weekstaten-check-icon" /> :
                                <TiDeleteOutline color='#FB2E5F' fontSize={"24px"} className="weekstaten-dlt-icon" />
                        }
                    </div>

                },
            },
            {
                header: () => 'Verwijderen',
                accessorKey: 'rows',
                cell: (value) => {
                    return <DeleteIcon className="cursor-pointer" onClick={() => { setShowAlert(true); setCurrentSubGrpId(value.row.original.iSubGroupId) }} />
                },
            },
        ],
        [communityId]
    );

    const toggle = () => {
        setFuntionModal(prev => !prev);
    }

    const handleOrgSubmit = (values) => {
        const data = {
            iSubGroupId: values.functies,
            iCommunityId: communityId,
            value: values.eExclude_wwz
        };
        CoordinatorInstellingenService.saveFeatureApi(data).then((response) => {
            if (response.status) {
                fetchFunctiesData();
                setFuntionModal(false);
            }else {
                toast.error("Er is iets misgegaan");
            }
        }).catch(() => console.error);
    }

    const deleteFunctieHandler = () => {
        CoordinatorInstellingenService.deleteFunctie(currentSubGrpId, communityId).then((response) => {
            if (response.status) {
                fetchFunctiesData();
            }else {
                toast.error("Er is iets misgegaan");
            }
        }).catch(() => console.error);
    }

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col>
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    {isLoading ?
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                                        <ReactTableGeneric
                                            data={featuresData?.functieArr || []}
                                            columns={columns}
                                            tableClassName="mb-0 table table-borderless table-sm table-hover tb-api tb-links"
                                            rowCount={40}
                                            filters={{ search: false, pagination: false, sorting: false }}
                                        />
                                    }
                                </CardBody>
                            </Card>
                            <p className="mt-4" style={{ color: '#076396' }}>Klik op icoontjes om te wijzigen</p>
                        </Col>
                        <Modal isOpen={functionModal} toggle={toggle} className="feedback-modal-style link-form">
                            <ModalHeader toggle={toggle}>
                                Functie toevoegen
                            </ModalHeader>
                            <ModalBody>
                                <Formik
                                    initialValues={{
                                        functies: '',
                                        eExclude_wwz: '',
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleOrgSubmit}
                                >
                                    {({ setFieldValue, handleSubmit, values, handleBlur, errors, touched }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <FormGroup>
                                                <Label for="functies">Functies</Label>
                                                <select
                                                    name="functies"
                                                    className="form-control"
                                                    value={values.functies}
                                                    onChange={(e) => setFieldValue('functies', e.target.value)}
                                                    onBlur={handleBlur}
                                                ><option value="">Optional functies</option>
                                                    {featuresData?.codeMasterArr?.map(item => <option value={item.iSubGroupId}>{item.vGroup}</option>)}
                                                </select>
                                                {touched.functies && errors.functies && (
                                                    <div className="invalid-feedback d-block">{errors.functies}</div>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="eExclude_wwz">Uitzondering WW</Label>
                                                <select
                                                    name="eExclude_wwz"
                                                    className="form-control"
                                                    value={values.eExclude_wwz}
                                                    onChange={(e) => setFieldValue('eExclude_wwz', e.target.value)}
                                                    onBlur={handleBlur}
                                                >
                                                    <option value="" disabled>Nee / Ja</option>
                                                    <option value="No">Nee</option>
                                                    <option value="Yes">Ja</option>
                                                </select>
                                                {touched.eExclude_wwz && errors.eExclude_wwz && (
                                                    <div className="invalid-feedback d-block">{errors.eExclude_wwz}</div>
                                                )}
                                            </FormGroup>

                                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                                <Button className="btn-color-style  opslaan-btn" size="sm" outline color="primary" type="submit">
                                                    Toevoegen
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    )}
                                </Formik>
                            </ModalBody>
                        </Modal>
                    </Row>

                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">
                        <div>
                            <select className={`form-select`} value={communityId} onChange={(e) => {setCommunityId(e.target.value) }}>
                                <option value={''} disabled>Alle besturen</option>
                                {communityList?.map(item => {
                                    return <option key={item.iCommunityId} value={item.iCommunityId}>{item.name}</option>
                                })}
                            </select>
                        </div>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={toggle}
                            type="button"
                            color="primary"
                        >
                            Functie <AddIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
            <ToastContainer style={{ top: "70px" }} />
            <SweetAlert title="Weet u zeker dat u deze selectie wilt verwijderen?" show={showAlert}
                type="warning"
                onConfirm={() => { setShowAlert(false); deleteFunctieHandler() }}
                onCancel={() => setShowAlert(false)}
                cancelBtnText="Nee"
                confirmBtnText="Ja"
                showCancel={true}
            />
        </Fragment>
    )
}

export default Features