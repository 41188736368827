import React from "react";

const Pagination = ({table, reactTablePageSize, totalPageCount, updatePaginate, pageNumber, setPageNumber}) => {
    const nextPage = (val) => {
        setPageNumber(totalPageCount >= val + 1 ? val + 1 : pageNumber);
        return totalPageCount >= val + 1 ? val + 1 : pageNumber;
    }

    const prevPage = (val) => {
        setPageNumber(totalPageCount >= val - 1 ? val - 1 : pageNumber);
        return totalPageCount >= val - 1 ? val - 1 : pageNumber;
    }

    const setPaginate = (val) => {
        updatePaginate(val);
    }

    return (
        <nav className="pagination-wrapper">

            <span className="pagination__number pnm-4">Rijen per pagina:</span>
            <div className="pagination__rows">
                <select
                    aria-label="Rijen per pagina"
                    className="pagination__rows-num"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                        reactTablePageSize(Number(e.target.value))
                        
                    }}>
                    {[20, 40, 60, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Toon {pageSize}
                        </option>
                    ))}
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M7 10l5 5 5-5z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
            </div>
            <span className="pagination__number pnm-24">
                {pageNumber} van{' '}
                {totalPageCount}
            </span>
            <div className="pagination__btns">
                <button id="pagination__first-page"
                        className="pagination__page"
                        type="button" aria-label="first page"
                        onClick={() => setPaginate(prevPage(2))}
                        disabled={pageNumber <= 1}
                        aria-disabled="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" viewBox="0 0 24 24" role="presentation">
                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                        <path d="M24 24H0V0h24v24z" fill="none"></path>
                    </svg>
                </button>

                <button id="pagination__prev-page"
                        onClick={() => setPaginate(prevPage(pageNumber))}
                        disabled={pageNumber <= 1}
                        className="pagination__page"
                        type="button"
                        aria-label="previous page"
                        aria-disabled="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" viewBox="0 0 24 24" role="presentation">
                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </button>
                <button id="pagination__next-page"
                        className="pagination__page"
                        type="button"
                        onClick={() => setPaginate(nextPage(pageNumber))}
                        disabled={totalPageCount <= pageNumber}
                        aria-label="next page">

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" viewBox="0 0 24 24" role="presentation">
                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z
                    "></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>

                </button>
                <button id="pagination__last-page"
                        className="pagination__page"
                        type="button"
                        onClick={() => setPaginate(nextPage(totalPageCount - 1))}
                        disabled={totalPageCount <= pageNumber}
                        aria-label="last page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" viewBox="0 0 24 24" role="presentation">
                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>

                </button> 
            </div>
        </nav>
    );
}

export default Pagination;