import axiosInstance from 'Api';

const updateGebruikerProfilePhoto = (data)=>{
    return axiosInstance.post('gebruiker/uploadImage', data)
};

const updateGebruikerPassword = (data)=>{
    return axiosInstance.post('gebruiker/generate-password', data)
}

const gebruikerForgotPassword = (data)=>{
    return axiosInstance.post('gebruiker/generate-link', data)
}

const gebruikerUpdateRoles = (data)=>{
    return axiosInstance.post("gebruiker/update-role",data);
}

const getGebruikerRoleList = (id)=>{
    return axiosInstance.get("gebruiker/show-user-roles?iAdminId=" + id);
}

const removeAddCommunity = (data)=>{
    return axiosInstance.post("gebruiker/save-community", data);
}

const GebruikerAlgemeenService = {
    updateGebruikerProfilePhoto,
    updateGebruikerPassword,
    gebruikerForgotPassword,
    gebruikerUpdateRoles,
    removeAddCommunity,
    getGebruikerRoleList
};
export default GebruikerAlgemeenService;