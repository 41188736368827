import React from 'react';
import {Field} from "formik";
import {Col, FormGroup, Label} from "reactstrap";

function SelectWrapper(props) {
    return (
        <FormGroup row>
            <Label for={props.id} sm={4}  >
                {props.label}
            </Label>
            <Col sm={8} >
                {props.children}
            </Col>
        </FormGroup>
    );
}

export default SelectWrapper;
