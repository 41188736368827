import { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { useFloating } from '@floating-ui/react-dom';


const PopupBasic = ({ name, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const {x, y, reference, floating} = useFloating();

    return (
        <>{isOpen && content && (
            <div
                ref={floating}
                style={{
                    position: 'absolute',
                    top: y + 10 ?? 0,
                    left: x + 350 ?? 0,
                    width: 'max-content',
                    zIndex: 10,
                }}>
            <Card className="bg-white rounded w-50">
                <CardBody className="d-flex">
                    {content}
                </CardBody>
            </Card>
            </div>
        )}

            <span 
                ref={reference} 
                className="cursor-pointer"
                onMouseOver={() => setIsOpen(true)} 
                onMouseLeave={() => setIsOpen(false)} 
            >{name}
            </span>
        </>
    )
}

export default PopupBasic;