import React from "react";

const AbsentFilter = ({schoolsListing, handleCallback}) => {
    return (
        <>
            {
                schoolsListing.schools != null && schoolsListing.schools != undefined ?
                    <div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="filterBy me-2">
                                <label>Filter school: </label>
                            </div>
                            <div className="selectBy">
                                <select className="form-select"
                                        onChange={(event) => handleCallback(event.target.value)}
                                        aria-label="Select schools by name">
                                    <option value=''>Alle</option>
                                    {schoolsListing.schools.map((school, i) => {
                                        return (<option value={school.id} key={i}> {school.name} </option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div> : {}
            }
        </>

    )
}

export default AbsentFilter;