import React, { useEffect, useState } from "react";
import DropdownList from "react-widgets/DropdownList";
import axiosInstance from "Api";
import { ErrorMessage } from "formik";
import InputWrapper from "Pages/Profile/Forms/InputWrapper";

import {
    Col,
    FormGroup,
    Label,
    Row,
    Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Formik } from "formik";
import { ReactComponent as PdfIcon } from "../../../assets/utils/images/svgs/PDF.svg";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";


const InlichtingenstaatForm = ({ type, saveDocTypeValue, docTypeName, doc, toggle, updateData, currentUser, filterCommunity}) => {

    const [docTypeList, setDocTypeList] = useState([]);
    const [communityList, setCommunityList] = useState([]);
    const [formDataState, setFormDataState] = useState({
        docType: '',
        comType: '',
        herinneringDatum: '',
        file: ''
    });

    const validationSchema = Yup.object().shape({
        docType: Yup.string().required("Veld mag niet leeg zijn"),
        comType: Yup.string().required("Veld mag niet leeg zijn"),
        file: Yup.string().required("Veld mag niet leeg zijn"),
    });

    const handleSubmit = (values, setSubmitting) => {
        setTimeout(() => {
        let formData = new FormData();
        formData.append('file', values.file);
        formData.append('docType', values.docType);
        formData.append('comType', values.comType);
        if(type !== "Gebruikers"){
            formData.append('herinneringDatum', values.herinneringDatum);
        }else {
            formData.append('iAdminId', currentUser.data.clooserId)
        }
    
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const url = type === "Gebruikers" ? "gebruiker/save-document" : "save-document";
        axiosInstance.post(url, formData, config).then((response) => {
            if (response.status == 200) {
                if (response.data.status == true) {
                    toast["success"]("Instellingen zijn bijgewerkt");
                    setFormDataState({  docType: '', comType: '', herinneringDatum: '', file: ''});
                    toggle();
                    updateData();
                }
                    
            }
        }).catch((error) => console.log("catch error:", error));

            setSubmitting(false);
         }, 400);
    };

    useEffect(() => {
        axiosInstance.get('doc/types').then((response) => {
            if (response.status == 200) {
                if (response.data.status === true) {
                    console.log(response.data.data);
                    setDocTypeList(response.data.data);
                    setFormDataState({ ...formDataState, docType: docTypeName, file: doc })
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }, []);

    useEffect(() => {
        const url = `communityList${type=== "Gebruikers" ? `?iAdminId=${currentUser.data.clooserId}` : ''}`;
        axiosInstance.get(url).then((response) => {
            if (response.status == 200) {
                if (response.data.status === true) {
                    setCommunityList(response.data.data);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }, []);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={formDataState}
                validationSchema={validationSchema}
                onSubmit={
                    (values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <div>
                            <Row>
                                <Col md="12" sm="12" lg="12">

                                    <FormGroup row >
                                        <Label className={"setting-lebel-style text-start"}>
                                            Geselecteerd bestand
                                        </Label>
                                        <Col sm={12} className="setting-lebel-style text-start">
                                            <PdfIcon /> {doc !== '' && doc.name !== undefined ? doc.name : ''}
                                        </Col>
                                        <ErrorMessage name="file" component="small"
                                            className="block mt-2 invalid-feedback" />
                                    </FormGroup>

                                    <div className="custom-styling  mb-3">

                                        <InputWrapper label="Document type *" labelCol={3} className={'setting-lebel-style text-start'}>
                                            <DropdownList dataKey='vName' textField='vLabel'
                                                placeholder="Selecteer"
                                                className={"border rounded text-start"}
                                                onChange={(nextValue) => {
                                                    saveDocTypeValue(nextValue.iDocTypeId, nextValue.vName)
                                                    setFormDataState({ ...formDataState, docType: nextValue.vName })
                                                }}
                                                defaultValue={docTypeName}
                                                name="docType"
                                                data={docTypeList}
                                                required="true"
                                            />
                                        </InputWrapper>
                                        <ErrorMessage name="docType" component="small"
                                            className="block mt-2 invalid-feedback" />
                                    </div>

                                    <div className="custom-styling  mb-3" >
                                        <InputWrapper label="Beschikbaarstellen voor *" labelCol={3} className={'setting-lebel-style text-start'}>
                                            <DropdownList dataKey='iCommunityId' textField='vCommunityName'
                                                placeholder="Selecteer"
                                                className={"border rounded text-start"}
                                                onChange={(nextValue) => {
                                                    setFormDataState({ ...formDataState, comType: nextValue.iCommunityId })
                                                }}
                                                name="comType"
                                                data={communityList}
                                                required="true"
                                            />
                                        </InputWrapper>
                                        <ErrorMessage name="comType" component="small"
                                            className="block mt-2 invalid-feedback" />
                                    </div>
                                    { type !== "Gebruikers" && 
                                        <FormGroup row >
                                            <Label className={"setting-lebel-style text-start"}>
                                                Datum herinnering
                                            </Label>
                                            <Col sm={12} >
                                                <div className="input-group-text input-border-styling">
                                                    <DatePicker
                                                        locale="nl"
                                                        name="herinneringDatum"
                                                        dateFormat="dd-MM-yyyy"
                                                        className="me-2 border-0 bg-transparent float-start w-100"
                                                        selected={(values.herinneringDatum !== '' && values.herinneringDatum !== undefined) ? new Date(values.herinneringDatum) : ''}
                                                        placeholderText={'dd-mm-yyyy'}
                                                        onChange={(val) => {
                                                            setFormDataState({ ...formDataState, herinneringDatum: moment(val).format('yyyy-MM-DD') })
                                                        }}
                                                    />
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </div>
                                                <ErrorMessage name="herinneringDatum" component="small"
                                                    className="block mt-2 invalid-feedback" />
                                            </Col>
                                        </FormGroup>
                                    }
                                    
                                </Col>
                            </Row>
                            <Button className="d-flex align-items-center btn-color-style mb-2 me-2 float-end" size="sm" outline color="primary" type="submit" >
                                Opslaan
                            </Button>
                        </div>
                    </Form>

                )}
            </Formik>

        </>
    )
}

export default InlichtingenstaatForm;
