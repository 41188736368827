import React, { Fragment, useReducer, useState, useEffect } from "react";
import dayjs from 'dayjs'
import classNames from "classnames";
import {
    getMonth,
    withoutWeekend,
    addDateToState,
    toggleDays,
    getDaysInRange,
    addClassByDayPart
} from "./util"
import dayjsBusinessDays from 'dayjs-business-days';
import { ErrorMessage } from "formik";
dayjs.extend(dayjsBusinessDays);

// change language (for months names)
// require('dayjs/locale/nl');
// dayjs.locale('nl');

// to on/off weekend:
// replace array with/-out weekend days
// replace array of month with/-out weekend days
// change quality of columns in gridStyle

const gridStyles = (cols) => {
    return {
        "display": "grid",
        "gridTemplateColumns": `repeat(${cols}, minmax(0, 36px)`,
        "gridTemplateRows": "repeat(6, minmax(0, 1fr))",
        "rowGap": "0.1rem",
        "justifyItems": "center",
        "justifyContent": "center",

        "backgroundColor": "#FAFCFD",
        "borderRadius": "10px",
    }
}

const weekDaysListWOWeekend = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag']

const Calender = ({ preselectedData=[], manageDays, removeDate, manageDate, data=[], prevState=[], setState, weekDays=[], showMonth=4, setDatesState, isDisabled }) => {
    let weekDaysList = weekDays.length !== 0 ? weekDays : weekDaysListWOWeekend;
    const [currentYear, setCurrentYear] = useState(dayjs().year()); // current year - for scroll whole year (12 months)
    const [currentIndex, setCurrentIndex] = useState(new Date().getMonth()); // currentIndex = for scroll 1 month (specific month number)
    const [preSelectedDates, setPreSelectedDates] = useState(null);

    let listOfMonths = [];
    for (let i = 0; i < showMonth; i++) {
        
        listOfMonths.push(getMonth(currentIndex+i, currentYear))
    }

    let listOfMonthWOWeekend = withoutWeekend(listOfMonths);

    const initialState = [];
    const [ state, dispatch ] = useReducer(reducer, initialState);

    function reducer (state = initialState, action) {
        switch (action.type) {
            case 'ADD_DATE':
                return [...state, action.payload];
            case 'ADD_DATES':
                return [...state, ...action.payload];
            case 'REMOVE_DATE':
                return state.filter(sub => sub.date !== action.payload)
            case 'UPDATE_DAYPART':
                return state.map(e => {
                    return e.date === action.payload.date ?
                        Object.assign(e, {dayPart: action.payload.dayPart}) : e
                })
            default:
                return state
        }
    }

    // selected dates (for multi step form)
    useEffect(() => {
        if (data.length !== 0) {
            dispatch({ type: 'ADD_DATES', payload: data.dateRange })
        }
    }, [])

    // preselected dates
    useEffect(() => {
        if (!preSelectedDates && preselectedData.length !== 0) {
            setPreSelectedDates(JSON.parse(JSON.stringify(preselectedData))); // to use unchanged data (from props)
        }
    }, [preselectedData])

    useEffect(() => {
        let arrangedDates = [];

        state.map(day => {
            let year = dayjs(day.date).year();
            let month = dayjs(day.date).month()+1;

            let filteredDatesByYear = arrangedDates.filter(yearObjDate => yearObjDate.year == year);

            if (
                preSelectedDates // don't save preselected dates
                && !preSelectedDates.map(e => e.dayPart === "full" && e.date).includes(day.date)
                || filteredDatesByYear
            ) {
                if (filteredDatesByYear.length == 0) { // add new year
                    arrangedDates.push({ year, months: [{ month: month, dayPart: null, dates: [day] }] });
                } else {
                    let filteredMonths = filteredDatesByYear[0].months.filter(arrangedMonth => arrangedMonth.month == month);

                    if (filteredMonths.length == 0) { // add new month
                        arrangedDates.map(yearObjDate => yearObjDate.year == year && yearObjDate.months.push({ month: month, dayPart: null, dates: [day] }));
                    } else {
                        arrangedDates.map(yearObjDate => // add new date
                            yearObjDate.year == year && yearObjDate.months.map(monthObj =>
                                monthObj.month == month && monthObj.dates.push(day)
                            )
                        );
                    }
                }
            }
        });

        arrangedDates.map(yearObjDate => {
            yearObjDate.months.map(monthObj => {
                let theSameDayPart = monthObj.dates.every(dayObj => dayObj.dayPart == monthObj.dates[0].dayPart)
                if (theSameDayPart) { // check if all selected days in month have the same day part
                    monthObj.dayPart = monthObj.dates[0].dayPart == "ochtend" ? 1 : monthObj.dates[0].dayPart == "middag" ? 2 : 3 // dayPart must have integer value (1-morning, 2-afternoon, 3-full)
                    if (monthObj.dates.length == dayjs(monthObj.dates[0].date).businessDaysInMonth().length) { // check if all days in month are selected
                        monthObj.dates = "full"
                    }
                }
            })
        });

        if (typeof setDatesState !== 'undefined') {
            setDatesState({ ...data, dateRange: state}); // save in useState
        }
        setState({ ...prevState, dateRange: arrangedDates }); // transfer the state to the parent element
    }, [state]);

    const checkPreSelectedDayPart = (date) => {
        let preSelectedDay = preSelectedDates.find(day => day.date === date);
        return preSelectedDay && preSelectedDay.dayPart === "full"
    }

    const nextMonth = e => {
        e.preventDefault();
        if (currentIndex < 12) {
            setCurrentIndex(prevState => prevState + 1)
        } else {
            setCurrentIndex(0);
            setCurrentYear(currentYear + 1)
        }
    }

    const prevMonth = e => {
        e.preventDefault();
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        } else {
            setCurrentIndex(12 - 1);
            setCurrentYear(currentYear - 1)
        }
    }


    return (
        <Fragment>
                <div className="d-flex align-items-start">
                    <button
                        onClick={e => prevMonth(e)}
                        className="bg-white border-0 p-0">
                        <i className="pe-7s-angle-left text-info fs-1"></i>
                    </button>
                        <div
                            className="w-100 d-flex justify-content-center flex-wrap"
                        >
                            {
                                listOfMonthWOWeekend.map((month, monthid) =>
                                    <div key={monthid} className="mt-2 mx-2 d-block">
                                        {/* MONTH, YEAR */}
                                        <header className="d-flex justify-content-center">
                                            <p
                                                className="cursor-pointer"
                                                monthid={monthid}
                                                onClick={(selectedMonth) => {
                                                    // !select by month
                                                    let filteredByMonth = getDaysInRange("filteredByMonth", selectedMonth.target)
                                                    toggleDays(filteredByMonth, dispatch)
                                                    manageDays(filteredByMonth)
                                                }}>
                                                {dayjs(month[2][1]).format('MMMM, YYYY')}
                                            </p>
                                        </header>

                                        <div style={gridStyles(6)} className="p-2">
                                            {/* WEEK DAY */}
                                            <span className="calendar-icon"></span>
                                            {weekDaysList.map((day, id) => (
                                                <span
                                                    weekday={id + 1}
                                                    monthid={monthid}
                                                    key={id}
                                                    className={"py-1 fs-6 text-center text-info cursor-pointer"}
                                                    onClick={(selectedDay) => {
                                                        // !select by week day
                                                        let filteredByWeekDay = getDaysInRange("filteredByWeekDay", selectedDay.target)
                                                        toggleDays(filteredByWeekDay, dispatch)
                                                        manageDays(filteredByWeekDay)
                                                    }}>
                                                    {day.substring(0, 2)}
                                                </span>
                                            ))}

                                            {/* DAY */}
                                            {month.map((row, id) => (
                                                <Fragment key={id}>
                                                    {row.map((day, idx) => (
                                                        <div
                                                            onClick={(selectedDay) => {
                                                                const dayClass = selectedDay.target.classList;

                                                                // !select by week
                                                                let filteredByWeek = getDaysInRange("filteredByWeek", selectedDay.target)
                                                                selectedDay.target.attributes.date.value.length <= 2 &&
                                                                    toggleDays(filteredByWeek, dispatch)
                                                                    manageDays(filteredByWeek)


                                                                // !select by day
                                                                if (dayClass.contains("selected-full")) return; // preselected day with full day part has to be unchanged

                                                                if (!dayClass.contains("selected")) { // if day isn't preselected
                                                                    if (
                                                                        typeof day !== "number"
                                                                        && !state.some(e => e.date == dayjs(day).format("YYYY/MM/DD"))
                                                                        && !['Sun', 'Sat'].includes(dayjs(day).format('ddd'))
                                                                        && !selectedDay.target.classList.contains('state-disabled')
                                                                    ) { addDateToState(dayjs(day).format("YYYY/MM/DD"), dispatch) 
                                                                    manageDate( { date: dayjs(day).format("YYYY/MM/DD"), dayPart: "full" } );
                                                                }

                                                                    if (dayClass.contains("bg-success")) {
                                                                        manageDate( { date: dayjs(day).format("YYYY/MM/DD"), dayPart: "ochtend" } );
                                                                        dispatch({ type: 'UPDATE_DAYPART', payload: { date: dayjs(day).format("YYYY/MM/DD"), dayPart: "ochtend" } })
                                                                    } else if (dayClass.contains("bg-warning") && !dayClass.contains("selected")) {
                                                                        manageDate( { date: dayjs(day).format("YYYY/MM/DD"), dayPart: "middag" } );
                                                                        dispatch({ type: 'UPDATE_DAYPART', payload: { date: dayjs(day).format("YYYY/MM/DD"), dayPart: "middag" } })
                                                                    } else if (dayClass.contains("bg-danger")) {
                                                                        removeDate(dayjs(day).format("YYYY/MM/DD"));
                                                                        dispatch({ type: 'REMOVE_DATE', payload: dayjs(day).format("YYYY/MM/DD") })
                                                                    }
                                                                } else { // logic for preselected day
                                                                    if (!dayClass.contains("bg-success")) { // if day part is ochtend/middag change to opposite value and save to state
                                                                        let dayPart = preSelectedDates.find(e => e.date === dayjs(day).format('YYYY/MM/DD')).dayPart === "ochtend" ? "middag" : "ochtend";
                                                                        dispatch({ type: 'ADD_DATE', payload: { date: dayjs(day).format("YYYY/MM/DD"), dayPart: dayPart } })
                                                                    } else { // day part is full, remove from state
                                                                        removeDate(dayjs(day).format("YYYY/MM/DD"));
                                                                        dispatch({ type: 'REMOVE_DATE', payload: dayjs(day).format("YYYY/MM/DD") })
                                                                    }
                                                                }

                                                            }}
                                                            monthid={monthid}
                                                            date={typeof day === "number" ? day : dayjs(day).format("YYYY/MM/DD")}
                                                            key={idx}
                                                            style={{
                                                                width: "calc(2rem + 2px)",
                                                                height: "2rem",
                                                                color: (
                                                                    typeof day == "number"
                                                                    || day.length !== 0 && dayjs(day).format('M') !== dayjs(month[2][2]).format('M')) ? "#8798ad" : ['Sun', 'Sat'].includes(dayjs(day).format('ddd')) && "#cccccc",
                                                            }}
                                                            className={classNames(
                                                                "py-1 rounded-circle text-center",
                                                                {
                                                                    "cursor-pointer": day !== 0,
                                                                    "bg-success text-white": addClassByDayPart("full", day, month, state, preSelectedDates),
                                                                    "bg-warning text-white": addClassByDayPart("ochtend", day, month, state, preSelectedDates),
                                                                    "bg-danger text-white": addClassByDayPart("middag", day, month, state, preSelectedDates),
                                                                    "border border-3 border-dark today":
                                                                        typeof day !== "number"
                                                                        && dayjs(day).format("YYYY/MM/DD") === dayjs().format('YYYY/MM/DD')
                                                                        && (day.length !== 0 && dayjs(day).format('M') == dayjs(month[2][2]).format('M')),
                                                                    "fw-bolder":
                                                                        (day.length > 2 && dayjs(day).format('M') == dayjs(month[2][2]).format('M')),
                                                                    "state-disabled":
                                                                        ['Sun', 'Sat'].includes(dayjs(day).format('ddd'))
                                                                        || typeof day == "number"
                                                                        || (day.length !== 0 && dayjs(day).format('M') !== dayjs(month[2][2]).format('M')),
                                                                    // classes for preselected dates with ochtend/middag day; borders for all dayParts
                                                                    "selected border border-2 border-info":
                                                                        preSelectedDates
                                                                        && preSelectedDates.map(day => day.date).includes(dayjs(day).format("YYYY/MM/DD"))
                                                                        && !(day.length !== 0 && dayjs(day).format('M') !== dayjs(month[2][2]).format('M')),
                                                                    // class for preselected dates with full day
                                                                    "selected-full bg-success text-white":
                                                                        preSelectedDates
                                                                        && checkPreSelectedDayPart(dayjs(day).format("YYYY/MM/DD"))
                                                                        && !(day.length !== 0 && dayjs(day).format('M') !== dayjs(month[2][2]).format('M')),
                                                                    "fs-7 pe-3" : typeof day == "number"
                                                                }
                                                            )}
                                                        >
                                                            {typeof day === "number" ? (day !== 0 && day) : dayjs(day).format('D')}
                                                        </div>
                                                    ))}
                                                </Fragment>
                                            ))}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    <button
                        onClick={e => nextMonth(e)}
                        className="bg-white border-0 p-0">
                        <i className="pe-7s-angle-right text-info fs-1"></i>
                    </button>
                </div>
                <div className="mt-2">
                    <div className="d-flex justify-content-center align-items-end">
                        <div className="me-5 calendar-agenda__item calendar-agenda__item-f">Hele Dag</div>
                        <div className="me-5 calendar-agenda__item calendar-agenda__item-m">Ochtend</div>
                        <div className="me-5 calendar-agenda__item calendar-agenda__item-o">Middag</div>
                        {/* {isDisabled && <div className="text-danger">Selecteer minimaal 1 of meer dagdelen</div>} */}
                    </div>

                </div>
        </Fragment>
    )
}

export default Calender;
