import { useState } from 'react';
import dayjs from "dayjs";
import { Button, Card, CardBody, CardFooter, Label } from 'reactstrap'
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import CommunityTypesModal from 'Components/Availability/CommunityTypesModal';
import DatesLayout from 'Components/Availability/DatesLayout';
import LegendModal from 'Components/Availability/LegendModal';
import { userTabs } from '../utils';

const modalText = "Geef hieronder aan voor welk bestuur je een aparte bestuursbeschikbaarheid  voor deze vervanger wilt invullen"

const Beschikbaarheid = ({ currentUser, setActiveTab }) => {
    const [years, setYears] = useState(`${dayjs().year()}-${dayjs().year() + 1}`);
    const [openLegend, setOpenLegend] = useState(false);
    const yearsRange = Array.from({ length: 4 }, (_, i) => `${dayjs().year() + i}-${dayjs().year() + i + 1}`);
    const [communities, setCommunities] = useState([]);
    const [openInstitutions, setOpenInstitutions] = useState(false);
    const isStandart = (communities.length && communities.some(comm => comm.type !== "S")) ? "A" : "S";
    const [type, setType] = useState(isStandart);

    return (
        <Card className="main-card ">
            <div className='row beschikbaarheid'>
                <div className='col-12'>
                    <CardBody className="p-3 table-responsive">
                        <DatesLayout iAdminId={currentUser.data.clooserId} years={years} type={type} setCommunities={setCommunities} />
                    </CardBody>
                </div>
            </div>
            <div className="chat-layout-footer" style={{ height: '80px' }}>
                <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                    <div className="d-flex gap-3 align-items-end w-100">
                        <div className='d-flex flex-column'>
                            <Label className="overview-page-label">Schooljaar</Label>
                            <select className={`form-select`} onChange={(e) => setYears(e.target.value)}>
                                <option value='' disabled>Selecteer schooljaar</option>
                                {yearsRange.map((item) => {
                                    return <option value={item} key={item}>{item}</option>
                                })}
                            </select>
                        </div>
                        <Button
                            outline
                            className="btn-outline-primary-blue"
                            onClick={() => setOpenInstitutions(true)}
                            type="button"
                            color="primary"
                        >
                            Instellingen
                        </Button>
                        <Button
                            outline
                            className="btn-outline-primary-blue"
                               onClick={() => setOpenLegend(true)}
                            type="button"
                            color="primary"
                        >
                            Legenda
                        </Button>
                        <Button
                            outline
                            className="btn-outline-primary-blue ms-auto mt-auto"
                            onClick={() => {
                                setActiveTab(userTabs.Functies)
                            }}
                            type="button"
                            color="primary"
                        >
                            <BackIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
            <LegendModal isOpen={openLegend} open={setOpenLegend} />
            <CommunityTypesModal iAdminId={currentUser.data.clooserId} isOpen={openInstitutions} open={setOpenInstitutions} communities={communities} type={type} setType={setType} text={modalText} />
        </Card>
    )
}

export default Beschikbaarheid

