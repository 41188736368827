import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import DatePicker from "react-datepicker";
import moment from "moment";

const Institution = ({ data, changeHandler, errorField,contractList }) => {
  
    const defaultValues = {
        iContractTypeId: data?.iContractTypeId || '',
        dContracttypeExpire: data?.dContracttypeExpire && data?.dContracttypeExpire !== "Invalid Da" ? moment(data?.dContracttypeExpire, 'DD-MM-YYYY').toDate() : null,
        inputField1: data?.inputField1 || '',
        inputField2: data?.inputField2 || '',
        inputField3: data?.inputField3 || '',
        vWtf: data?.vWtf
    };

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Instellingen [Organisation]</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, handleChange, setFieldValue }) => (
                        <Form>
                            <FormGroup className={`form-element ${errorField === 'iContractTypeId' ? 'error' : ''}`}>
                                <Label for="iContractTypeId">Contracttype</Label>
                                <select
                                    className="form-select"
                                    name="iContractTypeId"
                                    value={values.iContractTypeId}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('iContractTypeId', e.target.value);
                                    }}
                                >
                                    <option value="">Selecteer contracttype</option>
                                    {contractList.map(item => {
                                        return <option key={item.iContractTypeId} value={item.iContractTypeId}>{`${item.vContractTypeAbbr} - ${item.vContractTypeName}`}</option>
                                    })}
                                </select>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'dContracttypeExpire' ? 'error' : ''}`}>
                                <Label for="dContracttypeExpire">Contracttype verloopdatum</Label>
                                <div className="position-relative w-100">
                                <DatePicker
                                    selected={values.dContracttypeExpire}
                                    onChange={(date) => {
                                        const formattedDate = moment(date).format('DD-MM-YYYY');
                                        setFieldValue("dContracttypeExpire", date);
                                        changeHandler('dContracttypeExpire', formattedDate);
                                    }}
                                    placeholderText={'dd-mm-jjjj'}
                                    locale="nl"
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                />
                                </div>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vWtf' ? 'error' : ''}`}>
                                <Label for="vWtf">WTF Pooler</Label>
                                <Input
                                    name="vWtf"
                                    type="text"
                                    value={values.vWtf}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vWtf', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'inputField1' ? 'error' : ''}`}>
                                <Label for="inputField1">Vrije veld 1</Label>
                                <Input
                                    name="inputField1"
                                    type="text"
                                    value={values.inputField1}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('inputField1', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'inputField2' ? 'error' : ''}`}>
                                <Label for="inputField2">Vrije veld 2</Label>
                                <Input
                                    name="inputField2"
                                    type="text"
                                    value={values.inputField2}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('inputField2', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'inputField3' ? 'error' : ''}`}>
                                <Label for="inputField3">Vrije veld 3</Label>
                                <Input
                                    name="inputField3"
                                    type="text"
                                    value={values.inputField3}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('inputField3', e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default Institution