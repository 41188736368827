import axiosInstance from 'Api';

const getMatchcodesData = (data) => {
    return axiosInstance.post("gebruiker/matchcode/overview", data);
};

const saveMatchcodeData = (data)=>{
    return axiosInstance.post("gebruiker/matchcode/save", data);
}

const GebruikerMatchcodesService = {
    getMatchcodesData,
    saveMatchcodeData
};
export default GebruikerMatchcodesService;