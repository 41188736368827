export const SET_HOMEITEM_COUNT = 'SET_HOMEITEM_COUNT';

export const setHomeItemCount = homeItemCount => ({
    type: SET_HOMEITEM_COUNT,
    homeItemCount
});

export default function VervangerMenuCount(state = {
    homeItemCount : 0
}, action) {
    switch (action.type) {
        case SET_HOMEITEM_COUNT:
            return {
                ...state,
                homeItemCount: action.homeItemCount,
            };
            
        default:
            return state;
    }
}