import React, { useEffect,useState} from "react";
import { useFormikContext } from "formik";
import DropdownList from "react-widgets/DropdownList";
import axiosInstance from "Api";
import ExportService from "Api/Export";
import {ErrorMessage} from "formik";

import {
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Formik } from "formik";
import DefaultButton from "Components/Buttons/DefaultButton";
registerLocale("nl", nl);

const options = [{ value: "dagdelen", name: "Dagdelen" }];

const ExportForm = () => {

    var date = new Date();
    let setEndDateperiod = date.setMonth(date.getMonth() + 1);

    const formikProps = useFormikContext()
    const [data, setData] = useState([]);
    const [communityId, setcommunityId] = useState(null);
    const [schools, setSchools] = useState([]);
    const [schoolId, setSchoolId] = useState(null);
    const [exportList, setexportList] = useState([]);
    const [beginDate, setBeginDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(setEndDateperiod));

    useEffect(() => {
        getOrganizationsList();
        getExportList();
    
        const interval = setInterval(() => {
          getExportList();
        }, 20000);
        return () => {
          clearTimeout(interval);
        };
      }, []);

    const getExportList = () => {
        ExportService.getExportList()
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.data != null && response.data.data != undefined) {
                        setexportList(response.data.data);
                    }
                }
            })
            .catch((error) => console.log("catch error:", error));
    };

    const getOrganizationsList = () => {
        axiosInstance
            .get("organizations-list")
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.data != null && response.data.data != undefined) {
                      if(response.data.data.length === 1)
                      {
                        formikProps.setFieldValue("organization", response.data.data[0].iCommunityId);
                        getSchoolsByCommunityId(response.data.data[0].iCommunityId);
                      }
                        setData(response.data.data);

                    }
                }
            })
            .catch((error) => console.log("catch error:", error));
    };

    const getSchoolsByCommunityId = (id) => {
        setcommunityId(id);
        axiosInstance
            .get("schools-list?iCommunityId=" + id)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.data != null && response.data.data != undefined) {
                      if(response.data.data.length === 1)
                      {
                        formikProps.setFieldValue("school", response.data.data[0].iSchoolId);
                      }
                        setSchools(response.data.data);
                    }
                }
            })
            .catch((error) => console.log("catch error:", error));
    };

    const downloadFile = (data) => {
        let postData = {
            id: data.id,
            verifier: data.verifier,
        };
        axiosInstance
            .post("download-export", postData)
            .then((response) => {
                console.log(response);
                if (response.status == 200 && response.data.content !== "") {
                    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.content}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "export-report.xlsx";

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            })
            .catch((error) => console.log("catch error:", error));
    };
    return (
        <>
                <Form>
                  <FormGroup row>
                    <Label  hidden>{"Selecteer export"}</Label>
                    <Col>
                      <DropdownList
                        dataKey="value"
                        textField="name"
                        placeholder="Kies export"
                        defaultValue={"dagdelen"}
                        onChange={(nextValue) =>
                          console.log("Default Value dagdelen:")
                        }
                        name="type"
                        data={options}
                        hidden
                      />
                    </Col>
                  </FormGroup>

                  <Row>
                    <Col sm={3}>
                      <FormGroup row>
                        <Label sm={5} className="export-label-style">{"Organisatie"}</Label>
                        <Col sm={7}>
                          <DropdownList
                            dataKey="iCommunityId"
                            textField="name"
                            placeholder="Kies organisatie"
                            value={formikProps.values.organization}
                            class={formikProps.errors.organization && formikProps.touched.organization ? "form-control is-invalid error-export" : ""}
                            onChange={(community) => {
                              getSchoolsByCommunityId(community.iCommunityId);
                              formikProps.setFieldValue(
                                "organization",
                                community.iCommunityId
                              );
                            }}
                            name="organization"
                            data={data}
                          />
                          <ErrorMessage name="organization" component="small"
                            className="block mt-2 invalid-feedback"/>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup row>
                        <Label sm={4} className="export-label-style">{"School"}</Label>
                        <Col sm={8}>
                          <DropdownList
                            dataKey="iSchoolId"
                            textField="vSchoolName"
                            placeholder="Kies school"
                            value={formikProps.values.school}
                            class={formikProps.errors.school && formikProps.touched.school ? "form-control is-invalid error-export" : ""}
                            onChange={(school) => {
                              setSchoolId(school.iSchoolId);
                              formikProps.setFieldValue("school", school.iSchoolId);
                            }}
                            name="school"
                            data={schools}
                          />
                          <ErrorMessage name="school" component="small"
                            className="block mt-2 invalid-feedback"/>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup row>
                        <Label sm={5} className="export-label-style">{"Begindatum"}</Label>
                        <Col sm={7}>
                          <InputGroup>
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>

                            <DatePicker
                              locale="nl"
                              selected={beginDate}
                              className={formikProps.errors.dateBeginPeriod && formikProps.touched.dateBeginPeriod ? "form-control is-invalid" : "form-control"}
                              onChange={(date) => {
                                setBeginDate(date);
                                formikProps.setFieldValue("dateBeginPeriod", date);
                              }}
                              selectsStart
                              name="dateBeginPeriod"
                              dateFormat="dd-MM-yyyy"
                            />
                            <ErrorMessage name="dateBeginPeriod" component="small"
                              className="block mt-2 invalid-feedback"/>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup row>
                        <Label sm={5} className="export-label-style">{"Einddatum"}</Label>
                        <Col sm={7}>
                          <InputGroup>
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>

                            <DatePicker
                              locale="nl"
                              selected={endDate}
                              className={formikProps.errors.dateEndPeriod && formikProps.touched.dateEndPeriod ? "form-control is-invalid" : "form-control"}
                              onChange={(date) => {
                                setEndDate(date);
                                formikProps.setFieldValue("dateEndPeriod", date);
                              }}
                              selectsEnd
                              startDate={beginDate}
                              endDate={endDate}
                              minDate={beginDate}
                              name="dateEndPeriod"
                              dateFormat="dd-MM-yyyy"
                            />
                            <ErrorMessage name="dateEndPeriod" component="small"
                                className="block mt-2 invalid-feedback"/>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col sm={12} className="d-flex justify-content-center">
                      <FormGroup row>
                        <Col sm={12}>
                            <DefaultButton  className={"mb-2 me-0 btn-color-style"} type="submit">
                                Export samenstellen
                            </DefaultButton>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              
            </>
    )
}

export default ExportForm;
