import axiosInstance from "Api";

const upload = (url, file) => {
    let formData = new FormData();
    formData.append('file', file);
    // formData.append('_method', 'PATCH');

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return axiosInstance.post(url, formData, config);
}

export default {
    upload
}