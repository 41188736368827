import React, { useState } from "react";
import { Button, Card, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { ReactComponent as RoundPlus } from "../../../../../assets/utils/images/svgs/RoundPlus.svg";
import { ReactComponent as BlueHistorie } from "../../../../../assets/utils/images/svgs/BlueHistorie.svg";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as Save } from "../../../../../assets/utils/images/svgs/save_two.svg";
import { ReactComponent as Small_Arrow } from "../../../../../assets/utils/images/svgs/Small_Arrow.svg";
import { ReactComponent as Undo } from "../../../../../assets/utils/images/svgs/undo-svgrepo-com.svg";
import { toast } from "react-toastify";
import SweetAlert from 'react-bootstrap-sweetalert';
import TextLimit from "Api/TextLimit";
import { Form, Formik } from "formik";
import { ErrorMessage } from "formik";
import * as Yup from "yup";
import VerzoekenService from "Api/Verzoeken";

const Chat = (props) => {

    const [isEmpty, setIsEmpty] = useState(false);
    const params = useParams();
    const substitutionId = params.id || '';
    const [isShrink, setIsShrink] = useState(true);
    const [isSmall, setIsSmall] = useState(false);
    const [memoListIsOpen, setMemoListIsOpen] = useState(false);
    const [memoIsOpen, setMemoIsOpen] = useState(false);
    const [memoTitle, setMemoTitle] = useState('');
    const [memoType, setMemoType] = useState(0);
    const [memoMessage, setMemoMessage] = useState("");
    const [memoId, setMemoId] = useState(0);
    const [iSubsDateId, setISubsDateId] = useState(0);
    const [memoValue, setMemoValue] = useState('');
    const [shiftList, setShiftList] = useState([]);
    const [showArchiveModel, setShowArchiveModel] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [messageId, setMessageId] = useState(0);
    const [showUnArchiveModel, setShowUnArchiveModel] = useState(false);
    const [formDataState, setFormDataState] = useState({
        message: '',
        vervanger: '',
        dagdeel: '',
    });
  
    const titleSwitch = (param) => {
        switch (param) {
            case '1':
                return 'Memo mbt school';
            case '2':
                return 'Memo tbv coordinator';
            case '3':
                return 'Memo tbv weekstaat';
            case '4':
                return 'Memo tbv salarisadministratie';
            case '5':
                return 'Memo dagdeel vervanger';
            case '6':
                return 'Memo melder aan vervanger';
            default:
                return '';
        }
    }

    const memoValueSwitch = (param) => {
        switch (param) {
            case '1':
                return props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.tSchool_memo : props.list[0].data.Middag.tSchool_memo;
            case '2':
                return props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.tPrivate : props.list[0].data.Middag.tPrivate;
            case '3':
                return props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.tweekstaat : props.list[0].data.Middag.tweekstaat;
            case '4':
                return props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.vSalaryComments : props.list[0].data.Middag.vSalaryComments;
            case '6':
                return props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.vSubstitutorComments : props.list[0].data.Middag.vSubstitutorComments;
            default:
                return '';
        }
    }

    const setMemo = (value) => {
        if (value != '') {
            if (value == '1') {
                if (props.list.length > 0) {
                    if (props.list[0].data.Ochtend) {
                        setMemoId(props.list[0].data.Ochtend.iSchoolId);
                    } else {
                        setMemoId(props.list[0].data.Middag.iSchoolId);
                    }
                } else {
                    setMemoId(0);
                }
            } else {
                setMemoId(substitutionId);
            }
            setMemoType(value);
            setMemoTitle(titleSwitch(value));
            setMemoValue(memoValueSwitch(value));
            setMemoIsOpen(true);
            setMemoListIsOpen(false);
        }
    }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('id', memoId);
        formData.append('iSubsDateId', iSubsDateId);
        formData.append('type', memoType);
        formData.append('memo', memoMessage);

        VerzoekenService.updateMemo(formData).then((response) => {
            if (response.status === 200) {
                toast["success"]("Memo succesvol opgeslagen")
                setMemoIsOpen(false);
                props.getRequests();
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const onChangeMemo = (value) => {
        setMemoMessage(value);
    }

    const getShiftList = (value) => {
        VerzoekenService.vervangerShiftList(substitutionId,value).then((response) => {
            if (response.status === 200) {
                setShiftList(response.data.data);
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const isArchivedOrUnArchived = () => {
        let formData = new FormData();
        formData.append('id', messageId);
        formData.append('type', messageType);

        VerzoekenService.commentStatus(formData).then((response) => {
            if (response.status === 200) {
                props.getArchivedMessages(props.isChecked == false ? 1 : 0)
                setShowArchiveModel(false);
                setShowUnArchiveModel(false);
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const confirm = (id, type) => {
        setShowArchiveModel(true);
        setMessageId(id);
        setMessageType(type)
    }

    const confirmUnArchive = (id, type) => {
        setShowUnArchiveModel(true);
        setMessageId(id);
        setMessageType(type)
    }

    const validationSchema = Yup.object().shape({
        message: Yup.string().required("veld is verplicht"),
        vervanger: Yup.number().required("veld is verplicht"),
        dagdeel: Yup.number().required("veld is verplicht"),
    });

    // if(data.length === 0 ) return null;

    return (
                <Card className={cx("app-inner-layout__sidebar chatbox-fixed chat-box-styling", { "position-absolute height-100 end-0": !isShrink }, { "w-50": isSmall })} >
                    <div className="app-inner-layout__sidebar-inner d-flex flex-column overflow-hidden coor-chat-memo-box">
                        {isEmpty && <span className="text-danger px-3">Bericht is leeg</span>}
                        <div className="chat-box-header">
                            {/* <div className="mx-2 d-flex align-items-center">

                        </div> */}
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <Button size="sm" outline className="me-4 btn-color-style opmerking-chat-btn" color="primary" onClick={() => props.setIsOpen(true)}>
                                        <RoundPlus />
                                        <span className="ms-1">Opmerking</span>
                                    </Button>
                                    <Button size="sm" outline className="me-2 btn-color-style opmerking-chat-btn" color="primary" onClick={() => setMemoListIsOpen(true)}>
                                        <RoundPlus />
                                        <span className="ms-1"> Memo</span>
                                    </Button>
                                </div>
                                <div>
                                    <label class="switch">
                                        <input type="checkbox" checked={props.isChecked} onClick={() => props.switchIsChecked(props.isChecked == false ? 0 : 1)} />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="scroll-area-lg">
                            {/* <PerfectScrollbar> */}
                            <div className="p-1">
                                <div className="chat-wrapper p-1 d-flex flex-column-reverse">
                                    <div className="chat-box-wrapper mb-3 ">
                                        {props.comments.length > 0 &&
                                            <div className="coordinator-chat-box mb-5">
                                                {
                                                    props.comments.map((msg, i) => {
                                                        return (

                                                            <div>
                                                                <small className="opacity-6 text-capitalize">
                                                                    <span className="fw-bold me-1 chat-time">{moment(msg.iTimestamp * 1000).format("DD-MM-YYYY")}</span>
                                                                    <span class="me-2 chat-time">{moment(msg.iTimestamp * 1000).format("HH:mm")}</span>
                                                                    {msg.vMelderName}
                                                                </small>
                                                                {
                                                                    msg.is_archive == '1' ? <span className="chat-icon-styling" onClick={() => confirmUnArchive(msg.id, 0)}><Undo /></span> : <span className="chat-icon-styling" onClick={() => confirm(msg.id, 1)}><BlueHistorie /></span>
                                                                }
                                                                <div className="chat-box text-pre-wrap mt-2 mb-2 ">
                                                                    {msg.vDirComments}
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                }

                                            </div>
                                        }

                                        {
                                            props.list.length > 0 &&

                                            <div className="coordinator-memo-box">
                                                {
                                                    props.list.length > 0 && ((props.list[0].data.Ochtend !== undefined && props.list[0].data.Ochtend.tSchool_memo !== undefined && props.list[0].data.Ochtend.tSchool_memo !== null && props.list[0].data.Ochtend.tSchool_memo !== '') || (props.list[0].data.Middag !== undefined && props.list[0].data.Middag.tSchool_memo !== undefined && props.list[0].data.Middag.tSchool_memo !== null && props.list[0].data.Middag.tSchool_memo !== '')) &&
                                                    <div className="mt-2">
                                                        <small className="opacity-6 text-capitalize">
                                                            Memo mbt school
                                                        </small>
                                                        <span className="chat-icon-styling" onClick={() => setMemo('1')}><EditPen /></span>
                                                        <div className="chat-box text-pre-wrap ">
                                                            <p>{props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.tSchool_memo : props.list[0].data.Middag.tSchool_memo}</p>
                                                        </div>
                                                    </div>
                                                }


                                                {
                                                    props.list.length > 0 && ((props.list[0].data.Ochtend !== undefined && props.list[0].data.Ochtend.tPrivate !== undefined && props.list[0].data.Ochtend.tPrivate !== null && props.list[0].data.Ochtend.tPrivate !== '') || (props.list[0].data.Middag !== undefined && props.list[0].data.Middag.tPrivate !== undefined && props.list[0].data.Middag.tPrivate !== null && props.list[0].data.Middag.tPrivate !== '')) &&
                                                    <div className="mt-2">
                                                        <small className="opacity-6 text-capitalize">
                                                            Memo tbv coordinator
                                                        </small>
                                                        <span className="chat-icon-styling" onClick={() => setMemo('2')}><EditPen /></span>
                                                        <div className="chat-box text-pre-wrap ">
                                                            <p>{props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.tPrivate : props.list[0].data.Middag.tPrivate}</p>
                                                        </div>
                                                    </div>
                                                }


                                                {
                                                    props.list.length > 0 && ((props.list[0].data.Ochtend !== undefined && props.list[0].data.Ochtend.tweekstaat !== undefined && props.list[0].data.Ochtend.tweekstaat !== null && props.list[0].data.Ochtend.tweekstaat !== '') || (props.list[0].data.Middag !== undefined && props.list[0].data.Middag.tweekstaat !== undefined && props.list[0].data.Middag.tweekstaat !== null && props.list[0].data.Middag.tweekstaat !== '')) &&
                                                    <div className="mt-2">
                                                        <small className="opacity-6 text-capitalize">
                                                            Memo tbv  weekstaat
                                                        </small>
                                                        <span className="chat-icon-styling" onClick={() => setMemo('3')}><EditPen /></span>
                                                        <div className="chat-box text-pre-wrap ">
                                                            <p>{props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.tweekstaat : props.list[0].data.Middag.tweekstaat}</p>
                                                        </div>
                                                    </div>
                                                }


                                                {
                                                    props.list.length > 0 && ((props.list[0].data.Ochtend !== undefined && props.list[0].data.Ochtend.vSalaryComments !== undefined && props.list[0].data.Ochtend.vSalaryComments !== null && props.list[0].data.Ochtend.vSalaryComments !== '') || (props.list[0].data.Middag !== undefined && props.list[0].data.Middag.vSalaryComments !== undefined && props.list[0].data.Middag.vSalaryComments !== null && props.list[0].data.Middag.vSalaryComments !== '')) &&
                                                    <div className="mt-2">
                                                        <small className="opacity-6 text-capitalize">
                                                            Memo tbv salarisadministratie
                                                        </small>
                                                        <span className="chat-icon-styling" onClick={() => setMemo('4')}><EditPen /></span>
                                                        <div className="chat-box text-pre-wrap ">
                                                            <p>{props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.vSalaryComments : props.list[0].data.Middag.vSalaryComments}</p>
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    props.list.length > 0 && ((props.list[0].data.Ochtend !== undefined && props.list[0].data.Ochtend.vSubstitutorComments !== undefined && props.list[0].data.Ochtend.vSubstitutorComments !== null && props.list[0].data.Ochtend.vSubstitutorComments !== '') || (props.list[0].data.Middag !== undefined && props.list[0].data.Middag.vSubstitutorComments !== undefined && props.list[0].data.Middag.vSubstitutorComments !== null && props.list[0].data.Middag.vSubstitutorComments !== '')) &&
                                                    <div className="mt-2">
                                                        <small className="opacity-6 text-capitalize">
                                                            Memo melder aan vervanger
                                                        </small>
                                                        <span className="chat-icon-styling" onClick={() => setMemo('6')}><EditPen /></span>
                                                        <div className="chat-box text-pre-wrap ">
                                                            <p>{props.list[0].data.Ochtend !== undefined ? props.list[0].data.Ochtend.vSubstitutorComments : props.list[0].data.Middag.vSubstitutorComments}</p>
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            {/* </PerfectScrollbar> */}
                        </div>
                    </div>
                    <Modal id={"tooltip"} isOpen={memoListIsOpen} toggle={() => setMemoListIsOpen(!memoListIsOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling">
                        <ModalHeader>
                            <div className="modal-header-styling">
                                Selecteer Memo
                            </div>
                            <div className="cross-icon-color">
                                <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setMemoListIsOpen(false)}> </i>
                            </div>
                        </ModalHeader>

                        <ModalBody className="d-flex bg-white rounded">
                            <select className="memo-select-box" onChange={(e) => setMemo(e.target.value)}>
                                <option value="">Selecteren</option>
                                <option value="1">Memo mbt school</option>
                                <option value="2">Memo tbv coordinator</option>
                                <option value="3">Memo tbv weekstaat</option>
                                <option value="4">Memo tbv salarisadministratie</option>
                                <option value="6">Memo melder aan vervanger</option>
                                <option value="5">Memo dagdeel vervanger</option>
                            </select>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>

                    <Modal id={"tooltip"} isOpen={memoIsOpen} toggle={() => setMemoIsOpen(!memoIsOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling vervanger-memo-modal ">
                        <ModalHeader>
                            <div className="modal-header-styling">
                                {memoTitle}
                            </div>
                            <div className="cross-icon-color">
                                <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setMemoIsOpen(false)}> </i>
                            </div>
                        </ModalHeader>

                        <ModalBody className="bg-white rounded">
                            <Formik
                                enableReinitialize
                                initialValues={formDataState}
                                validationSchema={memoType == '5' ? validationSchema : ''}
                                onSubmit={
                                    (values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                        {
                                            memoType == '5' &&
                                            <>
                                                <Label className="vervanger-lable-styling">Selecteer Vervanger</Label>
                                                <select name='vervanger' className="memo-select-box" onChange={(e) => {
                                                    getShiftList(e.target.value)
                                                    setFormDataState({ ...formDataState, vervanger: e.target.value })
                                                }}>
                                                    <option value="">Selecteren</option>
                                                    {
                                                        props.nameList.map((block, i) => {
                                                            return <option value={block.iAdminId}>{TextLimit.AfwezigeLimitOptionOne(block.vFirstName, block.vMiddleName, block.vLastName)}</option>;
                                                        })
                                                    }

                                                </select>
                                                <ErrorMessage name="vervanger" component="small"
                                                    className="block mt-2 invalid-feedback" />

                                                <Label className="vervanger-lable-styling mt-3">Selecteer Dagdeel</Label>
                                                <select name='dagdeel' className="memo-select-box" onChange={(e) => {
                                                    setISubsDateId(e.target.value)
                                                    setFormDataState({ ...formDataState, dagdeel: e.target.value })
                                                }}>
                                                    <option value="">Selecteren</option>
                                                    {
                                                        shiftList.map((block, i) => {
                                                            return <option value={block.iSubsDateId}>{block.vSubsDate} - {block.vShift}</option>;
                                                        })
                                                    }
                                                </select>
                                                <ErrorMessage name="dagdeel" component="small"
                                                    className="block mt-2 invalid-feedback" />
                                            </>
                                        }

                                        <textarea
                                            className="form-style opmerking-input-styling mt-3"
                                            name='message'
                                            onChange={(e) => {
                                                onChangeMemo(e.target.value)
                                                setFormDataState({ ...formDataState, message: e.target.value })
                                            }}
                                            rows="6"
                                        >{memoValue}</textarea>
                                        <ErrorMessage name="message" component="small"
                                            className="block mt-2 invalid-feedback" />
                                        <ModalFooter>
                                        <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" type="submit">
                                            <Save />
                                            Opslaan
                                        </Button>
                                        <Button className="d-flex align-items-center btn-color-style back-btn" size="sm" outline color="primary" onClick={() => setMemoIsOpen(false)}>
                                            <Small_Arrow />
                                            Terug
                                        </Button>
                                        </ModalFooter>
                                    </Form>
                                )}
                            </Formik>
                        </ModalBody>

                    </Modal>
                    <SweetAlert title="Wilt u dit bericht markeren als gelezen ?" show={showArchiveModel}
                        type="warning"
                        onCancel={() => setShowArchiveModel(false)}
                        onConfirm={() => isArchivedOrUnArchived()}
                        cancelBtnText="Nee"
                        confirmBtnText="Ja"
                        showCancel={true}
                    />

                    <SweetAlert title="Wilt u dit bericht markeren als ongelezen ?" show={showUnArchiveModel}
                        type="warning"
                        onCancel={() => setShowUnArchiveModel(false)}
                        onConfirm={() => isArchivedOrUnArchived()}
                        cancelBtnText="Nee"
                        confirmBtnText="Ja"
                        showCancel={true}
                    />
                </Card>
    );
}

export default Chat;
