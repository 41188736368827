export const SET_USER_ROLE = 'SET_ROLE';

export const setUserRole = roles => ({
    type: SET_USER_ROLE,
    roles
});

export default function UserPermission(state = {
    roles: []
}, action){
    switch (action.type) {
        case SET_USER_ROLE:
            return {
                ...state,
                roles: action.roles
            };
        default:
            return state;
    }
}