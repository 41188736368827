import React from 'react'

export default function Select({label, options, value, onChange, selectClassName, disabled = false}) {
  return (
    <div className="form-checkbox-styling select__wrapper">
      <div className="col-md-10 selectBy p-0 select__container">
        {
          label && <label className='select__label'>{label}</label>
        }
        <select
          className={`form-select select ${selectClassName}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {
            options.map(option => (
              <option key={option.value} value={option.value} selected={option.selected}>
                {option.label}
              </option>
            ))
          }
        </select>
      </div>
    </div>
  )
}
