import { useEffect, useState } from 'react'
import Institution from './Institution'
import Arrangement from './Arrangement'
import Salary from './Salary'
import Remark from './Remark'
import Contracts from './Contracts'
import Log from './Log'
import { Button, Card, CardBody, CardFooter } from 'reactstrap'
import Loader from 'react-loaders'
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/utils/images/svgs/addBlue.svg"
import { debounce, userTabs } from '../utils'
import { toast } from 'react-toastify'
import GebruikerInstellingenService from 'Api/Gebruikers/GebruikersView/Instellingen'

const Instellingen = ({ isLoading, data, setActiveTab, adminCommunityList, currentUser, filterCommunity, setFilterCommunity, setDataUpdated }) => {
    const [errorField, setErrorField] = useState('');
    const [contractModal, setContractModal] = useState({ type: "add", state: false });
    const [contractList, setContractList] = useState([]);

    useEffect(() => {
        if (filterCommunity[0].iCommunityId && currentUser.data.clooserId) {
            fetchContractList(filterCommunity[0].iCommunityId, currentUser.data.clooserId)
        }
    }, [filterCommunity, currentUser]);

    const fetchContractList = async (id, iAdminId) => {
        try {
            const response = await GebruikerInstellingenService.contractType(id, iAdminId);
            if (response.data.status === 200 && response.data.data.length > 0) {
                setContractList(response.data.data);
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    };

    const callApi = (field, value, lockState = null) => {
        const data = {
            iAdminId: currentUser.data.clooserId,
            attr: field,
            [field]: field === "iContractTypeId" ? Number(value) : value,
            iCommunityId: Number(filterCommunity[0].iCommunityId)
        }
        if (field === "iContractTypeId") {
            const selectedContract = contractList?.find((item) => item.iContractTypeId === Number(value));
            data.iContractLogLabel = selectedContract ? `${selectedContract.vContractTypeAbbr} - ${selectedContract.vContractTypeName}` : ''
        }
        if (lockState === true || lockState === false) {
            data.type = "settings_locked";
            data.lock = lockState ? 1 : 0
        }
        GebruikerInstellingenService.updateInstellingenDetail(data)
            .then(response => {
                if (response.data.status) { if (lockState === true || lockState === false) { setDataUpdated() }; return };
                if (!response.data.success) {
                    setErrorField(field);
                    const errors = response.data.data[field];
                    if (Array.isArray(errors)) {
                        toast.error(errors.join(','));
                    } else {
                        toast.error(errors);
                    }
                }
            })
            .catch(error => {
                console.error('API error:', error);
            });
    };

    const debouncedApiCall = debounce(callApi, 1000);

    const handleFieldChange = (field, value, lockState = null) => {
        setErrorField('');
        debouncedApiCall(field, value, lockState);
    };

    return (
        <>
            {
                isLoading ?
                    <Card className="main-card">
                        <CardBody className="d-flex flex-column">
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </CardBody>
                    </Card> : <>
                        <div className='row instellingen'>
                            <div className='col-xxl-4'>
                                <Institution data={data?.organisation} errorField={errorField} changeHandler={handleFieldChange} contractList={contractList}/>
                            </div>
                            <div className='col-xxl-4'>
                                <Arrangement data={data?.ketenregeling} errorField={errorField} changeHandler={handleFieldChange} />
                            </div>
                            <div className='col-xxl-4'>
                                <Salary data={data?.salaris} currentUser={currentUser} errorField={errorField} changeHandler={handleFieldChange} />
                            </div>
                            <div className='col-12'>
                                <Remark data={data?.vMemo} changeHandler={handleFieldChange} />
                            </div>
                            <div className='col-12'>
                                <Contracts setDataUpdated={setDataUpdated} data={data?.contracten} modal={contractModal} setModal={setContractModal} currentUser={currentUser} filterCommunity={filterCommunity} />
                            </div>
                            <div className='col-12'>
                                <Log data={data?.logboek} />
                            </div>
                        </div>
                        <div className="chat-layout-footer py-5">
                            <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                <div className="d-flex gap-3">
                                    <select className={`form-select`} value={filterCommunity[0]?.iCommunityId} onChange={(e) => { setFilterCommunity(adminCommunityList.filter(item => item?.iCommunityId === Number(e.target.value))) }}>
                                        <option value={''} disabled>Alle besturen</option>
                                        {adminCommunityList.map(item => {
                                            return <option value={item.iCommunityId} key={item.iCommunityId}>{item.vCommunityName}</option>
                                        })}
                                    </select>
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue d-flex justify-content-center align-items-center gap-2"
                                        onClick={() => setContractModal({ type: "add", state: true })}
                                        type="button"
                                        color="primary"
                                    >
                                        Contract <AddIcon />
                                    </Button>
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue ms-auto"
                                        onClick={() => {
                                            setActiveTab(userTabs.Algemeen)
                                        }}
                                        type="button"
                                        color="primary"
                                    >
                                        <BackIcon />
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </>
            }

        </>
    )
}

export default Instellingen