import axiosInstance from 'Api';

const getCommunityContractList = () => {
    return axiosInstance.get('gebruiker/get-communitycontract-list')
};

const adminList = (data) => {
    return axiosInstance.post('gebruiker/get-admins-list', data);
};

const GebruikersService = {
    getCommunityContractList,
    adminList
};
export default GebruikersService;