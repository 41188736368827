import ReactTableGeneric from "Components/ReactTableGeneric";
import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import WeekReportInfo from "./WeekReportInfo";
import WeekReportTable from "./WeekReportTable";


const WeekReportTables = ({ data, columns }) => {
    if ((data?.items.length === 0 && data.prolonged.length === 0) || !data) return null;

    return (
        <Fragment>
            <Card className="main-card mb-3">
                <Fragment>
                    {
                        data.info && data.info.length !== 0 &&
                        <CardBody className="p-3 border-dark">
                            <WeekReportInfo data={data.info} />
                        </CardBody>
                    }

                    {
                        data.items && data.items.length !== 0 &&
                        <CardBody className="p-3 subs-main-table">
                            <WeekReportTable reportData={Object.values(data.items)} />
                        </CardBody>
                    }
                    {
                        data.prolonged && data.prolonged.length !== 0 &&
                        <CardBody className="p-3 lange-data">
                            <h6 className="mb-3 text-blue">Lange verzoeken</h6>
                            <ReactTableGeneric
                                data={data.prolonged}
                                columns={columns}
                                tableClassName="mb-0 table table-sm table-hover mt-3 prolong-tb"
                                filters={{ search: false, pagination: false, columnSorting: true }}
                            />
                        </CardBody>
                    }

                </Fragment>
            </Card>
        </Fragment>
    )
}

export default WeekReportTables;
