import { setUserProfilePicture } from "reducers/UserProfile";
import { setUserRole } from "reducers/UserPermission";
import avatar1 from "../assets/utils/images/avatars/1.jpg";
import AuthService from "Api/Auth";

const InitUserState = (configureStore) => {
    let userData = JSON.parse( localStorage.getItem('user'));
    configureStore.dispatch(setUserProfilePicture(userData == null || userData.profileImage === '' ? avatar1 : userData.profileImage ));

    let userRole = JSON.parse( localStorage.getItem('USER_ROLES'));
    configureStore.dispatch(setUserRole(userRole ?? [] ));
}

const InitReduxState = {
    InitUserState

};
export default InitReduxState;
