import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ReactComponent as Upload } from "../../../assets/utils/images/svgs/Upload.svg";
import { ReactComponent as Group } from "../../../assets/utils/images/svgs/Group.svg";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone';
import LossDocumentForm from './LossDocumentForm';
import DefaultForm from "./DefaultForm";
import GedlingForm from "./GedlingForm";
import HerinneringForm from './HerinneringForm';
import InlichtingenstaatForm from './InlichtingenstaatForm';
import Uitgifte from './UitgifteForm';

export default function DocumentPopup({type="", filterCommunity, currentUser, isOpen, togglePopup, croppedFile, classes, saveFile, doc, updateData }) {

    const [image, setImage] = useState("");
    const [pdf, setPdf] = useState("");
    const cropperRef = useRef(null);
    const inputFile = useRef(null);
    const dispatch = useDispatch();
    const userProfileImage = useSelector((state) => state.UserProfile.profileImage);
    const [file, setFile] = useState("");
    const [croppedImage, setCroppedImage] = useState("");
    const [openModel, setOpenModel] = useState(false);
    const [docTypeList, setDocTypeList] = useState({});
    const [docType, setDocType] = useState(0);
    const [docTypeName, setDocTypeName] = useState('');
    const [fileType, setFileType] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [message, setMessage] = useState({ type: null, text: "" });
    const fileSize = 2;
    const [toevoegenButtonToggle, setToevoegenButtonToggle] = useState(true);

    const baseStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        transition: 'border .3s ease-in-out'
      };
      
      const activeStyle = {
        borderColor: '#2196f3'
      };
      
      const acceptStyle = {
        borderColor: '#00e676'
      };
      
      const rejectStyle = {
        borderColor: '#ff1744'
      };

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles);
        console.log('acceptedFiles');
        dropImageOrPdf(acceptedFiles);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/jpg, image/png, application/pdf'
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    useEffect(() => {

        if (file !== '') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            if (file.type === 'application/pdf') {
                reader.onloadend = () => {
                    setPdf(reader.result);
                    saveFile(file);
                };
            } else {
                reader.onloadend = () => {
                    setImage(reader.result);
                };
            }
        }
    }, [file]);


    function toggle() {
        togglePopup(!isOpen);
        setShowForm(false);
        setCroppedImage('');
        setFileType('');
        setImage('');
        saveDocTypeValue(0, '');
        setToevoegenButtonToggle(true);
    }

    const onButtonClick = (e) => {
        inputFile.current.click();
    };

    const uploadImageOrPdf = (e) => {
        
        e.preventDefault();
        const file = e.target.files[0];

        if (file.type === 'application/pdf') {
            setShowForm(true);
        }

        if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'application/pdf') {
            setFileType(file.type);
        }

        if (!/\.(jpe?g|png|pdf)$/i.test(file.name)) {
            messageHandler("error", "Alleen jpeg/jpg, png-bestanden toegestaan");
            return;
        }
        if (file.size >= fileSize * 1024 * 1024) {
            messageHandler("error", "Maximale bestandsgrootte is 2 MB");
            return;
        }

        setOpenModel(true);

        setFile(file);
    };

    const dropImageOrPdf = (e) => {

        const file = e[0];
        
        if (file.type === 'application/pdf') {
            setShowForm(true);
        }

        if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'application/pdf') {
            setFileType(file.type);
        }
        
        if (!/\.(jpe?g|png|pdf)$/i.test(file.name)) {
            messageHandler("error", "Alleen jpeg/jpg, png-bestanden toegestaan");
            return;
        }
        if (file.size >= fileSize * 1024 * 1024) {
            messageHandler("error", "Maximale bestandsgrootte is 2 MB");
            return;
        }

        setOpenModel(true);
        setFile(file);
    };

    const messageHandler = (type, text) => {
        setMessage({ type, text });
    };

    const handleCroppedImage = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        croppedFile(cropper.getCroppedCanvas().toDataURL());
        setCroppedImage(cropper);
        setShowForm(true);
        saveFile(file);
        setToevoegenButtonToggle(false);
    }

    const saveDocTypeValue = (id, label) => {
        setDocType(id);
        setDocTypeName(label);
    }

    const updateDocumentList = () => {
        updateData();
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} className={classes} >
            <ModalHeader>Selecteer document  <Group onClick={toggle} style={{ cursor: 'pointer' }} /></ModalHeader>
            <ModalBody>
                <div>
                    {
                        image === '' && croppedImage === '' && showForm === false &&
                        <>
                            <div {...getRootProps({style})}>
                            <input {...getInputProps()}   
                                name="fileinput"
                                ref={inputFile}
                                type="file"
                                className="d-none"
                                onChange={uploadImageOrPdf}
                            />
                            <div><Upload onClick={onButtonClick} style={{ cursor: 'pointer' }} /></div>
                            </div>
                            <p className="bold-content">Sleep of klik op het icoon om bestand toe te voegen</p>
                            <p className="normal-content">Toegestane bestandsformaten: *.pdf, *.jpg, *.png, *.bmp</p></>
                    }

                    {
                        (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg') && showForm !== true &&

                        <Cropper
                            ref={cropperRef}
                            src={image}
                            style={{ height: 400, width: "100%" }}
                            aspectRatio={1 / 1.41}
                            autoCropArea={2}
                            guides={true}
                        />
                    }

                    {
                        showForm === true && docType === 0 &&
                        <DefaultForm saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} />
                    }

                    {
                        showForm === true && docType === 1 &&
                        <GedlingForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }

                    {
                        showForm === true && docType === 2 &&
                        <Uitgifte filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 3 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }

                    {
                        showForm === true && docType === 4 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }

                    {
                        showForm === true && docType === 5 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }

                    {
                        showForm === true && docType === 6 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 7 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 20 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 8 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 9 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 10 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 11 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 12 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }

                    {
                        showForm === true && docType === 13 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 14 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && docType === 15 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }

                    {
                        showForm === true && docType === 16 &&
                        <HerinneringForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                    {
                        showForm === true && (docType === 19) &&
                        <InlichtingenstaatForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }

                    {
                        showForm === true && (docType === 18) &&
                        <InlichtingenstaatForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }

                    {
                        showForm === true && docType === 17 &&
                        <LossDocumentForm filterCommunity={filterCommunity} currentUser={currentUser} type={type} saveDocTypeValue={saveDocTypeValue} docTypeName={docTypeName} doc={doc} toggle={toggle} updateData={updateDocumentList} />
                    }


                </div>
            </ModalBody>
            <ModalFooter>
                {
                    image !== '' && toevoegenButtonToggle !== false &&
                    <Button color="link" className="btn-color-style" onClick={handleCroppedImage}>
                        Toevoegen
                    </Button>
                }
            </ModalFooter>
        </Modal>
    );
}
