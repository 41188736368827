import axiosInstance from "Api";
const getDocumentList = () => {
  return axiosInstance.get("document-list");
};

const downloadDocument = (id) => {
  return axiosInstance.get("document/"+id);
};

const downloadProtocol = (id) => {
  return axiosInstance.get("protocol/"+id);
};
const DocumentService = {
  getDocumentList,
  downloadDocument,
  downloadProtocol,
};
export default DocumentService;