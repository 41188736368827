import React, {Fragment, useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Label, FormGroup } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import dayjs from 'dayjs'

// Components
import AppFooter from "Layout/AppFooter";

import AppHeader from "../../../../Layout/AppHeader"

// Components
import ThemeOptions from "../../../../Layout/ThemeOptions";
import CustomCalendar from "Components/Calendar";
// CustomCalendar parameters:
// data: array - array of preselected days
// prevState: array - preselected days to update state not only to save new days (important!)
// setState: () => {datesState} - function to save new selected days (needs!)
// weekDays: array - array of week day names
// showMonth: number - number of months
import DefaultButton from "Components/Buttons/DefaultButton";
import PageHeading from "Layout/AppMain/PageHeading";
import CalendarService from "Api/Verzoeken/Calendar";
import VerzoekenService from "Api/Verzoeken";

const Calendar = ({match}) => {
    const [datesState, setDatesState] = useState([]);
    const [newDates, setNewDates] = useState([]);
    const [gymList, setGymList] = useState([]);
    const [gymData, setGymData] = useState([]);
    const [formState, setFormState] = useState({dateRange: []});
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate(); // for handleSubmit function (to move to 'verzoeken' page)
    const params = useParams();
    const substitutionId = params.id || '';

    useEffect(() => {
        if (datesState.length === 0) {
            VerzoekenService.getRequestById(substitutionId).then((response) => {
                if (response.status == 200) {
                    const datesData = response.data.data.list.map(dateElem => {
                        const [day, month, year] = dateElem.day.split("-");
                        const dateValue = year + '/' + month + '/' + day

                        return {
                            date: dateValue,
                            dayPart: Object.keys(dateElem.data).length === 2 ? "full" : Object.values(dateElem.data)[0].vShift.toLowerCase()
                        }
                    });
                    setDatesState(datesData); // dates in state to pass them in child element (Calendar)
                    
                    const resultInfo = response.data.data;
                    setFormState({
                        organisationId: resultInfo.iCommunityId,
                        schoolId: resultInfo.iSchoolId,
                        absentId: resultInfo.iAbsentAdminId,
                        groupsId: resultInfo.groupList.map(group => group.iSubGroupId),
                        codeId: resultInfo.iCodeId,
                        type: resultInfo.eType,
                        planning: resultInfo.iPlanOption,
                        label: resultInfo.tExtra,
                    
                        iHowToInformId: resultInfo.iHowToInformId,
                        informEmailExtra1: resultInfo.vExtraEmail1,
                        informEmailExtra2: resultInfo.vExtraEmail2,
                    
                        gym: gymList,
                        substitutionNote: resultInfo.vCoOrdComments,
                        schedulerNote: resultInfo.vDirComments,
                        dateRange: [],
                    })
                }
            }).catch((error) => console.error("catch error:", error));
        }
        gymCalculation();

    }, [datesState]);

    useEffect(() => setFormState({ ...formState, gym: gymList.sort((x, y) => x - y) }), [gymList])// state for post request to update gym
   
    const weekDaysListWOWeekend = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag'];

    const gymCalculation = () => {
        const gym = []
        let dayId = [];
        let sift = [];
        datesState.map(day => {

            let weekDayNum = dayjs(day.date).day();

            if (day.dayPart ===  "ochtend") {
                dayId = [weekDayNum * 2 - 1]
                sift = [' ochtend'];
            } else if (day.dayPart ===  "middag") {
                dayId = [weekDayNum * 2]
                sift = [' middag']
            } else {
                dayId = [weekDayNum * 2 - 1, weekDayNum * 2]
                sift = [' ochtend', ' middag']
            }


            let gymObj = sift.map((dayPart, dayPartId) => {
                return {
                    id: dayId[dayPartId],
                    day: weekDaysListWOWeekend[weekDayNum-1] + dayPart,
                }
            });

            !gym.some(obj => obj.day == gymObj[0].day) && gym.push(...gymObj);
            gym.sort((x, y) => x.id - y.id);            
        });
        setGymData(gym);
    }

    const manageGymForNewDays = () => {
        var gym = [];
        let dayId = [];
        let sift = [];
        newDates.map(day => {
            let weekDayNum = dayjs(day.date).day();

            if (day.dayPart ===  "ochtend") {
                dayId = [weekDayNum * 2 - 1]
                sift = [' ochtend'];
            } else if (day.dayPart ===  "middag") {
                dayId = [weekDayNum * 2]
                sift = [' middag']
            } else {
                dayId = [weekDayNum * 2 - 1, weekDayNum * 2]
                sift = [' ochtend', ' middag']
            }
            let gymObj = sift.map((dayPart, dayPartId) => {
                return {
                    id: dayId[dayPartId],
                    day: weekDaysListWOWeekend[weekDayNum-1] + dayPart,
                }
            });
    
            !gym.some(obj => obj.day == gymObj[0].day) && gym.push(...gymObj);
            gym.sort((x, y) => x.id - y.id);
            
        });

        datesState.map(day => {

            let weekDayNum = dayjs(day.date).day();

            if (day.dayPart ===  "ochtend") {
                dayId = [weekDayNum * 2 - 1]
                sift = [' ochtend'];
            } else if (day.dayPart ===  "middag") {
                dayId = [weekDayNum * 2]
                sift = [' middag']
            } else {
                dayId = [weekDayNum * 2 - 1, weekDayNum * 2]
                sift = [' ochtend', ' middag']
            }

            let gymObj = sift.map((dayPart, dayPartId) => {
                return {
                    id: dayId[dayPartId],
                    day: weekDaysListWOWeekend[weekDayNum-1] + dayPart,
                }
            });

            !gym.some(obj => obj.day == gymObj[0].day) && gym.push(...gymObj);
            gym.sort((x, y) => x.id - y.id);            
        });
        
        setGymData(gym);
    }

    const handleSubmit = () => {
        if (formState.dateRange.length !== 0) {
            setIsDisabled(false);
            CalendarService.updateDayParts(substitutionId,formState).then((response) => {
                    if (response.data.status === "success") {
                        navigate(`/verzoeken/overzicht/${substitutionId}`);
                    }
                }).catch((err) => console.error(err));
        } else {
            setIsDisabled(true);
        }
    }

    const manageDays = (daysElemArray) =>{
        var dates = newDates;
        const daysElemArrayWOSelected = daysElemArray.filter(dayElem => !dayElem.classList.contains("selected"));
        const checked = (className) =>
            daysElemArrayWOSelected.every(dayElem => 
                dayElem.classList.contains(className)
            )
    
        daysElemArrayWOSelected.map(dayElem => {
            let date =  dayElem.attributes.date.value;
            if (checked("bg-success")) {
                dates.map((value, index) => {
                    if (value.date === date) {
                        dates.splice(index, 1);
                    }
                })
                dates.push( {date: date, dayPart: "ochtend"} );
            } else if (checked("bg-warning")) {
                dates.map((value, index) => {
                    if (value.date === date) {
                        dates.splice(index, 1);
                    }
                })
                dates.push( {date: date, dayPart: "middag"} );
            } else if (checked("bg-danger")) {
                dates.map((value, index) => {
                    if (value.date === date) {
                        dates.splice(index, 1);
                    }
                })
            } else {
                dates.map((value, index) => {
                    if (value.date === date) {
                        dates.splice(index, 1);
                    }
                })
                dates.push({date: date, dayPart: "full"});
            }
        })
        setNewDates(dates);
        manageGymForNewDays();
    }

    const manageDate = (day) => {
        let dates = newDates;
        
        dates.map((value, index) => {
            if (value.date === day.date) {
                newDates.splice(index, 1);
            }
        })

        dates.push(day);
        setNewDates(dates);
        manageGymForNewDays();
    }

    const removeDate = (day) => {
        newDates.map((value, index) => {
            if (value.date === day) {
                newDates.splice(index, 1);
            }
        })
        manageGymForNewDays();
    }


    return (
        <Fragment>
            <AppHeader />
            <ThemeOptions />
            <div>
                <TransitionGroup>
                    <CSSTransition component="div" className="TabsAnimation" appear={true}
                        timeout={1500} enter={false} exit={false}>
                        <div>
                            <div className="chat-layout-wrapper">
                                <div className="app-inner-layout chat-layout" >
                                    <div className="app-inner-layout__header text-white breadcrumb-color pb-0 pt-3">
                                        <PageHeading />
                                    </div>
                                    <div className="app-inner-layout__wrapper">
                                        <Card className="app-inner-layout__content">
                                            <Row>
                                                <Col md="12">
                                                    <Card className="main-card mb-3">
                                                        <CardBody className="p-3">
                                                            <CustomCalendar removeDate={removeDate} manageDays={manageDays} manageDate={manageDate} preselectedData={datesState} prevState={formState} setState={setFormState} weekDays={weekDaysListWOWeekend} isDisabled={isDisabled} />
                                                            <div className="calendar-fields-container row">
                                                            <FormGroup row className="custom-pl-0 custom-ml-0 mt-5 gym-input-style">
                                                                <Label className="d-block w-100 gym-text-left" sm={1}>
                                                                    Gym
                                                                </Label>
                                                                <Col sm={5}>
                                                                    <Typeahead
                                                                        id="id"
                                                                        name="gym"
                                                                        labelKey="day"
                                                                        options={gymData}
                                                                        multiple={true}
                                                                        placeholder="Kies gym dagen"
                                                                        onChange={gymNums => setGymList([...gymList, ...gymNums.map(e => e.id).filter(e => !gymList.includes(e))])}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            </div>
                                                            <div className="divider"/>
                                                            <DefaultButton onClick={handleSubmit} type="submit" className={"ms-2 float-end btn-color-style"} >
                                                                Toevoegen
                                                            </DefaultButton>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <AppFooter />
        </Fragment>
    )
};

export default Calendar;
