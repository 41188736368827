import { Modal, ModalHeader, ModalBody, Input, Table, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import axiosInstance from "Api";
import { toast } from "react-toastify";

const InformationModel = ({ isOpen, setIsInformationModalOpen, data, substitutionId, coordinatorName, setChangedStatusIds, statusCount, setStatusCount }) => {
    const [emailToSend, setEmailToSend] = useState([]);
    const [smsToSend, setSmsToSend] = useState([]);

    useEffect(() => {
        checkedEmails();
    }, [smsToSend, emailToSend]);

    const checkedEmails = () => {
        const combinedResult = [...emailToSend, ...smsToSend];

        const uniqueSet = new Set(
            combinedResult.map((item) => JSON.stringify({ id: item.id, userType: item.userType }))
        );
        const finalResult = Array.from(uniqueSet).map((item) => JSON.parse(item));

        setStatusCount(finalResult.length);
        if (finalResult.length === 0) {
            setChangedStatusIds([])
        }
    }

    const handleSmsCheckboxChange = (id, userType) => {
        const index = smsToSend.findIndex(
            (item) => (item.id === id && item.userType === userType)
        );
      
        const defaultMsg = userType === "vervanger" ? `Beste vervanger, Verzoek ${substitutionId} ${coordinatorName}` : userType === "melder" ? `Beste melder, Verzoek ${substitutionId} ${coordinatorName}` : `Beste user, Verzoek ${substitutionId} ${coordinatorName}`

        if (index === -1) {
            setSmsToSend([...smsToSend, { id, userType, message: defaultMsg }]);
        } else {
            const newCheckedIds = smsToSend.filter(item => !(item.id === id && item.userType === userType));
            setSmsToSend(newCheckedIds);
        }
    };

    const handleEmailCheckboxChange = (id, userType) => {
        const index = emailToSend.findIndex(
            (item) => item.id === id && item.userType === userType
        );
        if (index === -1) {
            setEmailToSend([...emailToSend, { id, userType }]);
        } else {
            const newCheckedIds = emailToSend.filter(item => !(item.id === id && item.userType === userType));
            setEmailToSend(newCheckedIds);
        }
    };

    const handleSmsTextChange = (e, id, userType) => {
        const value = e.target.value;
        setSmsToSend((prev) => {
            return prev.map((item) => {
                if (item.id === id && item.userType === userType) {
                    return { ...item, message: value };
                } else {
                    return item;
                }
            });
        });
    };

    const handleSubmit = () => {
        const dataToSend = { substEmail: emailToSend, substSms: smsToSend, iSubstitutionId: substitutionId};
        axiosInstance
            .post('/verzoeken/scherm/send-info', dataToSend)
            .then((response) => {
                if (response.status === 200) {
                    setIsInformationModalOpen(!isOpen)
                    toast.success("Informatie succesvol verzonden");
                    setEmailToSend([])
                    setSmsToSend([])
                    setStatusCount(0)
                }
            })
            .catch((error) => console.log("Error:", error));
    };

    const doesUserExist = (array, id, userType) => {
        return array.findIndex((item) => item.id === id && item.userType === userType) !== -1;
    };

    const getMessageByIdAndUserType = (array, id, userType) => {
        const foundItem = array.find((item) => item.id === id && item.userType === userType);
        return foundItem ? foundItem.message : "";
    };

    const getMsgCount = (id, userType) => {
        const userSms = smsToSend.filter(item => item.id === id && item.userType === userType)
        const message = userSms.length === 0 ? "" : userSms[0].message
        const smsCount = Math.ceil((message.length + 1) / 102);
        return smsCount || 1
    }

    return (
        <Modal id={"tooltip"} isOpen={isOpen} toggle={() => setIsInformationModalOpen(!isOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling info-modal-width">
            <ModalHeader>
                <div className="modal-header-styling">
                    Informeren ({statusCount})
                </div>
                <div className="cross-icon-color">
                    <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setIsInformationModalOpen(false)}> </i>
                </div>
            </ModalHeader>

            <ModalBody className="bg-white rounded">
                <Table borderless className="info-modal-table-row">
                    <thead>
                        <tr className="info-modal-table-head">
                            <th className="fifty-percent">Melder</th>
                            <th>E-mail</th>
                            <th>SMS</th>
                        </tr>
                    </thead>
                    <tbody className="border info-modal-table-row">
                        <tr >
                            <td className="fifty-percent info-data-padding">{data && data.melder.name}</td>
                            <td className="info-data-padding">
                                <Input className="info-modal-table-checkbox checked-checkbox" type="checkbox"
                                    onChange={() => { handleEmailCheckboxChange(data.melder.id, "melder") }}
                                    checked={doesUserExist(emailToSend, data.melder.id, "melder")}
                                />
                            </td>
                            <td className="info-data-padding">
                                <Input className="info-modal-table-checkbox checked-checkbox" type="checkbox"
                                    onChange={() => { handleSmsCheckboxChange(data.melder.id, "melder") }}
                                    checked={doesUserExist(smsToSend, data.melder.id, "melder")}
                                />
                            </td>
                        </tr>
                        {
                            (doesUserExist(smsToSend, data.melder.id, "melder")) &&
                            <>
                                <tr>
                                    <td>
                                        SMS verzenden
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <textarea type="text" className="info-modal-input"
                                            value={getMessageByIdAndUserType(smsToSend, data.melder.id, "melder")}
                                            onChange={(e) => handleSmsTextChange(e, data.melder.id, "melder")} />
                                    </td>
                                </tr>
                                <tr>
                                    <div className="info-modal-text">
                                        Totaal aantal karakters over {getMessageByIdAndUserType(smsToSend, data.melder.id, "melder").length}/102 - Aantal  berichten: {getMsgCount(data.melder.id, "melder") || 1}
                                    </div>
                                </tr>
                            </>
                        }
                    </tbody>
                </Table>

                <div className="table-container">
                    <Table borderless className="mb-0 info-modal-table-row ">
                        <thead>
                            <tr className="info-modal-table-head">
                                <th className="fifty-percent">Vervanger</th>
                                <th>E-mail</th>
                                <th>SMS</th>
                            </tr>
                        </thead>
                        <tbody className="border info-modal-table-row">
                            {data && data.vervanger && data.vervanger.map((element) => (
                                <React.Fragment key={element.id}>
                                    {element && (
                                        <tr className={!(doesUserExist(smsToSend, element.id, "vervanger")) && "border-bottom"}>
                                            <td className="fifty-percent info-data-padding">{element.name}</td>
                                            <td className="info-data-padding">
                                                <Input className="info-modal-table-checkbox checked-checkbox" type="checkbox"
                                                    onChange={() => handleEmailCheckboxChange(element.id, "vervanger")}
                                                    checked={doesUserExist(emailToSend, element.id, "vervanger")}
                                                />
                                            </td>
                                            <td className="info-data-padding">
                                                <Input className="info-modal-table-checkbox checked-checkbox" type="checkbox"
                                                    onChange={() => handleSmsCheckboxChange(element.id, "vervanger")}
                                                    checked={doesUserExist(smsToSend, element.id, "vervanger")}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {(doesUserExist(smsToSend, element.id, "vervanger")) && (
                                        <>
                                            <tr>
                                                <td>SMS verzenden</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <textarea type="text" className="info-modal-input"
                                                        value={getMessageByIdAndUserType(smsToSend, element.id, "vervanger")}
                                                        onChange={(e) => handleSmsTextChange(e, element.id, "vervanger")} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="info-modal-text">
                                                        Totaal aantal karakters over {(getMessageByIdAndUserType(smsToSend, element.id, "vervanger").length)}/102 - Aantal berichten: {getMsgCount(element.id, "vervanger") || 1}
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <Table borderless className="info-modal-table-row">
                    <thead>
                        <tr className="info-modal-table-head">
                            <th className="fifty-percent">User</th>
                            <th>E-mail</th>
                            <th>SMS</th>
                        </tr>
                    </thead>
                    <tbody className="border info-modal-table-row">
                        <tr >
                            <td className="fifty-percent info-data-padding">{data && data.user.name}</td>
                            <td className="info-data-padding"><Input className="info-modal-table-checkbox checked-checkbox" type="checkbox"
                                onChange={() => { handleEmailCheckboxChange(data.user.id, "user") }}
                                checked={doesUserExist(emailToSend, data.user.id, "user")}
                            /></td>
                            <td className="info-data-padding">
                                <Input className="info-modal-table-checkbox checked-checkbox" type="checkbox"
                                    onChange={() => { handleSmsCheckboxChange(data.user.id, "user") }}
                                    checked={doesUserExist(smsToSend, data.user.id, "user")}
                                />
                            </td>
                        </tr>
                        {
                            (doesUserExist(smsToSend, data.user.id, "user")) &&
                            <>
                                <tr>
                                    <td>
                                        SMS verzenden
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <textarea type="text" className="info-modal-input"
                                            value={getMessageByIdAndUserType(smsToSend, data.user.id, "user")}
                                            onChange={(e) => handleSmsTextChange(e, data.user.id, "user")} />
                                    </td>
                                </tr>
                                <tr>
                                    <div className="info-modal-text">
                                        Totaal aantal karakters over {getMessageByIdAndUserType(smsToSend, data.user.id, "user")?.length || 0}/102 - Aantal  berichten: {getMsgCount(data.user.id, "user") || 1}
                                    </div>
                                </tr>
                            </>
                        }
                    </tbody>
                </Table>

                {(smsToSend.length > 0 || emailToSend.length > 0) && (
                    <div className="request-modal-footer">
                        <Button className="d-flex align-items-center btn-color-style  opslaan-btn btn btn-outline-primary btn-sm info-btn-padding" size="sm" outline color="primary" type="submit" onClick={() => handleSubmit()}>
                            Verzenden
                        </Button>
                        <Button className="d-flex align-items-center btn-color-style  opslaan-btn btn btn-outline-primary btn-sm info-btn-padding" size="sm" outline color="primary" onClick={() => { setIsInformationModalOpen(!isOpen) }}>
                            Terug
                        </Button>
                    </div>
                )}
            </ModalBody>
        </Modal >
    );
}

export default InformationModel;