export const coordinatorPath = {
  'verzoeken': ['/verzoeken/overzicht', '/verzoeken/planbord', "/verzoeken/indienen"],
};

export const reiskostenPath = {
  'reiskosten': ['/reiskosten','/reiskosten/afstanden', '/reiskosten/berekenen'],
};

export const weekstatenPath = {
  'weekstaten': ['/weekstaten'],
};