import React, {Fragment} from "react";
import DashboardTable from "./DashboardTable.js";

// Layout
import AppHeader from "../../../../../Layout/AppHeader";


// Components
import ThemeOptions from "../../../../../Layout/ThemeOptions";
import AppFooter from "Layout/AppFooter";

const Substitutions = ({match}) => {

    return (
        <Fragment>
            <AppHeader/>
            <ThemeOptions/>
                <div>
                        <DashboardTable/>
                </div>
            <AppFooter/>
        </Fragment>
    )
};

export default Substitutions;
