import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { BsCheckCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { TiFolderOpen } from "react-icons/ti";
import Tippy from "@tippyjs/react";
import UserInfoPopup from "./UserInfoPopup";

export const iconSwitch = (param) => {
    switch (param) {
        case 'Openstaand':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><HiOutlineDotsCircleHorizontal fontSize={"18px"} /></span></Tippy>;
        case 'Aangevraagd':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><HiOutlineQuestionMarkCircle color="#ffa73f" fontSize={"18px"} /></span></Tippy>;
        case 'Ingepland':
            return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"14px"} /></span></Tippy>;
        case 'Geannuleerd':
            return <Tippy content={param} placement="bottom"><span style={{ margin: -4 }}><TiDeleteOutline color='#FB2E5F' fontSize={"20px"} /></span></Tippy>;
        case 'Historie':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><TiFolderOpen fontSize={"18px"} /></span></Tippy>;
        default:
            return '--';
    }
}

const WeekReportTable = ({ reportData }) => {

    const columns = (data) => [
        {
            title: "Geboortedatum",
            value: data?.dBirthdate !== undefined && data?.dBirthdate !== null && data?.dBirthdate.length !== 0 ? data.dBirthdate : "--"
        },
        {
            title: "Salaris Id",
            value: data?.vSalarisId !== null && data?.vSalarisId !== undefined && data?.vSalarisId.length !== 0 ? data.vSalarisId : "--"
        }
    ];

    if (!reportData) return null;
    return (
        <div className="dagdelen-tb">
            <h6 className="mb-3 text-blue">Dagdelen</h6>
            <Table responsive hover size="sm" className="mb-0">
                <thead>
                    <tr>
                        <th className="text-lef w-32 py-3 table-right-border" colSpan="2">&nbsp;</th>
                        <th colSpan="6" className="text-center py-3 table-right-border">Ochtend</th>
                        <th colSpan="6" className="text-center py-3">Middag</th>
                    </tr>
                    <tr>
                        <th className="text-left table-right-border" colSpan="2">Datum</th>
                        <th className="text-left">Vervanger</th>
                        <th>WTF</th>
                        <th>CT</th>
                        <th>DT</th>
                        <th className="text-left">Status</th>
                        <th className="text-left table-right-border">Salaris</th>
                        <th className="text-left ">Vervanger</th>
                        <th>WTF</th>
                        <th>CT</th>
                        <th>DT</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Salaris</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData.map((block, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-capitalize" width="35px">
                                    {new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}
                                </td>
                                <td className="table-right-border cursor-pointer" width="80px">{block.day}</td>
                                {
                                    block.data.Ochtend ?
                                        <>
                                            <td>
                                                <div className="overflow-ellipsis ws">
                                                    <UserInfoPopup name={block.data.Ochtend.vAbsentName} columns={columns(block.data.Ochtend)} />
                                                </div>
                                            </td>
                                            <td>{block.data.Ochtend.iClooserId > 0 ? block.data.Ochtend.vWtf + " " + block.data.Ochtend.vWtfValue : "--"} </td>
                                            <td>{block.data.Ochtend.iClooserId > 0 ? block.data.Ochtend.ct : "--"} </td>
                                            <td>{block.data.Ochtend.iClooserId > 0 ? block.data.Ochtend.dt : "--"} </td>
                                            <td className="text-center">{block.data.Ochtend.iClooserId > 0 ? iconSwitch(block.data.Ochtend.vStatus) : "--"} </td>
                                            <td className="text-center table-right-border">
                                                {
                                                    block.data.Ochtend.bBlock ? '--' :
                                                        (reportData?.disableSalary === 'N' ?
                                                            (block.data.Ochtend.bluebox_status === 'idle' ?
                                                                (
                                                                    (reportData?.chckSalaris === "Y" && block.data.Ochtend.bluebox_status === 'sent') ? <BsCheckCircle color='#39A825' fontSize={"14px"} /> : <input type="checkbox" />

                                                                ) : <BsCheckCircle color='#39A825' fontSize={"14px"} />) :

                                                            <BsCheckCircle color='#39A825' fontSize={"14px"} />
                                                        )
                                                }

                                            </td>
                                        </>
                                        :
                                        <td colSpan="6" className="text-center table-right-border">&nbsp;</td>
                                }
                                {
                                    block.data.Middag ?
                                        <>
                                            <td>
                                                <div className="overflow-ellipsis ws">
                                                    <UserInfoPopup name={block.data.Middag.vAbsentName} columns={columns(block.data.O)} />
                                                </div>
                                            </td>
                                            <td>{block.data.Middag.iClooserId > 0 ? block.data.Middag.vWtf + " " + block.data.Middag.vWtfValue : "--"} </td>
                                            <td>{block.data.Middag.iClooserId > 0 ? block.data.Middag.ct : "--"} </td>
                                            <td>{block.data.Middag.iClooserId > 0 ? block.data.Middag.dt : "--"} </td>
                                            <td className="text-center">{block.data.Middag.iClooserId > 0 ? iconSwitch(block.data.Middag.vStatus) : "--"} </td>
                                            <td className="text-center">
                                                {
                                                    block.data.Middag.bBlock ? '--' :
                                                        (reportData?.disableSalary === 'N' ?
                                                            (block.data.Middag.bluebox_status === 'idle' ?
                                                                (
                                                                    (reportData?.chckSalaris === "Y" && block.data.Middag.bluebox_status === 'sent') ? <BsCheckCircle color='#39A825' fontSize={"14px"} /> : <input type="checkbox" />

                                                                ) : <BsCheckCircle color='#39A825' fontSize={"14px"} />) :

                                                            <BsCheckCircle color='#39A825' fontSize={"14px"} />
                                                        )
                                                }
                                            </td>
                                        </>
                                        :
                                        <td colSpan="6" className="text-center">&nbsp;</td>
                                }
                            </tr>
                        )
                    })
                    }
                </tbody>
            </Table>
        </div>
    );
};

export default WeekReportTable;
