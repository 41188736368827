import React, {Fragment} from "react";

// Layout
import AppHeader from "../../../Layout/AppHeader/";
import AppSidebar from "../../../Layout/AppSidebar/";

// Components
import Default from "./Default";
import AppFooter from "Layout/AppFooter";

const Feedback = ({match}) => {
    return (
        <Fragment>
            <AppHeader/>
            <div className="app-main">
                <AppSidebar/>
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Default/>
                    </div>
                </div>
            </div>
            <AppFooter/>
        </Fragment>
    )
};

export default Feedback;