import { Card, CardBody } from "reactstrap";
import WeekTable from "./WeekTable";

const WeeklyOverview = ({ data }) => {
    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Weekoverzicht</h5>
                {
                    data?.arrWeek !== undefined && Object.keys(data.arrWeek).map((item) => {
                        return <div className='col-12 '>
                            <WeekTable list={data.arrWeek[item]} sum={data.arrWeekSum[item] !== undefined ? data.arrWeekSum[item] : null}  />
                        </div>
                    })
                }
            </CardBody>
        </Card>
    );
};

export default WeeklyOverview;