import React, {useEffect} from 'react'


import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'



import Pagination from "./pagination";
import DebouncedInput from "Components/Forms/DebouncedInput";



const filterTableGeneric = (row, columnId, values, addMeta) => {

    let searchValues = values.split(/(\s+)/).filter( e => e.trim().length > 0);

    return searchValues.every((value)=>{
        return Object.values(row.original).join(" ").toLowerCase().includes(value.toLowerCase());
    });

    // old implementation with ranking
    // TODO: implement search using words ranking
    //     const itemRank = rankItem(row.getValue(columnId), value)
    //     console.log(itemRank);

    // addMeta({
    //     itemRank,
    // })

    // return itemRank.passed;
}

const ReactTableGeneric = ({data, columns, filters, tableClassName, rowCount = 20, filterRecords}) => {
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [sorting, setSorting] = React.useState([])
    const [pageSize, setPageSize] = React.useState(rowCount);

    const tableClass =  tableClassName || "mb-0 table table-bordered table-sm table-striped table-hover";
    const {search = true, columnFilter=false, pagination = true, columnSorting=true } = filters || {};
    const fuzzyFilter = filterRecords ?? filterTableGeneric;

    const pointerStyles = {
        cursor: 'pointer',
        userSelect: 'none',
    }

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel:  getFilteredRowModel(),
        getPaginationRowModel:  getPaginationRowModel(),
        getSortedRowModel:  getSortedRowModel(),
    })

    useEffect(() => {
        table.setPageSize(pageSize)
    }, [pageSize]);

    return (
        <div>
                { search ?  <div className="form-group pull-right">
                        <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                        className="form-control mb-2 search"
                        placeholder="Zoeken..." />
                    </div> : null }


            <table className={tableClass}>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : (
                                        <div
                                            {...{
                                                style: header.column.getCanSort()
                                                        ? pointerStyles
                                                        : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {
                                                {
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getIsSorted()] ?? null}
                                        </div>
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {
                    table.getRowModel().rows.length ?
                    table.getRowModel().rows.map(row => (
                        <tr key={row.id} >
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext())
                                    }
                                </td>
                            ))}
                        </tr>
                    ))
                    : <tr><td colSpan={9} align='center'>Geen data beschikbaar</td></tr>
                }
                </tbody>

            </table>
            { pagination ? <Pagination table={table} /> : null }
        </div>
    )
}

export default ReactTableGeneric;
