import Tippy from "@tippyjs/react";
import { ReactComponent as InfoIcon } from "../../../../assets/utils/images/svgs/InformationBlue.svg";
import { ReactComponent as LockIcon } from "../../../../assets/utils/images/svgs/Locked.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/utils/images/svgs/Cross.svg";
import { ReactComponent as DateIcon } from "../../../../assets/utils/images/svgs/calendar-blue.svg";
import { BsCheckCircle } from "react-icons/bs";

export const cellIcons = (type, value = '') => {
    switch (type) {
        case "INFO":
            return <Tippy content={'Recenter document aanwezig'} placement="bottom">
                <span className="me-2">
                    <InfoIcon fontSize={"18px"} /></span>
            </Tippy>;
        case "LOCK":
            return <Tippy content={'Document is vergrendeld'} placement="bottom">
                <span className="me-2">
                    <LockIcon fontSize={"18px"} />
                </span>
            </Tippy>;
        case "CROSS":
            return <Tippy content={'Document niet aanwezig'} placement="bottom">
                <span className="me-2">
                    <CrossIcon fontSize={"18px"} />
                </span>
            </Tippy>;
        case "DATE":
            return <Tippy content={`Document aangevraagd op ${value}`} placement="bottom">
                <span className="me-2">
                    <DateIcon fontSize={"18px"} />
                </span>
            </Tippy>;
        default:
            return <></>;
    }
}

export const RenderCommunityRow = ({ community, filterData }) => {

    return <>
        {filterData?.community !== "" && <td className='border-start text-center'>{community.vCommunityName || '-'}</td>}
        <td className='text-center'>{community.isCoordinator ? <BsCheckCircle color='#3AC47D' fontSize={"18px"} /> : '-'}</td>
        <td className='text-center'>{community.isRequestor ? <BsCheckCircle color='#3AC47D' fontSize={"18px"} /> : '-'}</td>
        <td className='text-center'>{community.isSubstitutor ? <BsCheckCircle color='#3AC47D' fontSize={"18px"} /> : '-'}</td>
        <td className='text-center'>{community.isSubstitutor === 0 ? '' : community.vSalarisId || '-'}</td>
        <td className='text-center'>{community.isSubstitutor === 0 ? '' : community.wtf || '-'}</td>
        <td className='text-center'>{community.isSubstitutor === 0 ? '' : community.contractAbbr || '-'}</td>
        <td className='text-center'>

            {community.isSubstitutor === 0 && ''}
            {community.isSubstitutor === 1 && <>
                {
                    community.doc_type?.VOG?.is_locked ? cellIcons("LOCK") :
                        community.doc_type?.VOG?.value ? community.doc_type?.VOG?.value :
                            cellIcons("CROSS")
                }
                {
                    !community.doc_type?.VOG?.is_locked &&
                    <>
                        {community.doc_type?.VOG?.recent_doc &&
                            cellIcons("INFO")
                        }
                        {community.doc_type?.VOG?.is_requested !== null &&
                            cellIcons("DATE", community.doc_type?.VOG?.is_requested)
                        }
                    </>
                }
                {community.doc_type?.VOG?.info &&
                    cellIcons("INFO")
                }
            </>}
        </td>
        <td className='text-center '>
            {community.isSubstitutor === 0 && ''}
            {community.isSubstitutor === 1 && <>
                {
                    community.doc_type?.ID?.is_locked ?
                        cellIcons("LOCK") : community.doc_type?.ID?.value ?
                            community.doc_type?.ID?.value :
                            cellIcons("CROSS")
                }
                {
                    !community.doc_type?.ID?.is_locked &&
                    <>
                        {community.doc_type?.ID?.recent_doc &&
                            cellIcons("INFO")
                        }
                        {community.doc_type?.ID?.is_requested !== null &&
                            cellIcons("DATE", community.doc_type?.ID?.is_requested)}
                    </>
                }
                {community.doc_type?.ID?.info &&
                    cellIcons("INFO")}
            </>}

        </td>

        <td className='text-center'>
            {community.isSubstitutor === 0 && ''}
            {community.isSubstitutor === 1 && <>
                {(community.KR?.value !== null && community.KR?.value !== undefined && community.KR?.value !== '')
                    ? community.KR.value
                    : cellIcons("LOCK")}
            </>}
        </td>
    </>
}