import React, {Fragment} from "react";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import DefaultButton from "Components/Buttons/DefaultButton";
import { useState } from "react";
import classNames from "classnames";


const getNavStates = (indx, length) => {
    let styles = [];
    for (let i = 0; i < length; i++) {
        if (i < indx) {
            styles.push("done");
        } else if (i === indx) {
            styles.push("doing");
        } else {
            styles.push("todo");
        }
    }
    return {current: indx, styles: styles};
};


const MultiStep = (props) => {
    const [showPreviousBtn, setShowPreviousBtn] = useState(false);
    const [showNextBtn, setShowNextBtn] = useState(true);
    const [compState, setCompState] = useState(0);
    const [navState,  setNavState] = useState(getNavStates(0, props.steps.length));

    const checkNavState = (currentStep, stepsLength) => {
        if (currentStep > 0 && currentStep < stepsLength - 1) {
            setShowPreviousBtn(true);
            setShowNextBtn(true)
        } else if (currentStep === 0) {
            setShowPreviousBtn(false);
            setShowNextBtn(true)
        } else {
            setShowPreviousBtn(true);
            setShowNextBtn(false)
        }
    };

    const setNavStates = (next) => {
        setNavState(getNavStates(next, props.steps.length));

        if (next < props.steps.length) {
            setCompState(next);
        }
        checkNavState(next, props.steps.length);
    };

    const handleOnClick = (evt) => {
        if (
            evt.currentTarget.value === props.steps.length - 1 &&
            compState === props.steps.length - 1
        ) {
            setNavStates(props.steps.length);
        } else {
            setNavStates(evt.currentTarget.value);
        }
    };

    const next = e => {
        // validate if all necessary fields are selected
        if (
            Object.keys(props.formikProps.errors).length > 1
            || props.leaveLink !== null
            && props.leaveLink.length !== 0
            && props.formikProps.values.type.length == 0
        ) {
            props.setDisabled(true);
        } else {
            props.setDisabled(false);
            setNavStates(compState + 1);
        }
    };

    const previous = () => {
        if (compState > 0) {
            setNavStates(compState - 1);
        }
    };

    const getClassName = (className, i) => {
        return className + "-" + navState.styles[i];
    };

    const renderSteps = () => {
        return props.steps.map((s, i) => (
            <li
                className={getClassName("form-wizard-step", i)}
                onClick={e => {
                     // validate if all necessary fields are selected
                    if (
                        Object.keys(props.formikProps.errors).length > 1
                        || props.leaveLink.length !== 0
                        && props.formikProps.values.type.length == 0
                    ) {
                        props.setDisabled(true);
                    } else {
                        props.setDisabled(false);
                        handleOnClick(e);
                    }
                }}
                key={i}
                value={i}
            >
                <em>{i + 1}</em>
                <span>{props.steps[i].name}</span>
            </li>
        ));
    };


    return (
        <Fragment>
            <PageHeading/>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                               timeout={1500} enter={false} exit={false}>
                    <Row>
                        <Col md="12">
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    <div>
                                        <ol className="forms-wizard">{renderSteps()}</ol>
                                        <Container className="px-0">
                                            {props.steps[compState].component}
                                            <div className="divider"/>
                                            <div className="clearfix">
                                                <div className={classNames({"row": showNextBtn})} style={props.showNavigation ? {} : {display: "none"}}>

                                                    {!showNextBtn &&
                                                        <DefaultButton onClick={props.handleSubmit} type="button" className={"float-end ms-2 btn-color-style"} disabled={props.disabledButton}>
                                                            Toevoegen
                                                        </DefaultButton>
                                                    }

                                                    {showNextBtn && <div className="col row px-4"><span className="d-inline-block col-3"></span><span className="col px-4">* Verplichte velden</span></div>}

                                                    {showNextBtn &&
                                                    <div className="col">
                                                        <DefaultButton onClick={next} className={"float-end btn-color-style"}>
                                                            Volgende
                                                        </DefaultButton>
                                                    </div>
                                                    }

                                                    {showPreviousBtn &&
                                                    <DefaultButton onClick={previous} className={"float-end btn-color-style"}>
                                                        Vorige
                                                    </DefaultButton>
                                                    }

                                                </div>
                                            </div>
                                        </Container>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    );
}

export default MultiStep;

MultiStep.defaultProps = {
    showNavigation: true,
};
