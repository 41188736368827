import React from 'react'
import {
  CardFooter,
  Container
} from "reactstrap";

export default function AppInnerFooter({children, footerWrapperClassName = "", cardFooterClassName = ""}) {
  return (
    <div className={`app-iner-footer ${footerWrapperClassName}`}>
      <CardFooter className={`app-iner-footer__card text-center d-flex ${cardFooterClassName}`}>
          {children}
      </CardFooter>
    </div>
  )
}
