import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, CardFooter, Col, DropdownMenu, DropdownToggle, Input, Label, Nav, Row, UncontrolledButtonDropdown } from "reactstrap";
import Records from "./table";
import { ReactComponent as InformationBlue } from "../../../../assets/utils/images/svgs/InformationBlue.svg";
import AppFooter from "Layout/AppFooter";
import WeekstatenService from "Api/Weekstaten";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import Process from "../Process";
import { useNavigate } from "react-router";

const Default = () => {
    const [data, setData] = useState('');
    const [statusIds, setStatusIds] = useState('t:1;');
    const [schoolId, setSchoolId] = useState(-1);
    const [communityId, setCommunityId] = useState(-1);
    const [menuShow, setMenuShow] = useState(false);

    const [showLoader, setShowLoader] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [serachValue, setSerachValue] = useState('');
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [pageSizeCount, setPageSizeCount] = useState(100);
    const pdfDownloading = JSON.parse(sessionStorage.getItem("pdfDownloading")) || false;
    const [alertMsg, setAlertMsg] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [processData, setProcessData] = useState({
        iCommunityId: [],
        iSchoolIds: [],
        iWeekstaatIds: []
    });
    const [processResponse, setProcessResponse] = useState();
    const [overviewPage, setOverviewPage] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (pdfDownloading) {
            toast.success("Het genereren van uw PDF duurt ongeveer 5 minuten.");
            sessionStorage.setItem("pdfDownloading", false);
        }
    }, []);

    useEffect(() => {
        getWeekstatenData()
    }, [schoolId, communityId, statusIds, pageSizeCount, pageNumber,overviewPage,serachValue])

    const searchHandler = (val) => {
        const delay = 1000; 
        const timeoutId = setTimeout(() => {
            setPageNumber(1);
            setSerachValue(val);
        }, delay);
        
        return () => {
            clearTimeout(timeoutId);
        };
    }

    const getWeekstatenData = () => {
        setShowLoader(true)
        var dataToSend = statusIds + 'o:' + communityId + ';s:' + schoolId + ';&pageSize=' + pageSizeCount + '&pageNumber=' + pageNumber+'&search='+(serachValue|| '');
        WeekstatenService.getWeekstatenOverview(dataToSend).then((response) => {
            if (response.data.status === true) {
                setData(response.data.data)
                setTotalPageCount(response.data.data.pageCount);
                setShowLoader(false);
            }
        }).catch(() => console.error);
    }

    const getDataToSend = (event, id) => {
        setPageNumber(1);
        setStatusIds(prevText => {
            const newStatusIds = prevText.split(';').filter(item => item);
            if (event.target.checked) {
                if (!newStatusIds.includes(`t:${id}`)) {
                    newStatusIds.push(`t:${id}`);
                }
            } else {
                const index = newStatusIds.indexOf(`t:${id}`);
                if (index > -1) {
                    newStatusIds.splice(index, 1);
                }
            }
            return newStatusIds.join(';') + (newStatusIds.length > 0 ? ';' : '');
        });
    };

    const menuIsOpen = () => {
        if (menuShow === false) {
            setMenuShow(true);
        } else {
            setMenuShow(false);
        }
    }

    const handleNextProcess = () => {
        if (processData.iCommunityId.length === 0) {
            setShowAlert(true)
            setAlertMsg('Selecteer minimaal één weekafschrift.')
            return;
        }
        if (!processData.iCommunityId.every(value => value === processData.iCommunityId[0])) {
            setShowAlert(true)
            setAlertMsg('Selecteer slechts één community tegelijk.')
            return;
        }
        const reqData = {
            ...processData, iCommunityId: processData.iCommunityId[0]
        }
        WeekstatenService.getSchoolLists(reqData).then((response) => {
            if (response.data.status === true) {
                setOverviewPage(false);
                setProcessResponse(response.data.data)
            }
        }).catch(() => console.error);
    }

    const updateProcessData = (rowData, type) => {
        let id = [];
        if (type === 'ADD_ALL') {
            id = rowData.map(item => {
                return getCommunityId(item.vCommunityName);
            })
        } else if (type === 'REMOVE_ALL') {
            id = []
        }
        else {
            id = [getCommunityId(rowData.vCommunityName)]
        }

        if (type === 'ADD') {
            setProcessData((prev) => {
                return {
                    iCommunityId: [...prev.iCommunityId, id[0]],
                    iSchoolIds: [...prev.iSchoolIds, rowData.iSchoolId],
                    iWeekstaatIds: [...prev.iWeekstaatIds, rowData.id]
                }
            })

        } else if (type === 'REMOVE') {
            setProcessData((prev) => {
                const idToRemove = rowData.iSchoolId;
                const indexToRemove = prev.iSchoolIds.indexOf(idToRemove);
                const commId = id[0];
                const indexCommId = prev.iCommunityId.indexOf(commId);
                return {
                    iCommunityId: indexToRemove !== -1 && [
                        ...prev.iCommunityId.slice(0, indexCommId),
                        ...prev.iCommunityId.slice(indexCommId + 1)
                    ],
                    iSchoolIds: indexToRemove !== -1 && [
                        ...prev.iSchoolIds.slice(0, indexToRemove),
                        ...prev.iSchoolIds.slice(indexToRemove + 1)
                    ],
                    iWeekstaatIds: prev.iWeekstaatIds.filter(item => item !== rowData.id),
                }
            });
        } else if (type === 'ADD_ALL') {
            setProcessData({
                iCommunityId: id,
                iSchoolIds: rowData.map(item => item.iSchoolId),
                iWeekstaatIds: rowData.map(item => item.id),
            })
        }
        else if (type === 'REMOVE_ALL') {
            setProcessData({
                iCommunityId: id,
                iSchoolIds: [],
                iWeekstaatIds: [],
            })
        }else if(type === 'ID_CLICK'){
            navigate(`/weekstaten/${rowData.id}`, {
                state: { 
                    data: {
                        wid: rowData.wid,
                        iSchoolId: rowData.iSchoolId,
                        iWeekstaatId: rowData.id
                    }
                }
            });
        }
        else return;
    }

    function getCommunityId(communityName) {
        return data.communityList.filter(item => item.name === communityName)[0].iCommunityId
    }

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Fragment>
                        {overviewPage && <div className="chat-layout-wrapper">
                            <div className="app-inner-layout chat-layout" >
                                <Row className="app-inner-layout__wrapper">
                                    <Col md="12" lg="12">
                                        <Records data={data} showLoader={showLoader} setShowLoader={setShowLoader} pageNumber={pageNumber} setPageNumber={setPageNumber}
                                            totalPageCount={totalPageCount} pageSizeCount={pageSizeCount} 
                                            setPageSizeCount={setPageSizeCount} processData={processData} 
                                            searchHandler={searchHandler}
                                            processDataHandler={updateProcessData}/>
                                    </Col>
                                </Row>
                            </div>

                            <div className="chat-layout-footer mb-2">
                                <CardFooter className="d-block text-center d-flex chat-layout-footer__inner footer_inner_margin">
                                    <div className="flex-grow-1 d-flex w-100 justify-content-between">
                                        <Row>
                                            <Col md="8">
                                                <div className="checbox-border">
                                                    <Row style={{ marginTop: '-4px' }}>
                                                        <Col md="3">
                                                            <Row className="custom-checkbox">
                                                                <Label className="col-md-12">Openstaand({data.openStaandCount ? data.openStaandCount : 0})</Label>
                                                                <Input type="checkbox" className="col-md-12" onChange={(event) => { getDataToSend(event, 1) }} defaultChecked />
                                                            </Row>
                                                        </Col>
                                                        <Col md="3">
                                                            <Row className="custom-checkbox">
                                                                <Label className="col-md-12">Goedgekeurd</Label>
                                                                <Input type="checkbox" className="col-md-12" onChange={(event) => { getDataToSend(event, 2) }} />
                                                            </Row>
                                                        </Col>
                                                        <Col md="3">
                                                            <Row className="custom-checkbox">
                                                                <Label className="col-md-12">Afgekeurd</Label>
                                                                <Input type="checkbox" className="col-md-12" onChange={(event) => { getDataToSend(event, 3) }} />
                                                            </Row>
                                                        </Col>
                                                        <Col md="3">
                                                            <Row className="custom-checkbox">
                                                                <Label className="col-md-12">Historie</Label>
                                                                <Input type="checkbox" className="col-md-12" onChange={(event) => { getDataToSend(event, 4) }} />
                                                            </Row>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div>
                                                    <Row className={"typeHead-CustomStyle"} style={{ marginTop: '-8px', marginLeft: '5px' }}>
                                                        <Col md="6">
                                                            <Row className="form-checkbox-styling">
                                                                <div className="filterBy col-md-6 p-0">
                                                                    <label>Organisatie</label>
                                                                </div>
                                                                <div className="col-md-10 selectBy p-0">
                                                                    <select className="form-select" style={{ height: '28px', width: '100px' }}
                                                                        onChange={(event) => {
                                                                            setSchoolId(-1); setCommunityId(event.target.value); setPageNumber(1);
                                                                            setData(prevData => ({
                                                                                ...prevData,
                                                                                schoolList: []
                                                                            }));
                                                                        }}
                                                                    >
                                                                        <option value='-1'>Alle</option>
                                                                        {data && data.communityList.map((key) => (
                                                                            <option value={key.iCommunityId}>{key.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </Col>
                                                        <Col md="4">
                                                            <Row className="form-checkbox-styling">
                                                                <div className="filterBy col-md-6 p-0">
                                                                    <label>School</label>
                                                                </div>
                                                                <div className="col-md-10 selectBy p-0">
                                                                    <select className="form-select" style={{ height: '28px', width: '100px' }} value={schoolId}
                                                                        onChange={(event) => { setSchoolId(event.target.value); setPageNumber(1); }}
                                                                    >
                                                                        <option value='-1'>Alle</option>
                                                                        {data && data.schoolList.map((key) => (
                                                                            <option value={key.iSchoolId}>{key.vSchoolName}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="me-2">
                                            <Col md="12" className="d-flex">
                                                <UncontrolledButtonDropdown isOpen={menuShow}>
                                                    <DropdownToggle color="link" className="p-0">
                                                        <div className="d-flex align-items-center me-2" onClick={() => menuIsOpen()}>
                                                            <InformationBlue />
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className={"rm-pointers p-0 weekstaten-popup-border"}>
                                                        <Nav className="p-2 weekstaten-popup-width">
                                                            <div className="btn-color-style travelCost-blue-text mb-2 ps-2">
                                                                Melding
                                                            </div>
                                                            <div className="travelCost-light-text ps-2">
                                                                Weekstaten moeten per organisatie verwerkt worden. Selecteer scholen van één organisatie.
                                                            </div>
                                                        </Nav>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <Button className="d-flex align-items-center btn-color-style  opslaan-btn mt-1 " size="sm" outline color="primary" onClick={handleNextProcess}>
                                                    Verwerken
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardFooter>
                            </div>
                        </div>}

                        {!overviewPage && <Process processData={processData} data={processResponse} setOverviewPage={setOverviewPage} setProcessData={setProcessData} />}

                        <SweetAlert title={alertMsg}
                            show={showAlert}
                            type="warning"
                            onConfirm={() => setShowAlert(false)}
                            confirmBtnText="Ok"
                        />
                    </Fragment>
                </CSSTransition>
            </TransitionGroup>
            <AppFooter />
        </Fragment>
    )
};

export default Default;