import axiosInstance from 'Api';

const getWeekstatenOverview = (data) => {
    return axiosInstance.get('weekstaten/overzicht?filter=' + data);
};

const getSchoolLists = (data)=>{
    return axiosInstance.post('weekstaten/verwerken/get-school-lists', data)
}

const getWeeklyLists = (data)=>{
    return axiosInstance.post('weekstaten/verwerken/get-weekly-lists', data)
}

const getWeekstaatData = (data)=>{
    return axiosInstance.post('weekstaten/verwerken/get-weekly-list', data)
}

const weekstatenUpdateStatus= (data)=>{
    return axiosInstance.post('weekstaten/verwerken/status-update', data)
}

const WeekstatenService = {
    getWeekstatenOverview,
    getSchoolLists,
    getWeeklyLists,
    getWeekstaatData,
    weekstatenUpdateStatus
};
export default WeekstatenService;