const limitStringSmallScreen = (value) => {
    if (value !== undefined && value !== '' && value !== null) {
        return value.substring(0, 12);
    }
}

const limitStringLargeScreen = (value) => {
    if (value !== undefined && value !== '' && value !== null) {
        return value.substring(0, 25);
    }
}

const limitFunctiesSmallScreen = (value) => {
    if (value !== null) {
        return value.substring(0, 25);
    }
}

const limitFunctiesLargeScreen = (value) => {
    if (value !== null) {
        return value.substring(0, 35);
    }
    
}

const codeLimitOptionOne = (value) => {
    if (value !== '' && value !== null) {
        return value.substring(0, 5);
    }
}

const codeLimitOptionTwo = (value, value1) => {
    if (value !== '' && value1 !== '') {
        return value.substring(0, 5) + ' ' + value1.substring(0, 30);
    }
}

const AfwezigeLimitOptionOne = (firstName, middleName, lastName) => {
    let name = (lastName !== null ? lastName : '') + ' ' + (firstName !== null ? firstName : '') + ' ' + (middleName !== null ? middleName : '');
    return name.substring(0, 30);
}

const AfwezigeLimitOptionTwo = (firstName, middleName, lastName) => {
    let name = (lastName !== null ? lastName : '') + ' ' + (firstName !== null ? firstName : '') + ' ' + (middleName !== null ? middleName : '');
    return name.substring(0, 20);
}

const TextLimit = {
    codeLimitOptionOne,
    codeLimitOptionTwo,
    AfwezigeLimitOptionOne,
    AfwezigeLimitOptionTwo,
    limitStringSmallScreen,
    limitStringLargeScreen,
    limitFunctiesSmallScreen,
    limitFunctiesLargeScreen,
};
export default TextLimit;
