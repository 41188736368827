import dayjs from 'dayjs'
import moment from "moment";

export function getMonth(month = dayjs().month(), year) {
    month  = Math.floor(month);

    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
    let currentMonthCount = 0 - firstDayOfTheMonth;

    const daysMatrix = new Array(6).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            currentMonthCount++;
            return dayjs(new Date(year, month, currentMonthCount));
        })
    })
    daysMatrix.map(row => row.unshift(moment(new Date(row[0])).week()-1));
    return daysMatrix;
}

// return array with months without weekend
export function withoutWeekend(listOfMonth) {
    let clone = JSON.parse(JSON.stringify(listOfMonth));
    clone.map(month => month.map(row => {row.pop(); row.splice(1, 1)}));
    return clone;
}

export const addDateToState = (date, dispatch) => {
    const dateData = {
        date,
        dayPart: "full",
    }
    dispatch({type: 'ADD_DATE', payload: dateData})
}

export const toggleDays = (daysElemArray, dispatch) => {
    const notPreselectedDaysElemArray = daysElemArray.filter(dayElem => !dayElem.classList.contains("selected"));
    const preselectedDaysElemArray = daysElemArray.filter(dayElem => dayElem.classList.contains("selected")); // array of preselected days
    const checked = (daysArray, className) =>
        daysArray.every(dayElem => 
            dayElem.classList.contains(className)
        )

        notPreselectedDaysElemArray.forEach(dayElem => {
        let date = dayElem.attributes.date.value;
        if (checked(notPreselectedDaysElemArray, "bg-success")) {
            dispatch({ type: 'UPDATE_DAYPART', payload: { date: date, dayPart: "ochtend" } })
        } else if (checked(notPreselectedDaysElemArray, "bg-warning")) {
            dispatch({ type: 'UPDATE_DAYPART', payload: { date: date, dayPart: "middag" } })
        } else if (checked(notPreselectedDaysElemArray, "bg-danger")) {
            dispatch({ type: 'REMOVE_DATE', payload: date });
        } else {
            dispatch({ type: 'REMOVE_DATE', payload: date });
            addDateToState(date, dispatch)
        }
    })

    preselectedDaysElemArray.forEach(dayElem => {
        let date = dayElem.attributes.date.value;
        dispatch({ type: 'REMOVE_DATE', payload: date });
        if (!checked(preselectedDaysElemArray, "bg-success")) {
            addDateToState(date, dispatch)
        }
    })
}

export const getDaysInRange = (range, selectedRangeElem) => 
[...document.querySelectorAll('[date]')].filter(dayElem => {
    let dayElemDate = dayElem.attributes.date.value;

    if (
        !dayElem.classList.contains('state-disabled') //not week number, weekend, day from another month(prev/next)
        && selectedRangeElem.attributes.monthid.value == dayElem.attributes.monthid.value // day in selected range element (day of week/week day of the month/month)
        && !dayElem.classList.contains("selected-full") // reject to change preselected days with "full" day part
    ) {

        if (dayElemDate.length === 10) { //day, not a week number

            if (
                range == "filteredByMonth"
                // && selectedRangeElem.innerHTML.includes(moment(new Date(dayElemDate)).format('MMMM'))
            ) {
                return dayElem
            } else if (
                range == "filteredByWeekDay"
                && dayjs(dayElemDate).day() == selectedRangeElem.attributes.weekday.value
            ) {
                return dayElem
            }
        }

        if (
            range == "filteredByWeek"
            && moment(new Date(dayElemDate)).week()-1 == selectedRangeElem.attributes.date.value 
        ){
            return dayElem
        }

    }
});

export const addClassByDayPart = (dayPart, day, month, state, preSelectedDates) => {
    if (!(day.length !== 0 && dayjs(day).format('M') !== dayjs(month[2][2]).format('M'))) {
        if (preSelectedDates) { // logic for dates when there are preselected dates
            if (
                // check for all dates exept preselected
                !preSelectedDates.map(day => day.dayPart !== "full" && day.date).includes(dayjs(day).format("YYYY/MM/DD"))
                && state.map(e => e.dayPart == dayPart && e.date).includes(dayjs(day).format('YYYY/MM/DD'))
                || // check for not-full day part (preselected)
                dayPart !== "full"
                && preSelectedDates.map(day => day.dayPart == dayPart && day.date).includes(dayjs(day).format("YYYY/MM/DD"))
                && !state.map(e => e.date).includes(dayjs(day).format('YYYY/MM/DD'))
                || // check for full day part (preselected)
                dayPart === "full"
                && preSelectedDates.map(day => day.dayPart !== "full" && day.date).includes(dayjs(day).format("YYYY/MM/DD"))
                && state.map(e => e.date).includes(dayjs(day).format('YYYY/MM/DD'))
            ) {
                return true
            }
        } else if (
            //check for dates when there aren't preselected
            state.map(e => e.dayPart == dayPart && e.date).includes(dayjs(day).format('YYYY/MM/DD'))
        ) {
            return true
        }
    }
}
