import React, {useEffect} from "react";
import {Alert} from "reactstrap";

const FormStatus = ({message, status, changeMessage}) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            changeMessage({type: null, text: ''});
        }, 3000);
        return () => {
            clearTimeout(timer);
        }
    }, [message])

    if (!message.type) return null;

    return (
        <Alert color={status}>
            {message.text}
        </Alert>
    )
}
export default FormStatus;
