import "tippy.js/dist/tippy.css";
import React from "react"; // optional
import { Link } from "react-router-dom";
import TextLimit from "Api/TextLimit";
import { Colors } from "enums/Colors";
import { PartsOfDay } from "enums/PartsOfDay";

function AbsentCell({ value }) {
  let text = " ";
  var id = 0;
  var substitutionId = 0;
  var status = "";

  if (value && value.length !== 0) {
    if (value.length > 1) {
      text = "Multiple";
    }

    if (value.length === 1) {
      text =
        value[0].vFirstName +
        " " +
        (value[0].vLastName ? value[0].vLastName.charAt(0) : "");
      id = value[0].iSubstitutorAdminId;
      substitutionId = value[0].iSubstitutionId;
      status = TextLimit.codeLimitOptionOne(value[0].vShortName)  + " - " + TextLimit.codeLimitOptionOne(value[0].vCode);
    }
    if (value.length > 1) {
      status = "Meerdere";
    }
  }
  return (
    <>
      {substitutionId > 0 ? (
        <Link to={`/verzoeken/${substitutionId}`}>{status}</Link>
      ) : (
        <span className="table-coordinator-planboard__conflict-warning">
          {status}
        </span>
      )}
    </>
  );
}

export function convertHeaders(tableHeaders) {
  let formattedHaeders = tableHeaders.map((n, index) => {
    return {
      Header: () => n.date,
      id: index + 1,
      columns: [
        {
          Header: "Ochtend",
          id: n.day.toLowerCase() + "_ochtend",
          accessor: n.day.toLowerCase() + "_ochtend",
          Cell: AbsentCell,
        },
        {
          Header: "Middag",
          id: n.day.toLowerCase() + "_middag",
          accessor: n.day.toLowerCase() + "_middag",
          Cell: AbsentCell,
        },
      ],
    };
  });
  formattedHaeders.unshift({
    Header: " ",
    id: 0,
    columns: [
      {
        Header: "Afwezige",
        accessor: (value) =>
          window.innerWidth > 1250
            ? TextLimit.AfwezigeLimitOptionOne(
                value.vFirstName,
                value.vMiddleName,
                value.vLastName
              )
            : TextLimit.AfwezigeLimitOptionTwo(
                value.vFirstName,
                value.vMiddleName,
                value.vLastName
              ),
      },
      {
        Header: "CT",
        accessor: (value) =>
          window.innerWidth > 1500
            ? TextLimit.limitStringLargeScreen(value.contractTypeAbbr)
            : TextLimit.limitStringSmallScreen(value.contractTypeAbbr),
      },
    ],
  });

  return formattedHaeders;
}

export const isWeekday = (date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

export function assignColorsToSubstitutions(substitutor) {
  const colorMap = new Map();
  const colorValues = Object.values(Colors);

  Object.values(PartsOfDay).forEach((partOfDay) => {
    if (substitutor[partOfDay] && substitutor[partOfDay].length === 1) {
      substitutor[partOfDay].forEach((substitution) => {
        if (!colorMap.has(substitution.iSubstitutionId)) {
          const color = colorValues[colorMap.size % colorValues.length];
          colorMap.set(substitution.iSubstitutionId, color);
        }
        substitution.vColor = colorMap.get(substitution.iSubstitutionId);
      });
    }
  });
}
