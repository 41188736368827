import { ReactComponent as CheckGray } from "../../../../assets/utils/images/svgs/Ok-gray.svg";
import { ReactComponent as HelpGray } from "../../../../assets/utils/images/svgs/Help-gray.svg";
import { ReactComponent as CrossGray } from "../../../../assets/utils/images/svgs/Cross-gray.svg";
import { BsCheckCircle } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../../assets/utils/images/svgs/Cross.svg";

export const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

export const GENERATE_PASSWORD = "GENERATE_PASSWORD";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

export const roleColors = ["#FF0000", "#3AC47D", "#AD00FF"];

export const userRoles = {
    Coordinator: "coordinator",
    Melder: "melder",
    Vervanger: "vervanger"
}

export const userTabs = {
    Algemeen: "algemeen",
    Instellingen: "instellingen",
    Documenten: "documenten",
    Functies: "functies",
    Beschikbaarheid: "beschikbaarheid",
    Matchcodes: "matchcodes",
    Urenoverzicht: "urenoverzicht"
}

export const iconSwitch = (param, type = "", isLocked = false) => {
    switch (param) {
        case '+':
            if (type === "bestuur") {
                if (isLocked) return <CheckGray />
                else return <BsCheckCircle color='#39A825' font-size={"18px"} />
            } else return <CheckGray />
        case '?':
            if (type === "bestuur") {
                if (isLocked) return <HelpGray />
                else return <BsQuestionCircle color='#F2983E' font-size={"18px"} />
            } else return <HelpGray />
        case '-':
            if (type === "bestuur") {
                if (isLocked) return <CrossGray />
                else return  <CrossIcon
                fontSize={"18px"}
            />
            } else return <CrossGray />
        default:
            return '--';
    }
}

export const downloadBase64File = (file, fileName) => {
       const [prefix, data] = file.split(",");
       const mimeType = prefix.match(/:(.*?);/)[1]; 
   
       const binaryData = atob(data);
       const byteNumbers = new Array(binaryData.length);
       for (let i = 0; i < binaryData.length; i++) {
           byteNumbers[i] = binaryData.charCodeAt(i);
       }
       const byteArray = new Uint8Array(byteNumbers);
   
       const blob = new Blob([byteArray], { type: mimeType });
       const blobUrl = URL.createObjectURL(blob);
   
       const link = document.createElement("a");
       link.href = blobUrl;
       link.download = fileName || "downloaded_file";
       document.body.appendChild(link);
   
       link.click();
       document.body.removeChild(link);
       URL.revokeObjectURL(blobUrl);
}