
import React, { useState } from "react";
import { Col, Row, Card, CardBody, CardFooter, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import { registerLocale } from "react-datepicker";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as Add } from "../../../../../assets/utils/images/svgs/Add.svg";
import nl from "date-fns/locale/nl";
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
registerLocale("nl", nl);

const Step1 = ({ formDataState, setFormDataState, setStep, setOverviewPage, setProcessData }) => {

    const [emailError, setEmailError] = useState(false)
    const [radiobtnErr, setRadiobtnErr] = useState(false)
    const [memoIsOpen, setMemoIsOpen] = useState(false)
    const [checkedStates, setCheckedStates] = useState(
        new Array(formDataState.emailList.length).fill(true)
    );

    const handleApproveClick = (type, value) => {
        setFormDataState({
            ...formDataState,
            [type]: value,
        })
    }

    const toggleInformCheckboxes = (value, type) => {
        setFormDataState({ ...formDataState, [type]: value })
    }

    const setEmailValue = (value) => {
        setFormDataState({ ...formDataState, informEmail: value })
    }

    function validateEmailList() {
        var emails = (formDataState.informEmail).split(',')
        var valid = true;
        var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (var i = 0; i < emails.length; i++) {
            if (emails[i] === "" || !regex.test(emails[i].replace(/\s/g, ""))) {
                valid = false;
            }
        }
        return valid;
    }

    const addEmail = () => {
        if (formDataState.informEmail.trim() !== '') {
            validateEmailList();
            if (validateEmailList() === true) {
                setEmailError(false)
                setFormDataState({
                    ...formDataState,
                    emailList: [...formDataState.emailList, formDataState.informEmail],
                    informEmail: ''
                });
                setCheckedStates((prevState) => [...prevState, true]);
            }
            else {
                setEmailError(true)
            }
        };
    }

    const handleEmailCheckbox = (index) => {
        const updatedCheckedStates = checkedStates.map((item, idx) =>
            idx === index ? !item : item
        );

        setCheckedStates(updatedCheckedStates);
    };

    const handleSubmit = () => {
        var selectedEmails = formDataState.emailList.filter((_, idx) => checkedStates[idx]);

        const isRdApprovedNull = formDataState.rdApproved === null;
        setRadiobtnErr(isRdApprovedNull);

        if (!isRdApprovedNull) {
            setFormDataState({
                ...formDataState,
                informEmail: selectedEmails
            })
            setStep('step2');
        }
    };

    return (
        <div>
            <div className="chat-layout-wrapper">
                <div className="app-inner-layout chat-layout">
                    <Row className="app-inner-layout__wrapper">
                        <Col md="12" sm="12" lg="12" xxl="6">
                            <Card className="main-card mb-3">
                                <CardBody className="p-5 table-responsive travelCost-card-height">
                                    <Row className="mb-2">
                                        <Col md="4" lg="4" className="travelCost-bold-text">
                                            Weekstaten afmelden: *
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text">
                                            Ja
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text">
                                            Nee
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="4" lg="4" className="travelCost-light-text">

                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                            <input type="radio" className="travelCost-customRadio"
                                                checked={formDataState.rdApproved === 1 ? true : false}
                                                onClick={() => { handleApproveClick('rdApproved', 1) }}
                                                style={{ borderColor: radiobtnErr ? "red" : "" }}
                                            />
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center">
                                            <input type="radio" className="travelCost-customRadio"
                                                checked={formDataState.rdApproved === 0 ? true : false}
                                                onClick={() => { handleApproveClick('rdApproved', 0) }}
                                                style={{ borderColor: radiobtnErr ? "red" : "" }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 mb-2">
                                        <Col md="4" lg="4" className="travelCost-bold-text">
                                            Informeren
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text">
                                            Mail
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text">
                                            Bijlage
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="4" lg="4" className="travelCost-light-text">
                                            Coördinator
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                            <Input
                                                type="checkbox"
                                                className="col-md-12"
                                                checked={formDataState.selectCoordinator}
                                                onChange={(e) => { toggleInformCheckboxes(e.target.checked, 'selectCoordinator') }}
                                            />
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center">
                                            <Input
                                                type="checkbox"
                                                className="col-md-12"
                                                checked={formDataState.selectCoordinatorAttach}
                                                onChange={(e) => { toggleInformCheckboxes(e.target.checked, 'selectCoordinatorAttach') }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="4" lg="4" className="travelCost-light-text">
                                            School
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                            <Input
                                                type="checkbox"
                                                className="col-md-12"
                                                checked={formDataState.selectSchool}
                                                onChange={(e) => { toggleInformCheckboxes(e.target.checked, 'selectSchool') }}
                                            />
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center">
                                            <Input
                                                type="checkbox"
                                                className="col-md-12"
                                                checked={formDataState.selectSchoolAttach}
                                                onChange={(e) => { toggleInformCheckboxes(e.target.checked, 'selectSchoolAttach') }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="4" lg="4" className="travelCost-light-text">
                                            Salaris administratie
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                            <Input
                                                type="checkbox"
                                                className="col-md-12"
                                                checked={formDataState.selectSalarisAdmin}
                                                onChange={(e) => {
                                                    toggleInformCheckboxes(e.target.checked, 'selectSalarisAdmin');
                                                }}
                                            />
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center">
                                            <Input
                                                type="checkbox"
                                                className="col-md-12"
                                                checked={formDataState.selectSalarisAdminAttach}
                                                onChange={(e) => { toggleInformCheckboxes(e.target.checked, 'selectSalarisAdminAttach') }}
                                            />
                                        </Col>
                                    </Row>

                                    {formDataState.emailList.map((email, index) => (
                                        <Row key={index} className="align-items-center">
                                            <Col md="5" lg="5" className="travelCost-light-text"></Col>
                                            <Col md="4" lg="4" className="d-flex justify-content-start p-2 informEmail-margin">
                                                <Input
                                                    type="checkbox"
                                                    className="col-md-12"
                                                    checked={checkedStates[index]}
                                                    onChange={() => handleEmailCheckbox(index)}
                                                />
                                                <div className="ps-2">{email}</div>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row className="mb-2 align-items-center">
                                        <Col md="4" lg="4" className="travelCost-bold-text mb-4">
                                            Extra e-mail adressen
                                        </Col>
                                        <Col md="7" lg="7">
                                            <Row className="weekstaten-email-margin">
                                                <Col md="12" lg="12" className="d-flex px-0 align-items-center weekstaten-email-input">
                                                    <Input
                                                        className="travelCost-disabled-text"
                                                        placeholder="lemand@school1.nl. Il@school2.l, ..."
                                                        value={formDataState.informEmail}
                                                        onChange={(e) => { setEmailValue(e.target.value) }}
                                                        style={{ borderColor: emailError ? "red" : "" }}
                                                    />
                                                    <Add className="ms-2" style={{ height: "30px", width: "40px" }} onClick={addEmail} />
                                                </Col>
                                            </Row>
                                            {
                                                emailError &&
                                                <Row className="travelCost-light-text weekstaten-email-margin">
                                                    <Col md="12" lg="12" className="text-danger">
                                                        ongeldig e-mail
                                                    </Col>
                                                </Row>
                                            }
                                            <Row className="travelCost-light-text weekstaten-email-margin">
                                                <Col md="12" lg="12" className="">
                                                    Gerbruik bij meerdere mailadresson een comma als scheidingeroken.                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12" sm="12" lg="12" xxl="6">
                            <Card className="main-card mb-3">
                                <CardBody className="p-5 table-responsive travelCost-card-height">
                                    <Row className="align-items-center">
                                        <Col md="4" lg="4" className="travelCost-light-text">
                                            Salarismutaties
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                            <Input
                                                type="checkbox"
                                                className="col-md-12"
                                                checked={formDataState.rdSalaris === 1 ? true : false}
                                                onChange={(e) => { handleApproveClick('rdSalaris', 1) }}
                                            />
                                            <Label htmlFor="selectCoordinator" className="ms-2">Aangevinkt</Label>
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex justify-content-center">
                                            <Input
                                                type="checkbox"
                                                className="col-md-12"
                                                checked={formDataState.rdSalaris === 0 ? true : false}
                                                onChange={(e) => { handleApproveClick('rdSalaris', 0) }}
                                            />
                                            <Label htmlFor="selectCoordinatorAttach" className="ms-2">Uitgevinkt</Label>
                                        </Col>
                                    </Row>
                                    {(formDataState.selectSalarisAdmin || formDataState.selectCoordinator || formDataState.selectSchool) && <Row className="mt-2 mb-2">
                                        <Col md="4" lg="4" className="travelCost-light-text">
                                            Tekst e-mail
                                        </Col>
                                        <Col md="2" lg="2" className="d-flex">
                                            <EditPen onClick={() => setMemoIsOpen(true)} className="cursor-pointer" />
                                        </Col>
                                    </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="chat-layout-footer verzoken-footer-height">
                    <CardFooter className="chat-layout-footer__inner">
                        <div className="d-flex justify-content-end gap-3">
                            <Button
                                outline
                                className="btn-outline-primary-blue btn-color-style"
                                onClick={handleSubmit}
                                type="button"
                                color="primary"
                            >
                                Volgende
                            </Button>
                            <Button
                                outline
                                className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn mx-0"
                                onClick={() => {
                                    setOverviewPage(true); setProcessData({
                                        iCommunityId: [],
                                        iSchoolIds: [],
                                        iWeekstaatIds: []
                                    })
                                }}
                                type="button"
                                color="primary"
                            >
                                <BackIcon />
                            </Button>
                        </div>
                        <Modal id={"tooltip"} isOpen={memoIsOpen} toggle={() => setMemoIsOpen(!memoIsOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling info-modal-width w-40">
                            <ModalHeader>
                                <div className="modal-header-styling">
                                    Tekst e-mail wijzigen
                                </div>
                                <div className="cross-icon-color">
                                    <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setMemoIsOpen(false)}> </i>
                                </div>
                            </ModalHeader>

                            <ModalBody className="bg-white rounded">
                                <div className="d-flex justify-content-center flex-column">
                                    <textarea onChange={(e) => {
                                        setFormDataState({ ...formDataState, informText: e.target.value })
                                    }}
                                        value={formDataState.informText}
                                        className="form-style opmerking-input-styling my-2 travelCost-light-text w-100"
                                        rows="6"
                                    ></textarea>
                                </div>
                                <ModalFooter>
                                    <Button className="d-flex align-items-center btn-color-style back-btn" size="sm" outline color="primary" onClick={() => setMemoIsOpen(false)}>
                                        Wijzigen
                                    </Button>
                                </ModalFooter>
                            </ModalBody>
                        </Modal>
                    </CardFooter>
                </div>
            </div>
        </div>
    )
};

export default Step1;
