import React, {useEffect} from 'react'
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table'
import { Loader } from 'react-loaders';
import Pagination from './pagination';
import DebouncedInput from 'Components/Forms/DebouncedInput';

const filterTableGeneric = (row, values) => {

    let searchValues = values.split(/(\s+)/).filter( e => e.trim().length > 0);

    return searchValues.every((value)=>{
        return Object.values(row.original).join(" ").toLowerCase().includes(value.toLowerCase());
    });
}

const ReactTableForWeekstaten = ({data, columns, filters, tableClassName, filterRecords, userTablePageSize, totalPageCount, paginate, pageNumber, setPageNumber, pageSizeCount, showLoader, searchHandler}) => {
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [sortingArray, setSortingArray] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(pageSizeCount);
    const tableClass =  tableClassName || "mb-0 table table-bordered table-sm table-striped table-hover";
    const {search = true, columnFilter=false, pagination = true, columnSorting=true } = filters || {};
    const fuzzyFilter = filterRecords ?? filterTableGeneric;
   
    const pointerStyles = {
        userSelect: 'none',
    }

    const reactTablePageSize = (value) => {
        userTablePageSize(value);
    }

    const updatePaginate = (val) => {
        paginate(val);
    }

    const table = useReactTable({
        data,
        columns,
        state: {
            sortingArray,
            globalFilter,
        },
        onSortingChange: setSortingArray,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel:  getFilteredRowModel(),
        getPaginationRowModel:  getPaginationRowModel(),
        getSortedRowModel:  getSortedRowModel(),
    })

    useEffect(() => {
        table.setPageSize(pageSize)
    }, [pageSize]);

    return (
        <div>
            { search ?  <div className="form-group pull-right mb-2">
                <DebouncedInput
                value={globalFilter ?? ''}
                onChange={value => searchHandler(String(value))}
                className="form-control mb-2 search"
                placeholder="Zoeken..." />
            </div> : null }
            <table className={tableClass}>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : (
                                        <div
                                            {...{
                                                style: header.column.getCanSort()
                                                        ? pointerStyles
                                                        : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {
                                                {
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getIsSorted()] ?? null}
                                        </div>
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                { showLoader === false &&
                     <tbody>
                     {
                         table.getRowModel().rows.length ?
                         table.getRowModel().rows.map(row => (
                             <tr key={row.id}>
                                 {row.getVisibleCells().map(cell => (
                                     <td key={cell.id}>
                                         {flexRender(
                                             cell.column.columnDef.cell,
                                             cell.getContext())
                                         }
                                     </td>
                                 ))}
                             </tr>
                         ))
                         : <tr><td colSpan={9} align='center'>Geen data beschikbaar</td></tr>
                     }
                     </tbody>
                }
            </table>
            { showLoader === true &&
                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
            }
            { pagination ? <Pagination table={table} reactTablePageSize={reactTablePageSize} totalPageCount={totalPageCount} updatePaginate={updatePaginate} pageNumber={pageNumber} setPageNumber={setPageNumber} showLoader={showLoader}/> : null }
        </div>
    )
}

export default ReactTableForWeekstaten;
