import {Card, CardBody, CardHeader} from "reactstrap";
import ReactTableGeneric from "Components/ReactTableGeneric";

const SchoolPlanningTable = (props) => {

    if (!props.data || props.data === "undefined" || props.data.school_schedule.length === 0) return null;

    return (
        <Card className="main-card mb-3">
            <CardHeader className="head-text-color">
                <div>
                    <h5 className="menu-header-title text-capitalize">
                        {props.name} ({props.data.school_schedule.length})
                    </h5>
                </div>

            </CardHeader>
            <CardBody>
                <ReactTableGeneric data={props.data.school_schedule} tableClassName="mb-0 table table-borderless table-sm table-hover table-heading-left"
                            columns={props.columns} filters={{search: false, pagination: true, sorting: true }} rowCount={60} 
                />
            </CardBody>
        </Card>
    )
}

export default SchoolPlanningTable;