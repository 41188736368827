import { Table } from "reactstrap";
import React, { useState } from "react";
import { ReactComponent as BasilLockTimeSolid } from "../../../../../assets/utils/images/svgs/basil_lock-time-solid.svg";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as Ok } from "../../../../../assets/utils/images/svgs/Ok.svg";
import TextLimit from "Api/TextLimit";
import { ReactComponent as BlueDelete } from "../../../../../assets/utils/images/svgs/bluedelete.svg";
import { toast } from "react-toastify";
import SweetAlert from 'react-bootstrap-sweetalert';
import VerzoekenService from "Api/Verzoeken";


const LongContractsRequest = ({ list, getRequests, getLongContractDetails, setType }) => {

    const [deleteId, setDeleteId] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const confirmDelete = (id) => {
        setShowModal(true);
        setDeleteId(id);
    }

    const deleteLongContract = (id) => {
        VerzoekenService.deleteLongContractById(id).then((response) => {
            if (response.status === 200) {
                setShowModal(false);
                getRequests();
            }
        })
        .catch((error) => console.log("catch error:", error));
    toast["success"]("langetermijncontract is succesvol verwijderd.")
    }

    return (
        <>
            <Table hover size="sm" borderless className="mb-0">
                <thead>
                    <tr>
                        <th>Vervanger</th>
                        <th>Begindatum</th>
                        <th>Einddatum</th>
                        <th>Wtf MA</th>
                        <th>Wtf DI</th>
                        <th>Wtf WO</th>
                        <th>Wtf DO</th>
                        <th>Wtf VR</th>
                        <th>Wtf TOT</th>
                        <th>Weekstaat</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((block, i) => {

                        return (
                            <tr>
                                <td>
                                    {window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(block.vFirstName, block.vMiddleName, block.vLastName)
                                        : TextLimit.AfwezigeLimitOptionTwo(block.vFirstName, block.vMiddleName, block.vLastName)}
                                </td>
                                <td>{block.dDateBegin}</td>
                                <td>{block.dDateEnd}</td>
                                <td>{(block.iWtfMa / 100000).toLocaleString('nl-NL', { minimumFractionDigits: 5 })}</td>
                                <td>{(block.iWtfDi / 100000).toLocaleString('nl-NL', { minimumFractionDigits: 5 })}</td>
                                <td>{(block.iWtfWo / 100000).toLocaleString('nl-NL', { minimumFractionDigits: 5 })}</td>
                                <td>{(block.iWtfDo / 100000).toLocaleString('nl-NL', { minimumFractionDigits: 5 })}</td>
                                <td>{(block.iWtfVr / 100000).toLocaleString('nl-NL', { minimumFractionDigits: 5 })}</td>
                                <td>{((block.iWtfMa + block.iWtfDi + block.iWtfWo + block.iWtfDo + block.iWtfVr) / 100000).toLocaleString('nl-NL', { minimumFractionDigits: 5 })}</td>
                                <td>{block.eActive === 'Y' ? <Ok /> : '----'}</td>
                                <td>
                                    <span className="me-2 svg-pointer"><BasilLockTimeSolid /></span>
                                    <span className="me-2 svg-pointer" onClick={() => {getLongContractDetails(block.id)
                                        setType('update')
                                    }}><EditPen /></span>
                                    <span className="svg-pointer" onClick={() => confirmDelete(block.id)}><BlueDelete /></span>
                                </td>
                            </tr>

                        )
                    })
                    }
                </tbody>
            </Table>
            <SweetAlert title="Weet je zeker dat je dit wilt verwijderen?" show={showModal}
                type="warning"
                onCancel={() => setShowModal(false)}
                onConfirm={() => deleteLongContract(deleteId)}
                cancelBtnText="Nee"
                confirmBtnText="Ja"
                showCancel={true}
            />
        </>
    );
};

export default LongContractsRequest;