import {Table} from "reactstrap";
import moment from "moment";
import React from "react";

const LongTermRequestTable = ({data}) => {
    if (!data) return null;
    return (
        <Table hover size="sm" borderless className="mb-0">
            <thead>
            <tr>
                <th>Vervanger</th>
                <th>Begindatum</th>
                <th>Einddatum</th>
                <th>Wtf MA</th>
                <th>Wtf DI</th>
                <th>Wtf WO</th>
                <th>Wtf DO</th>
                <th>Wtf VR</th>
                <th>Wtf TOT</th>
            </tr>
            </thead>
            <tbody>
            { data.map((block, i) => {
                    return (
                        <tr>
                            <td className="border-end">{(block.vFirstName ?  block.vFirstName + " ": "")+ (block.vMiddleName ?  block.vMiddleName + " ": "") + (block.vLastName ?  block.vLastName + " ": "")}</td>
                            <td className="border-end">{block.dDateBegin}</td>
                            <td className="border-end">{block.dDateEnd}</td>
                            <td className="border-end">{(block.iWtfMa / 100000).toLocaleString('nl-NL', {minimumFractionDigits: 5} )}</td>
                            <td className="border-end">{(block.iWtfDi / 100000).toLocaleString('nl-NL', {minimumFractionDigits: 5} )}</td>
                            <td className="border-end">{(block.iWtfWo / 100000).toLocaleString('nl-NL', {minimumFractionDigits: 5} )}</td>
                            <td className="border-end">{(block.iWtfDo / 100000).toLocaleString('nl-NL', {minimumFractionDigits: 5} )}</td>
                            <td className="border-end">{(block.iWtfVr / 100000).toLocaleString('nl-NL', {minimumFractionDigits: 5} )}</td>
                            <td className="border-end">{((block.iWtfMa + block.iWtfDi + block.iWtfWo + block.iWtfDo + block.iWtfVr) / 100000).toLocaleString('nl-NL', {minimumFractionDigits: 5} )}</td>
                        </tr>
                    )
                })
            }
            </tbody>
        </Table>
    );
};

export default LongTermRequestTable;