import React, {Fragment, useEffect, useState} from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import axiosInstance from "Api";
import PageHeading from "Layout/AppMain/PageHeading";
import CustomFilter from "Components/ReactTable/Filters/CustomFilters";
import DefaultModal from "Components/Modal/DefaultModal";
import ReactTableGeneric from "Components/ReactTableGeneric";
import {BsArrowRightCircle} from "react-icons/bs";
import TextLimit from "Api/TextLimit";

const Default = () => {
    const [data, setData] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [filterData, setFilter] = useState([]);
    const [modal, setModal] = useState();
    const [current, setCurrent] = useState();
    const [iSubsDateId, setISubsDateId] = useState();

    const toggle = (id) => {
        setModal(!modal);
        setISubsDateId(id);
    }

    const openModel = (iSubdDateId) => {
        setISubsDateId(iSubdDateId);
        setModal(true);
    }

    const handleChange = (event) => {
        setCurrent(event.target.value)
    }

    const select = () => {
        setModal(!modal);
    }

    const handleSubmit = () => {
        let postdata = {
            iSubdateId: iSubsDateId,
            iAdminId: current,
            note: ''
        }
        if (iSubsDateId != '' && current != '') {

            axiosInstance.post('terugkoppeling/update', postdata).then(response => {
                if (response.status == '200') {
                    getTerugkoppelingList();
                }
            }).catch(err => {
                if (err.response) {
                    console.log("errror ");
                }
            }).finally(() => {
                    setModal(!modal);
                }
            );
        }
    }

    const handleSelect = (e) => {
        let selectedValue = e;
        let updatedList = selectedValue
            ? filterData.filter((feedback) => feedback.organization == selectedValue)
            : filterData;
        setData(updatedList);
    }

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Datum',
                accessorKey: 'date', // accessor is the "key" in the data
            },
            {
                header: () => 'Dag',
                accessorKey: 'day', // accessor is the "key" in the data
            },
            {
                header: () => 'Dagdeel',
                accessorKey: 'daypart', // accessor is the "key" in the data
            },
            {
                header: () => 'Verzoek',
                accessorKey: 'verzoek',
            },
            {
                header: () => 'Organisatie',
                accessorKey: 'organization',
                cell: (value) => {
                    return (
                        window.innerWidth > 1250 ?
                        TextLimit.limitStringLargeScreen(value.row.original.organization)
                        : TextLimit.limitStringSmallScreen(value.row.original.organization)
                    )
                }
            },
            {
                header: () => 'School',
                accessorKey: 'afdeling',
                cell: (value) => {
                    return (
                        window.innerWidth > 1250 ?
                        TextLimit.limitStringLargeScreen(value.row.original.afdeling)
                        : TextLimit.limitStringSmallScreen(value.row.original.afdeling)
                    )
                }
            },
            {
                header: () => 'Afwezige',
                accessorKey: 'Te_vervangen',
                cell: (value) => {
                    return (
                        window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                            :TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    )
                }
            },
            {
                header: () => 'Actie',
                accessorKey: 'info',

                cell: (cell) => (
                    <BsArrowRightCircle className="cursor-pointer arrow-icon-size" onClick={() => openModel(cell.row.original.iSubsDateId)}  />
                )
            }
        ],
        []
    );

    useEffect(() => {
        if (data.length === 0) {
            getTerugkoppelingList();
        }
    }, [data]);

    const getTerugkoppelingList = () => {
        axiosInstance.get('terugkoppeling').then((response) => {
            if (response.data.feedback.length > 0) {
                setData(response.data.feedback);
                response.data.feedback.forEach(element => {
                    element.filterName = element.organization;
                    element.filterId = element.organization;
                });

                setFilter(response.data.feedback);

                if (response.data.contractTypes.length > 0) {
                    setContracts(response.data.contractTypes);
                }
            }
        }).catch(() => console.error);
    }

    return (
        <Fragment>
            <PageHeading>
                {(data.length > 0) ? <CustomFilter data={filterData} handleCallback={handleSelect}/> : null}
            </PageHeading>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                               timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12" lg="12">

                                <Card className="main-card mb-3">
                                    <CardBody className="p-3">
                                        <DefaultModal modal={modal} toggle={toggle} select={select}
                                                      handleSubmit={handleSubmit} classes="feedback-modal-style">
                                            <FormGroup>
                                                <div>
                                                    {contracts.length !== 0 ?
                                                        contracts.map(item => (
                                                            <FormGroup check key={item.iAdminId}>
                                                                <Input name="contracts" value={item.iAdminId}
                                                                       type="radio" onClick={handleChange}/>
                                                                {' '}
                                                                <Label check>
                                                                    {item.name}
                                                                </Label>
                                                            </FormGroup>
                                                        )) : null
                                                    }
                                                </div>
                                            </FormGroup>
                                        </DefaultModal>
                                        <ReactTableGeneric 
                                            data={data} 
                                            columns={columns}
                                            tableClassName="mb-0 table table-borderless table-sm table-hover"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
};

export default Default;