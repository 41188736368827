import { Button, CardFooter, InputGroup, Label } from 'reactstrap'
import { ReactComponent as CalendarIcon } from "../../../../../assets/utils/images/svgs/calendar-blue.svg"
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/utils/images/svgs/addBlue.svg"
import NewDocumentTable from './NewDocumentTable';
import DatePicker, { registerLocale } from "react-datepicker";
import { userTabs } from '../utils';

const Step2 = ({setActiveTab}) => {
    return (
        <>
            <div className='row documenten'>
                <div className='col-12'>
                    <NewDocumentTable/>
                </div>
            </div>
            <div className="chat-layout-footer" style={{ height: '80px' }}>
                <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                    <div className="d-flex gap-3 align-items-end">
                        <select className={`form-select`}>
                            <option value={''}>Bestuur</option>
                            <option value='vervanger'>Vervangers</option>
                            <option value='melder'>Melders</option>
                            <option value='coordinator'>Coördinatoren</option>
                        </select>
                        <select className={`form-select`}>
                            <option value={''}>Alle Contracttypen</option>
                            <option value='vervanger'>Vervangers</option>
                            <option value='melder'>Melders</option>
                            <option value='coordinator'>Coördinatoren</option>
                        </select>
                        <div className='d-flex flex-column'>
                            <Label className="overview-page-label">Begindatum</Label>
                            <InputGroup className="periode-input-style">
                                <DatePicker
                                    locale="nl"
                                    dateFormat="dd-MM-yyyy"
                                // selected={new Date(startDate)}
                                // selected={(startDate !== '' && startDate !== undefined) ? new Date(startDate) : new Date()}
                                // onChange={(date) => saveStartDate(date)}
                                />
                                <CalendarIcon />
                            </InputGroup>
                        </div>
                        <div className='d-flex flex-column'>
                            <Label className="overview-page-label">Einddatum</Label>
                            <InputGroup className="periode-input-style">
                                <DatePicker
                                    locale="nl"
                                    dateFormat="dd-MM-yyyy"
                                // selected={new Date(startDate)}
                                // selected={(startDate !== '' && startDate !== undefined) ? new Date(startDate) : new Date()}
                                // onChange={(date) => saveStartDate(date)}
                                />
                                <CalendarIcon />
                            </InputGroup>
                        </div>
                        <select className={`form-select`}>
                            <option value={''}>Alle documenten</option>
                            <option value='1'>Eerste inzet</option>
                            <option value='2'>Nieuwe documenten</option>
                        </select>
                        <Button
                            outline
                            className="btn-outline-primary-blue ms-auto mt-auto"
                            onClick={() => {
                                setActiveTab(userTabs.Instellingen)
                            }}
                            type="button"
                            color="primary"
                        >
                            <BackIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </>
    )
}

export default Step2