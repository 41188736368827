import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import WeekTable from './WeekTable';
import MaandoverzichtTable from './MaandoverzichtTable';
import PeriodeOverzicht from './PeriodeOverzicht';
import axiosInstance from "Api";
import { Table } from "reactstrap";
import moment from "moment";


const Default = () => {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [data, setData] = useState([]);
    const [communityId, setCommunityId] = useState('all');
    const [show, setShow] = useState(false);

    const intiDates = async () => {
        const date = new Date();
        const currentDate = moment(date).format('MM/DD/yyyy');
        let year = date.getFullYear();
        let endDate2 = '';
        let beginDate = '08/01/' + year;

        if (currentDate < beginDate) {
            year = date.getFullYear() - 1;
        }
        beginDate = '08/01/' + year;
        let nextYear = year + 1;
        endDate2 = '07/31/' + nextYear;
        console.log('first' + beginDate);
        setStartDate(beginDate);
        setEndDate(endDate2);
    }
    
    useEffect(() => {
        if( startDate == "" && endDate == "" ){
            intiDates();
        }
        getOverviewPageData();

    }, [startDate, endDate, communityId]);

    const getOverviewPageData = async () => {
        if( startDate != "" && endDate != ""){
            await axiosInstance.get('vervanger/overview?begin=' + moment(startDate).format('DD-MM-yyyy') + '&eind=' + moment(endDate).format('DD-MM-yyyy') + '&iCommunityId=' + communityId).then((response) => {
                if (response.status == 200) {
                    if (response.data.status === true) {
                        setData(response.data.data);
                    }
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }

    const saveStartDate = (date) => {
        setStartDate(moment(date).format("MM/DD/YYYY"));
    }

    const saveEndDate = (date) => {
        setEndDate(moment(date).format("MM/DD/YYYY"));       
    }

    const saveCommunityId = (value) => {
        console.log(value);
        setCommunityId(value);
        isOpen();
    }

    const isOpen = () => {
        console.log('isOpen');
        if (show === false) {
            setShow(true);
        } else {
            setShow(false);
        }
    }


    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Card className="main-card mb-3">
                                <Col md="12">
                                    <CardBody>
                                        <PeriodeOverzicht arrTotal={data.arrTotal !== undefined ? data.arrTotal : []}
                                            communityList={data.bestuur !== undefined ? data.bestuur : []}
                                            saveStartDate={saveStartDate} saveEndDate={saveEndDate}
                                            startDate={startDate} endDate={endDate}
                                            saveCommunityId={saveCommunityId}
                                            show={show}
                                            isOpen={isOpen}
                                            communityId={communityId}
                                        />
                                    </CardBody>
                                </Col>
                            </Card>
                            <Card className="main-card mb-3">
                                <CardHeader className="row m-0">
                                    <div className="col-md-3">
                                        <div className="row">
                                            <span className="col-md-9 ps-0" style={{ color: '#878EA6' }}>
                                                <span className="text-planbord">Maandoverzicht</span>
                                            </span>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Col md="6">
                                    <CardBody>
                                        <MaandoverzichtTable arrMonth={data.arrMonth !== undefined ? data.arrMonth : []} />
                                    </CardBody>
                                </Col>
                                {data.arrWeek !== undefined && Object.keys(data.arrWeek).map(function (list) {
                                    return (
                                        <CardBody className="table-responsive">
                                            <WeekTable list={data.arrWeek[list]} sum={data.arrWeekSum[list] !== undefined ? data.arrWeekSum[list] : null} />
                                        </CardBody>
                                    )
                                })
                                }
                            </Card>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
};

export default Default;