import axiosInstance from 'Api';

const getHistoryWeekstaat = () => {
    return axiosInstance.get('weekstaat?filter=historie')
};

const getCurrentWeekstaat = () => {
    return axiosInstance.get('weekstaat');
};

const downloadReport = (id) => {
    return axiosInstance.get('document/'+id+'/weekstaat');
};

const WeekstaatService = {
    getCurrentWeekstaat,
    getHistoryWeekstaat,
    downloadReport
};
export default WeekstaatService;