import axiosInstance from 'Api';

const getMelderCounts = () => {
    return axiosInstance.get("reports/count");
};

const getSubstitutorCounts = () => {
    return axiosInstance.get("substitutor/count");
};

const CommonService = {
    getMelderCounts,
    getSubstitutorCounts
};
export default CommonService;