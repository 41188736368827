import ReactTableGeneric from 'Components/ReactTableGeneric'
import { Card, CardBody } from 'reactstrap'
import GebruikerFunctiesService from 'Api/Gebruikers/GebruikersView/Functies';
import { useMemo } from 'react';
import { iconSwitch } from '../utils';
import GroupStatus from './GroupStatus';

const Features = ({ data, currentUser, fetchFunctiesData, filterCommunity }) => {

    const changeStatus = async (groupId, vValue) => {
        const data = {
            iAdminId: currentUser.data.clooserId,
            groupId: groupId,
            value: vValue === '+' ? '1' : vValue === '-' ? '0' : '2',
            iCommunityId: String(filterCommunity[0].iCommunityId)
        }
        try {
            const response = await GebruikerFunctiesService.updateGroupStatus(data);
            if (response.data.status) {
                fetchFunctiesData();
            }
            console.log(response.data)
        } catch (error) {
            console.error(`API error:`, error);
        }
    }

    const columns = useMemo(
        () => [
            {
                header: () => 'Functies',
                accessorKey: 'name'
            },
            {
                header: () => 'Functies bestuur',
                accessorKey: 'vValue',
                cell: (value) => (
                    <GroupStatus data={value} changeStatus={changeStatus}/>
                )
            },
            {
                header: () => 'Functies vervanger',
                accessorKey: 'vValueAdmin',
                cell: (value) => (
                    <div className='ms-5'>
                        {iconSwitch(value.row.original.vValueAdmin)}
                    </div>
                )
            }
        ],
        []
    );

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Functies</h5>
                <ReactTableGeneric
                    data={data?.functieLists || []}
                    columns={columns}
                    tableClassName="mb-0 table table-borderless table-sm table-hover"
                    filters={{ search: false, pagination: false, sorting: false }}
                />
                <p className='mt-4' style={{ color: '#026094' }}>Klik op icoontjes om te wijzigen</p>
            </CardBody>
        </Card>
    )
}

export default Features;