import axiosInstance from "Api";

const communityList = () => {
    return axiosInstance.get('travelcost/community-list');
};

const travelCostData = (id) => {
    return axiosInstance.get('travelcost/travelcost-data/' + id);
};

const substitutorList = (id, startDate, endDate) => {
    return axiosInstance.get('travelcost/substitutor-list/' + id + '?startDate=' + startDate + '&endDate=' + endDate);
};

const substitutorShifts = (data) => {
    return axiosInstance.post('travelcost/substitutor-shifts-list', data);
};

const saveData = (data) => {
    return axiosInstance.post('travelcost/save-calculated-travelcost', data);
};

const travelCostSummary = (data) => {
    return axiosInstance.post('travelcost/summary', data);
};

const CalculationService = {
    communityList,
    travelCostData,
    substitutorList,
    substitutorShifts,
    saveData,
    travelCostSummary
};

export default CalculationService;