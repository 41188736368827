import PageHeading from "Layout/AppMain/PageHeading";
import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardBody, Table, CardFooter, Button } from "reactstrap";
import { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { ReactComponent as Arrow } from "../../../../../assets/utils/images/svgs/Arrow 3.svg";
import Loader from "react-loaders";
import moment from "moment";
import TextLimit from "Api/TextLimit";
registerLocale("nl", nl);

const SummaryList = ({ setStep, summary, saveTravelcostData, shiftListLoading, formDataState, selectedTravel, getSubstitutorShifts, setSubstitutorssData }) => {
    const [dayTypes, selectedDayTypes] = useState();

    useEffect(() => {
        extractIdsWithValueOne(selectedTravel)
    }, [])

    function extractIdsWithValueOne(selectedTravel) {
        const idsWithValueOne = [];
        selectedTravel.forEach(obj => {
            if (obj.value === 1) {
                idsWithValueOne.push(obj.name);
            }
        });
        selectedDayTypes(idsWithValueOne)
    }

    const columns = [
        {
            header: () => 'Vervanger',
            accessorKey: 'name',
            cell: (value) => <div>
                {window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    : TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)}
            </div>,
        },
        {
            header: () => 'Woonplaats',
            accessorKey: 'city',
            cell: (value) => <div>{value.row.original.city}</div>,
        },
        {
            header: () => 'E-mail (privé)',
            accessorKey: 'vEmail',
            cell: (value) => <div>{value.row.original.vEmail}</div>,
        },
        {
            header: () => 'CT',
            accessorKey: 'CT',
            cell: (value) => <div>{value.row.original.CT}</div>,
        },
    ];

    const goToLastStep = () => {
        setStep('step2')
    }

    const handleSubmit = () => {
        if (formDataState.automatic == 1) {
            setSubstitutorssData([])
            saveTravelcostData();
        }
        else {
            if (formDataState.substitutorList.length == 0) {
                setSubstitutorssData([])
                saveTravelcostData();
            }
            else {
                setStep('step4');
                getSubstitutorShifts(0)
            }
        }
    }

    return (
        <div>
            <Row>
                <Col md="10" lg="10">
                    <PageHeading />
                </Col>
            </Row>
            {(shiftListLoading || (!summary || summary.length === 0)) ? (
                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
            )
                :
                (
                    <>
                        <Row>
                            <Col md="12" lg="12" >
                                <Card className="main-card mb-3 sub-detail-table-style">
                                    <CardBody className="p-3">
                                        <div className="btn-color-style travelCost-blue-text mb-2 ms-1">
                                            Instellingen deze reiskostenberekening
                                        </div>
                                        <Table size="sm" borderless className="mb-0 ">
                                            <tbody>
                                                <tr className="border-bottom-0">
                                                    <th className="fw-bold col-2">Bestuur</th>
                                                    <th className="fw-bold col-2">Periode</th>
                                                    <th className="fw-bold col-2">Dagtypen </th>
                                                    <th className="fw-bold col-2">Informeren vervanger</th>
                                                </tr>
                                                <tr className="border-bottom-0">
                                                    <td className="wl">
                                                        {formDataState.travelData.communityData.vCommunityName}
                                                    </td>

                                                    <td className="wl">
                                                        {moment(formDataState.startDate, 'YYYY-MM-DD').format('DD-MM-YY') + " tot " + moment(formDataState.endDate, 'YYYY-MM-DD').format('DD-MM-YY')}
                                                    </td>

                                                    <td className="wl">
                                                        {dayTypes && dayTypes.join(', ')}
                                                    </td>

                                                    <td className="wl">
                                                        {(formDataState.email == 1) && "E-mail"} {((formDataState.email == 1) && (formDataState.pdf == 1)) && ","} {(formDataState.pdf == 1) && "PDF in Clooser.nl"}
                                                    </td>
                                                </tr>

                                                <tr className="border-bottom-0">
                                                    <td className="p-0" colSpan="5">
                                                        <Table size="sm" borderless className="mb-0 verzoeken-inner-table">
                                                            <tbody>
                                                                <tr className="border-bottom-0">
                                                                    <th className="fw-bold col-2">Vergoeding per km afstand</th>
                                                                    <th className="fw-bold col-2">Aftrek aantal km</th>
                                                                    <th className="fw-bold col-2">Maximumbedrag per dag</th>
                                                                    <th className="fw-bold col-2">Werkwijze</th>
                                                                </tr>
                                                                <tr className="border-bottom-0">
                                                                    <td className="wl">
                                                                        € {(formDataState.vPrice).replace('.', ',')}
                                                                    </td>

                                                                    <td className="wl">
                                                                        {Number(formDataState.vKm)}
                                                                    </td>

                                                                    <td className="wl">
                                                                        € {String(formDataState.vBedrag).replace('.', ',')}
                                                                    </td>

                                                                    <td className="wl">
                                                                        {(formDataState.automatic == 1) ? "Alle automatisch verwerken" : "Per vervanger verwerken"}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </Table>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" lg="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3 table-responsive">
                                        <ReactTableGeneric
                                            data={summary.summaryList}
                                            columns={columns}
                                            tableClassName="mb-0 table table-sm table-hover table-borderless"
                                            filters={{ search: false, pagination: true, sorting: false }}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )
            }
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-end">
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn me-2"
                            onClick={handleSubmit}
                            type="button"
                            color="primary"
                        >
                            Verwerken
                        </Button>
                        <Button size="sm" outline className="me-2 btn-color-style footer-btn-styling py-1" color="primary">
                            <Arrow onClick={goToLastStep} />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </div>
    )
};

export default SummaryList;
