import React from "react";


import {
    DropdownToggle,
    DropdownMenu,
    Nav,
    NavItem,
    NavLink,
    UncontrolledButtonDropdown,
} from "reactstrap";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import Auth from "../../../services/Auth";

const UserBox = () => {

    const user = Auth.getAuthenticatedUser();
    const profileImage = useSelector(state=>state.UserProfile.profileImage);

    let navigate = useNavigate();


    const handleLogout = () => {
        Auth.logout().then((response) => {
            // TODO: handle correct response
        }).catch(()=>console.error)
            .finally(()=>{
                localStorage.clear();
                navigate("/login");
            });

    };

    return (
        <>
            <div className="header-btn-lg pe-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">

                                <img width={42} height={42} className="rounded-circle profile-pic" src={profileImage} alt=""/>
                                    <FontAwesomeIcon
                                        className="ms-2 opacity-8"
                                        icon={faAngleDown}
                                    />
                                </DropdownToggle>
                                <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                                    <div className="dropdown-menu-header border-bottom">
                                        <div className="dropdown-menu-header-inner">
                                            <div className="menu-header-content text-start">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left me-3">
                                                            <img width={42} height={42} className="rounded-circle profile-pic" src={profileImage} alt=""/>
                                                        </div>
                                                        <div className="widget-content-left text-body">
                                                            <div className="widget-heading">
                                                            {user.vFirstName} {user.vMiddleName} {user.vLastName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Nav vertical>
                                        <NavItem>
                                            <NavLink href="#"  onClick={()=>{
                                                navigate("/profiel", {state: { from: "header-profile"}});
                                            }}>
                                                <i className="nav-link-icon pe-7s-user"> </i>
                                                <span>Profiel</span>
                                            </NavLink>

                                            <NavLink href="#"  onClick={()=>{
                                                navigate("/profiel", {state: { from: "header-password"} });
                                            }}>
                                                <i className="nav-link-icon pe-7s-key"> </i>
                                                <span>Wachtwoord wijzigen</span>
                                            </NavLink>
                                            <NavItem className="nav-item-divider" />
                                            <NavLink href="#"  onClick={handleLogout}>
                                                <span>Uitloggen</span>
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="widget-content-left  ms-3 header-user-info">
                            <div className="widget-subheading">{user.vFirstName} {user.vMiddleName} {user.vLastName}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default UserBox;
