import React, {Fragment} from "react";

// Components
import Default from "./Partials";
import AppFooter from "Layout/AppFooter";

import AppHeader from "../../../../Layout/AppHeader";


// Components
import ThemeOptions from "../../../../Layout/ThemeOptions";

const Show = ({match}) => {
    return (
        <Fragment>
            <AppHeader/>
            <ThemeOptions/>
                    <Default/>
            <AppFooter/>
        </Fragment>
    )
};

export default Show;