export const SET_WEEKSTAAT_COUNT = 'SET_WEEKSTAAT_COUNT';
export const SET_FEEDBACK_COUNT = 'SET_FEEDBACK_COUNT';

export const setWeekstateCount = weekstaatCount => ({
    
    type: SET_WEEKSTAAT_COUNT,
    weekstaatCount
});

export const setFeedbackCount = feedbackCount => ({
    type: SET_FEEDBACK_COUNT,
    feedbackCount
});

export default function MenuCount(state = {
    weekstaatCount: 0,
    feedbackCount : 0
}, action) {
    switch (action.type) {
        case SET_WEEKSTAAT_COUNT:
            return {
                ...state,
                weekstaatCount: action.weekstaatCount,
            };
        case SET_FEEDBACK_COUNT:
            return {
                ...state,
                feedbackCount: action.feedbackCount,
            };
            
        default:
            return state;
    }
}