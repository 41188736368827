import { Button, CardFooter, InputGroup, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap'
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/utils/images/svgs/calendar-blue.svg"
import PeriodOverview from './PeriodOverview'
import MonthlyOverview from './MonthlyOverview'
import WeeklyOverview from './WeeklyOverview'
import DatePicker from "react-datepicker";
import { userTabs } from '../utils';
import { useEffect, useState } from 'react';
import GebruikerUrenoverzichtService from 'Api/Gebruikers/GebruikersView/Urenoverzicht';
import moment from 'moment';
import Loader from 'react-loaders'
import { toast } from 'react-toastify';

const Urenoverzicht = ({ currentUser, adminCommunityList, setActiveTab, setFilterCommunity, filterCommunity }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hoursData, setHoursData] = useState()
    const [loading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleCheckboxChange = (event) => {
        const communityId = Number(event.target.value);
        const communityName = event.target.name;
        if (filterCommunity?.length === 1 && filterCommunity.some(item => item.iCommunityId === communityId)) {
            toast.info("Je moet minimaal 1 bord selecteren.")
            return;
        }

        setFilterCommunity(prevOptions => {
            const exists = prevOptions.some(item => item.iCommunityId === communityId);
            if (exists) {
                return prevOptions.filter(item => item.iCommunityId !== communityId);
            } else {
                return [...prevOptions, { iCommunityId: communityId, vCommunityName: communityName }];
            }
        });
    };

    const intiDates = async () => {
        const date = new Date();
        const currentDate = moment(date).format('MM/DD/yyyy');
        let year = date.getFullYear();
        let endDate2 = '';
        let beginDate = '08/01/' + year;

        if (currentDate < beginDate) {
            year = date.getFullYear() - 1;
        }
        beginDate = '08/01/' + year;
        let nextYear = year + 1;
        endDate2 = '07/31/' + nextYear;
        setStartDate(beginDate);
        setEndDate(endDate2);
    }

    useEffect(() => {
        if (startDate === "" && endDate === "") {
            intiDates();
        }
        fetchUrenoverzichtData();
    }, [startDate, endDate, filterCommunity]);

    const fetchUrenoverzichtData = async () => {
        if (startDate === "" && endDate === "") return;
        setIsLoading(true);
        const communitySelectedIds = filterCommunity.length ===
            adminCommunityList.length ? "all" :
            filterCommunity.map(item => item.iCommunityId).join(',');

        const data = {
            ids: communitySelectedIds,
            adminId: currentUser.data.clooserId,
            startDate: moment(startDate).format('DD-MM-yyyy'),
            endDate: moment(endDate).format('DD-MM-yyyy'),
        };
        try {
            const response = await GebruikerUrenoverzichtService.getUrenoverzichtData(data);
            if (response.data.status) {
                setHoursData(response.data.data)
                setIsLoading(false);
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    };

    return (
        <>
            <div className='urenoverzicht'>
                {loading ?
                    <Card className="main-card">
                        <CardBody className="d-flex flex-column">
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </CardBody>
                    </Card> : <>
                        <div className='row'>
                            <div className='col-12'>
                                <PeriodOverview startDate={startDate} endDate={endDate} data={hoursData?.arrTotal} />
                            </div>
                            <div className='col-12'>
                                <MonthlyOverview data={hoursData?.arrMonth !== undefined ? hoursData.arrMonth : []} />
                            </div>
                            {
                                !Array.isArray(hoursData?.arrWeek) && hoursData &&
                                <div className='col-12 '>
                                    <WeeklyOverview data={hoursData} />
                                </div>
                            }
                        </div>
                    </>
                }
                <div className="chat-layout-footer" style={{ height: '80px' }}>
                    <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                        <div className="d-flex gap-3 align-items-end">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle className="form-select">
                                    Alle besturen
                                </DropdownToggle>
                                <DropdownMenu>
                                    {adminCommunityList.map(item => {
                                        return <DropdownItem key={item.iCommunityId} toggle={false} className='d-flex justify-content-between'>
                                            {item.vCommunityName}
                                            <Input
                                                type="checkbox"
                                                name={item.vCommunityName}
                                                value={item.iCommunityId}
                                                checked={filterCommunity.some(community => community.iCommunityId === item.iCommunityId)}
                                                onChange={handleCheckboxChange}
                                            />
                                        </DropdownItem>
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                            <div className='d-flex flex-column'>
                                <Label className="overview-page-label">Begindatum</Label>
                                <InputGroup className="periode-input-style">
                                    <DatePicker
                                        locale="nl"
                                        dateFormat="dd-MM-yyyy"
                                        selected={startDate ? moment(startDate, "MM/DD/YYYY", true).toDate() : new Date()}
                                        onChange={(date) => {
                                            const formattedDate = moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : '';
                                            setStartDate(formattedDate);
                                        }}
                                    />
                                    <CalendarIcon />
                                </InputGroup>
                            </div>
                            <div className='d-flex flex-column'>
                                <Label className="overview-page-label">Einddatum</Label>
                                <InputGroup className="periode-input-style">
                                    <DatePicker
                                        locale="nl"
                                        dateFormat="dd-MM-yyyy"
                                        selected={endDate ? moment(endDate, "MM/DD/YYYY", true).toDate() : new Date()}
                                        onChange={(date) => {
                                            const formattedDate = moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : '';
                                            setEndDate(formattedDate);
                                        }}
                                    />
                                    <CalendarIcon />
                                </InputGroup>
                            </div>
                            <Button
                                outline
                                className="btn-outline-primary-blue ms-auto mt-auto"
                                onClick={() => {
                                    setActiveTab(userTabs.Matchcodes)
                                }}
                                type="button"
                                color="primary"
                            >
                                <BackIcon />
                            </Button>
                        </div>
                    </CardFooter>
                </div>
            </div>
        </>
    )
}

export default Urenoverzicht