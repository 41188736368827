import {Button, InputGroup} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import React from "react";
registerLocale("nl", nl);

const AbsentDatePicker = (props) => {
    const {currentDate, setCurrentDate} = props;
    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const prevWeek = () => {
        currentDate.setDate(currentDate.getDate() - 7);
        setCurrentDate(new Date(currentDate));
    }

    const nextWeek = () => {
        let monday = getMonday(currentDate.setDate(currentDate.getDate() + 7))
        setCurrentDate(monday);

    }
    const getMonday = (d) => {
        d = new Date(d);
        let day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }
    return (
        <div className="col-md-4">
            <div className="dateRow d-flex justify-content-between align-items-center">
                <div className="buttoncol1 buttoncol">
                    <Button outline className="me-2 btn-outline btn-color-style custom-btn-padding" type="button" color="secondary"
                            onClick={prevWeek}>
                        Vorige
                    </Button>
                </div>
                <div className="inputcol">
                    <InputGroup>
                        <div className="input-group-text">
                            <FontAwesomeIcon icon={faCalendarAlt}/>
                        </div>
                        <DatePicker
                            locale="nl"
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            selected={currentDate}
                            filterDate={isWeekday}
                            onChange={(date) => setCurrentDate(date)}
                        />
                    </InputGroup>
                </div>
                <div className="buttoncol2 buttoncol">
                    <Button outline className="ms-2 btn-outline btn-color-style  custom-btn-padding" color="secondary"
                            onClick={nextWeek}>
                        Volgende
                    </Button>
                </div>
            </div>
        </div>

    )
}
export default AbsentDatePicker;