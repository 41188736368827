import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
const FeedbackNavItem = () => {
  const feedbackCount = useSelector((state) => state.MenuCount.feedbackCount);

  return (
    <div>Terugkoppeling <span className='show-item-count' >
      {feedbackCount > 0 && '(' + feedbackCount  + ')'}</span></div>
  );
}
export default FeedbackNavItem;
