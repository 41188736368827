import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BasicInformation from "./Steps/BasicInformation";
import SubstitutionList from "./Steps/SubstitutionList";
import SummaryList from "./Steps/SummaryList";
import CalculationService from "Api/TravelCost/Calculation";
import Calculate from "./Steps/Calculate";

const Default = () => {

    const [formDataState, setFormDataState] = useState({
        communityList: [],
        travelData: '',
        startDate: '',
        endDate: '',
        datePickerStyle: "travelCost-datePicker travelCost-card-text-width form-control",
        email: 1,
        pdf: 1,
        vKm: 0.00,
        vPrice: 0.00,
        vBedrag: "0.00",
        substitutorList: [],
        selectedIds: [],
        idsToSend: [],
        substitutorShiftData: '',
        editable: null,
        automatic: null,
        vCorrect: "0.00",
        messageVervanger: '',
        messageSalaris: '',
        summary: [],
    });

    const [step, setStep] = useState('step1')
    const [communityId, setCommunityId] = useState('')
    const [selectedTravel, setSelectedTravel] = useState();

    //step 3

    const [shiftListLoading, setShiftListLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    var currentAdminId;

    const [shiftsData, setShiftsData] = useState([]);
    const [substitutorssData, setSubstitutorssData] = useState();
    const [summary, setSummary] = useState([]);

    let navigate = useNavigate();

    useEffect(() => {
        getCommunityList();
        const initialSelectedTravel = [];
        Object.keys(formDataState.travelData && formDataState.travelData.daytypes).forEach((key) => {
            const dayType = formDataState.travelData.daytypes[key];
            initialSelectedTravel[key] = {
                id: dayType.id,
                value: dayType.travel,
                name: dayType.desc
            };
        });
        setSelectedTravel(initialSelectedTravel);
    }, [formDataState.travelData])

    const getCommunityList = () => {
        CalculationService.communityList().then((response) => {
            if (response.data.status === true) {
                setFormDataState({ ...formDataState, communityList: response.data.communityList })
            }
        }).catch(() => console.error);
    }

    const getTravelCostData = (id) => {
        CalculationService.travelCostData(id).then((response) => {
            if (response.data.status === true) {
                setFormDataState({
                    ...formDataState,
                    travelData: response.data.data,
                    vPrice: response.data.data.communityData.vPrice,
                    vKm: response.data.data.communityData.vKm,
                    vBedrag: 9.35
                });
            }
        }).catch(() => console.error);
    }

    const getSubstitutorList = () => {
        setShiftListLoading(true)
        CalculationService.substitutorList(communityId, formDataState.startDate, formDataState.endDate).then((response) => {
            if (response.data.status === true) {
                setFormDataState(prevState => {
                    const updatedState = { ...prevState, substitutorList: response.data.substitutorList };
                    const adminIds = response.data.substitutorList.map(item => item.iAdminId);
                    return { ...updatedState, selectedIds: adminIds };
                });
                setShiftListLoading(false)
            }
        })
            .catch((error) => console.error(error));
    };

    const countVergoeding = (data) => {
        var iMinusDistance = Number(formDataState.vKm);
        var iMaxBedrag = Number(String(formDataState.vBedrag).replace(',', '.'));
        var distance = Number(data.distance, 10) - Number(iMinusDistance, 10);
        if (distance < 0) {
            distance = 0;
        }
        var sum = Number(distance, 10) * Number(String(formDataState.vPrice).replace(',', '.'), 10);
        if (sum > iMaxBedrag) {
            sum = iMaxBedrag;
        }
        sum = parseFloat(Number(sum).toFixed(2))
        return sum;
    }

    const calculateSubTotal = (data) => {
        return data.list.reduce((total, day) => {
            let dayTotal = 0;
            if (day.data.Ochtend) {
                dayTotal += day.data.Ochtend.Vergoeding || 0;
            }
            if (day.data.Middag) {
                dayTotal += day.data.Middag.Vergoeding || 0;
            }
            return total + dayTotal;
        }, 0);
    };

    const getSubstitutorShifts = (index) => {
        currentAdminId = formDataState.selectedIds[index]
        const dataToSend = {
            iCommunityId: communityId,
            startDate: formDataState.startDate,
            iAdminId: [
                currentAdminId
            ],
            endDate: formDataState.endDate,
            vKm: formDataState.vKm,
            vPrice: parseFloat((formDataState.vPrice).replace(',', '.'))
        }
        CalculationService.substitutorShifts(dataToSend).then((response) => {
            if (response.data.status === true) {
                const substitutorShiftData = response.data.data;
                const updatedList = substitutorShiftData.list.map(day => {
                    const updatedDay = { ...day };
                    if (updatedDay.data.Ochtend) {
                        updatedDay.data.Ochtend = {
                          ...updatedDay.data.Ochtend,
                          Vergoeding: countVergoeding(updatedDay.data.Ochtend),
                          vPrice: parseFloat((formDataState.vPrice).replace(',', '.')),
                        };
                      }
                    if (updatedDay.data.Middag) {
                    updatedDay.data.Middag = {
                        ...updatedDay.data.Middag,
                        Vergoeding: countVergoeding(updatedDay.data.Middag),
                        vPrice: parseFloat((formDataState.vPrice).replace(',', '.'))
                    };
                    }

                    return updatedDay;
                });
                substitutorShiftData.list = updatedList;
                const updatedSubstitutorShiftData = {
                    ...substitutorShiftData,
                    list: updatedList,
                    subTotal: calculateSubTotal(substitutorShiftData),
                };

                setFormDataState({
                    ...formDataState,
                    substitutorShiftData: updatedSubstitutorShiftData,
                    messageVervanger: '',
                    messageSalaris: '',
                    vCorrect: '0.00'
                });

                shiftsToSend(substitutorShiftData, currentAdminId)
            }
        })
            .catch((error) => console.error(error));
    };

    const getVDate = (date, shift, schoolName, id) => {
        var getDay = new Date(moment(date, 'DD-MM-YY')).toLocaleDateString("nl-NL", { weekday: 'long' });
        getDay = getDay.charAt(0).toUpperCase() + getDay.slice(1)

        const dateObject = new Date(moment(date, 'DD-MM-YY').format('DD MMMM YYYY'));
        var getDate = dateObject.toLocaleDateString("nl-NL", { day: '2-digit', month: 'long', year: 'numeric' });

        var vDate = getDay + " " + getDate + "||" + shift + "||" + schoolName + "||" + id;
        return vDate;
    }

    const shiftsToSend = (data, currentAdminId) => {
        var ochtendData = data.list.map(item => {
            const { day, data } = item;
            if (data.Ochtend) {
                return {
                    iSubsDateId: data.Ochtend.iSubsDateId,
                    vDate: getVDate(day, data.Ochtend.vShift, data.Ochtend.vSchoolName, data.Ochtend.iSubstitutionId),
                    vCode: data.Ochtend.vCode,
                    iSchoolId: data.Ochtend.iSchoolId,
                    iDistance: data.Ochtend.distance,
                    vPrice: data.Ochtend.vPrice,
                    Vergoeding: Number(data.Ochtend.Vergoeding)
                };
            }
            else {
                return null;
            }
        });
        var middagData = data.list.map(item => {
            const { day, data } = item;
            if (data.Middag) {
                return {
                    iSubsDateId: data.Middag.iSubsDateId,
                    vDate: getVDate(day, data.Middag.vShift, data.Middag.vSchoolName, data.Middag.iSubstitutionId),
                    vCode: data.Middag.vCode,
                    iSchoolId: data.Middag.iSchoolId,
                    iDistance: data.Middag.distance,
                    vPrice: data.Middag.vPrice,
                    Vergoeding: Number(data.Middag.Vergoeding)
                };
            }
            else {
                return null;
            }
        });
        middagData = middagData.filter(item => item !== null);
        ochtendData = ochtendData.filter(item => item !== null);
        setShiftsData([...ochtendData, ...middagData]);
        const shiftsData = ([...ochtendData, ...middagData]);
        setTimeout(() => {
            substitutorsToSend(data, shiftsData, currentAdminId)
        }, 0);
    }

    const substitutorsToSend = (data, shift, currentAdminId) => {
        const newSubstitutor = {
            iDistanceTotal: data.totalAfstand,
            subtotal: calculateSubTotal(data) ? calculateSubTotal(data) : 0.00,
            vCorrect: Number(formDataState.vCorrect),
            total: Number(Number(calculateSubTotal(data)) + Number(formDataState.vCorrect)).toFixed(2),
            iSubstitutorAdminId: currentAdminId,
            messageVervanger: formDataState.messageVervanger,
            messageSalaris: formDataState.messageSalaris,
            shifts: shift
        }
        setSubstitutorssData(prevState => {
            return [...(prevState || []), newSubstitutor];
        });
        setShiftListLoading(false)
    }

    const saveTravelcostData = () => {
        const newSelectedTravel = selectedTravel && selectedTravel.map(item => {
            const { name, ...rest } = item;
            return rest;
        });
        const dataToSend = {
            iCommunityId: communityId,
            vKm: Number(formDataState.vKm),
            vPrice: parseFloat((formDataState.vPrice).replace(',', '.')),
            vBedrag: parseFloat(String(formDataState.vBedrag).replace(',', '.')),
            automatic: formDataState.automatic,
            startDate: formDataState.startDate,
            endDate: formDataState.endDate,
            sendEmail: formDataState.email,
            savePdf: formDataState.pdf,
            vEmail1: '',
            vEmail2: '',
            iSubstitutorAdminIds: formDataState.automatic == 1 ? formDataState.selectedIds : formDataState.idsToSend,
            substitutors: substitutorssData ? substitutorssData : [],
            dayparts: newSelectedTravel
        }
        CalculationService.saveData(dataToSend).then((response) => {
            if (response.data.status === true) {
                navigate("/reiskosten/overzicht")
                setShiftListLoading(false)
            }
        })
            .catch((error) => console.error(error));
    };

    const getTravelCostSummary = () => {
        const dataToSend = {
            iCommunityId: communityId,
            substitutors: formDataState.selectedIds,
        }
        CalculationService.travelCostSummary(dataToSend).then((response) => {
            if (response.data.status === true) {
                setSummary(response.data.data)
                setShiftListLoading(false)
            }
        })
            .catch((error) => console.error(error));
    };

    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    {
                        (step == 'step1') ?
                            <BasicInformation getTravelCostData={getTravelCostData} getSubstitutorList={getSubstitutorList} communityId={communityId} setCommunityId={setCommunityId} setStep={setStep}
                                formDataState={formDataState} setFormDataState={setFormDataState} selectedTravel={selectedTravel} setSelectedTravel={setSelectedTravel} /> :
                            (step == 'step2') ?
                                <SubstitutionList formDataState={formDataState} setStep={setStep} getTravelCostSummary={getTravelCostSummary}
                                    setFormDataState={setFormDataState} shiftListLoading={shiftListLoading}/> :
                                (step == 'step3') ?
                                    <SummaryList setStep={setStep} summary={summary} saveTravelcostData={saveTravelcostData} selectedTravel={selectedTravel} setSubstitutorssData={setSubstitutorssData}
                                        shiftListLoading={shiftListLoading} formDataState={formDataState} getSubstitutorShifts={getSubstitutorShifts}/> :
                                    <Calculate formDataState={formDataState} setFormDataState={setFormDataState} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} saveTravelcostData={saveTravelcostData}
                                        getSubstitutorShifts={getSubstitutorShifts} shiftListLoading={shiftListLoading} setShiftListLoading={setShiftListLoading} substitutorssData={substitutorssData}
                                        shiftsData={shiftsData} setShiftsData={setShiftsData} setSubstitutorssData={setSubstitutorssData} />
                    }
                </CSSTransition>
            </TransitionGroup>
            <ToastContainer style={{ top: "70px" }} />
        </Fragment>
    )
};

export default Default;
