import React, {useEffect, useState} from 'react';

import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Auth from "services/Auth";
import InputWrapper from "Pages/Profile/Forms/InputWrapper";
import SelectWrapper from "Pages/Profile/Forms/SelectWrapper";
import FormStatus from "Pages/Profile/Forms/FormStatus";
import {Button, Col, Container, FormGroup, InputGroup, Label, Row} from "reactstrap";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import axiosInstance from "Api";
import DefaultButton from 'Components/Buttons/DefaultButton';
import moment from 'moment';

const customStyle = {
    paddingRight: "0"
}

const ProfileForm = (props) => {
    // const [user, setUser] = useState({
    //     vFirstName: "",
    //     vMiddleName: "",
    //     vLastName: ""
    // })
    const [message, setMessage] = useState({type: null, text: ''});
    const [user, setUser] = useState({});

    const validationSchema = Yup.object().shape({
        vFirstName: Yup.string().max(50, 'Moet 50 tekens of minder zijn').trim().required('Veld mag niet leeg zijn'),
        vMiddleName: Yup.string().max(50, 'Moet 50 tekens of minder zijn').trim().nullable(true),
        vLastName: Yup.string().max(50, 'Moet 50 tekens of minder zijn').trim().required('Veld mag niet leeg zijn'),
        tAddress: Yup.string().max(500, 'Moet 50 tekens of minder zijn').trim().nullable(true),
        vPostCode: Yup.string().max(10, 'Moet 10 tekens of minder zijn').trim().nullable(true),
        vCity: Yup.string().max(50, 'Moet 50 tekens of minder zijn').trim().nullable(true),
        iMobileNumber: Yup.string().matches(/^[\+]?[0-9]*[-\s\.]?[(]?[0-9]*[)]?[-\s\.]?[-\s\.0-9]*$/gm, 'Geen geldig mobiel/telefoon nummer').max(20, 'Moet 20 tekens of minder zijn').trim().nullable(true),
        vEmail: Yup.string().email('Ongeldig e-mail').max(50, 'Moet 50 tekens of minder zijn').trim().nullable(true),
        vPhonePrivate: Yup.string().matches(/^[\+]?[0-9]*[-\s\.]?[(]?[0-9]*[)]?[-\s\.]?[-\s\.0-9]*$/gm, 'Geen geldig mobiel/telefoon nummer').max(20, 'Moet 20 tekens of minder zijn').trim().nullable(true),
        vBusinessEmail: Yup.string().email('Ongeldig e-mail').max(50, 'Moet 50 tekens of minder zijn').trim().nullable(true),
        vPhoneWork: Yup.string().matches(/^[\+]?[0-9]*[-\s\.]?[(]?[0-9]*[)]?[-\s\.]?[-\s\.0-9]*$/gm, 'Geen geldig mobiel/telefoon nummer').max(20, 'Moet 20 tekens of minder zijn').trim().nullable(true),
    });

    useEffect(() => {
        axiosInstance.get('me').then(response => {
            if (response.status === 200) {
                Object.keys(response.data.user).map(function (key, index) {
                    if (user[key] === null) {
                        user[key] = '';
                    }
                });
                setUser(response.data.user);
            }
        }).catch(e => console.log(e))
    }, [])

    const [dob, setDob] = useState(new Date(user.dBirthdate).toLocaleDateString('es-CL'));

    const cities = [
        {
            key: "",
            value: "--- Kies land ---",
        },
        {
            key: "NL",
            value: "Nederland",
        },
        {
            key: "BE",
            value: "België",
        },
        {
            key: "DE",
            value: "Duitsland",
        }
    ];
    const sex = [
        {
            key: "",
            value: "--- Kies geslacht ---"
        },
        {
            key: "Male",
            value: "Man"
        },
        {
            key: "Female",
            value: "Vrouw"
        },
    ];

    return (
        <Formik
            enableReinitialize
            initialValues={user}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                values.dBirthdate = dob;
                return new Promise(resolve =>
                    setTimeout(async () => {
                        try {
                            const response = await axiosInstance.patch('users/' + props.id, values);
                            if (response.data.status === "success" && response.data.user) {
                                Auth.setAuthenticatedUser(response.data.user);
                                setMessage({type: 'success', text: 'Algemene data succesvol aangepast'})
                            }
                        } catch (err) {
                            if (err.response) {
                                if (err.response.status === 422) {
                                    let errors = {};
                                    for (let key in err.response.data.errors) {
                                        errors[key] = err.response.data.errors[key][0];
                                    }
                                    setErrors(errors);
                                }
                            }
                        }
                        setSubmitting(false);
                        resolve();
                    }, 500)
                );
            }}
        >
            {(formik) => (
                <Form>
                    < Container>
                        {message.type && <FormStatus message={message} status="success" changeMessage={setMessage}/>}
                        <Row>
                            <Col lg="6" className='custom-padding'>
                                <InputWrapper label="Gebruikersnaam">
                                    {user.vUserName}
                                </InputWrapper>
                                <InputWrapper label="Clooser ID">
                                    {user.iAdminId}
                                </InputWrapper>
                                <InputWrapper label="Voornaam">
                                    <Field className={formik.errors.vFirstName && formik.touched.vFirstName ? "form-control is-invalid" : "form-control"} name="vFirstName"/>
                                    <ErrorMessage name="vFirstName" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>

                                <InputWrapper label="Tussenvoegsel">
                                    <Field className={formik.errors.vMiddleName && formik.touched.vMiddleName ? "form-control is-invalid" : "form-control"} name="vMiddleName"/>
                                    <ErrorMessage name="vMiddleName" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>

                                <InputWrapper label="Achternaam">
                                    <Field className={formik.errors.vLastName && formik.touched.vLastName ? "form-control is-invalid" : "form-control"} name="vLastName"/>
                                    <ErrorMessage name="vLastName" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>
                                <SelectWrapper label="Land">
                                    <Field as='select' name="vCountry" className={formik.errors.vCountry && formik.touched.vCountry ? "form-control is-invalid" : "form-select"}>
                                        {cities.map(option => {
                                            return (
                                                <option key={option.key} value={option.key}>
                                                    {option.value}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                    <ErrorMessage name="vCountry" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </SelectWrapper>

                                <InputWrapper label="Adres">
                                    <Field className={formik.errors.tAddress && formik.touched.tAddress ? "form-control is-invalid" : "form-control"} name="tAddress"/>
                                    <ErrorMessage name="tAddress" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>

                                <FormGroup row>
                                    <Label sm={4}>
                                        Postcode + Woonplaats
                                    </Label>
                                    <Col sm={8}>
                                        <Row>
                                            <Col sm="4" className="pe-0">
                                                <Field className={formik.errors.vCity && formik.touched.vCity ? "form-control is-invalid" : "form-control mb-1"} name="vPostCode"/>
                                            </Col>
                                            <Col sm="8">
                                                <Field className="form-control" name="vCity"/>
                                            </Col>
                                        </Row>
                                    </Col>

                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <SelectWrapper label="Geslacht">
                                    <Field as='select' name="vSex" className={formik.errors.vSex && formik.touched.vSex ? "form-control is-invalid" : "form-select"}>
                                        {sex.map(option => {
                                            return (
                                                <option key={option.key} value={option.key}>
                                                    {option.value}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                    <ErrorMessage name="vSex" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </SelectWrapper>
                                <InputWrapper label="Geboortedatum">
                                    <InputGroup>
                                        <div className="input-group-text">
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </div>
                                        <InputMask name="dBirthdate" className={formik.errors.dBirthdate && formik.touched.dBirthdate ? "form-control is-invalid" : "form-control"}
                                                mask="99-99-9999"
                                                defaultValue={dob} onChange={(e) => {
                                            setDob(e.target.value)
                                        }}/>
                                        <ErrorMessage name="dBirthdate" component="small"
                                                    className="block mt-2 invalid-feedback"/>
                                    </InputGroup>
                                </InputWrapper>

                                <InputWrapper label="Mobiele telefoon">
                                    <Field className={formik.errors.iMobileNumber && formik.touched.iMobileNumber ? "form-control is-invalid" : "form-control"} name="iMobileNumber"/>
                                    <ErrorMessage name="iMobileNumber" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>

                                <InputWrapper label="E-mail privé">
                                    <Field className={formik.errors.vEmail && formik.touched.vEmail ? "form-control is-invalid" : "form-control"} name="vEmail"/>
                                    <ErrorMessage name="vEmail" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>

                                <InputWrapper label="Telefoon privé">
                                    <Field className={formik.errors.vPhonePrivate && formik.touched.vPhonePrivate ? "form-control is-invalid" : "form-control"} name="vPhonePrivate"/>
                                    <ErrorMessage name="vPhonePrivate" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>

                                <InputWrapper label="E-mail werk">
                                    <Field className={formik.errors.vBusinessEmail && formik.touched.vBusinessEmail ? "form-control is-invalid" : "form-control"} name="vBusinessEmail"/>
                                    <ErrorMessage name="vBusinessEmail" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>

                                <InputWrapper label="Telefoon werk">
                                    <Field className={formik.errors.vPhoneWork && formik.touched.vPhoneWork ? "form-control is-invalid" : "form-control"} 
                                        name="vPhoneWork"/>
                                    <ErrorMessage name="vPhoneWork" component="small"
                                                className="block mt-2 invalid-feedback"/>
                                </InputWrapper>
                            </Col>

                            <Col lg="12" className="button-main">
                                <DefaultButton type="submit" className="mb-2 me-0 btn-color-style" color="primary">
                                    Aanpassen
                                </DefaultButton>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}              


export default ProfileForm;
