const Video = ({ aspect, link, title, w }) => {
    return (
        <div className={`ratio ratio-${aspect} w-${w}`}>
            <iframe
                src={link}
                title={title}
                allowFullScreen
            ></iframe>
        </div>
    )
}

export default Video;
