import React, { useEffect, useMemo, useState } from "react";
import Default from "../../WeekReportInfo/Partials";
import WeekstatenService from "Api/Weekstaten";

const Step3 = ({ data, processData, setStep, formDataState, setProcessData, setOverviewPage }) => {

    const [showLoader, setShowLoader] = useState(true);
    const totalWeekstats = processData.iWeekstaatIds.length;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [weekReportData, setWeekReportData] = useState();
    const [isDataUpdated, setDataUpdated] = useState(false);

    let requestData =  useMemo(()=>{
        return {
            iWeekstaatId: processData.iWeekstaatIds[currentIndex],
            // "fitems":[3957345,3957344],
            // "itemsp":[3957345,3957344],
            rdApproved: formDataState.rdApproved === 1 ? "Y" : "N",
            // rdActionAfmelden: "on",
            // rdSelection: "automatisch",
            rdSalaris: formDataState.rdSalaris === 1 ? "Y" : "N",
            selectCoordinator: formDataState.selectCoordinator ? "on" : "off",
            selectSchool: formDataState.selectSchool ? "on" : "off",
            selectSalarisAdmin: formDataState.selectSalarisAdmin ? "on" : "off",
            selectCoordinatorAttach: formDataState.selectCoordinatorAttach ? "on" : "off",
            selectSchoolAttach: formDataState.selectSchoolAttach ? "on" : "off",
            selectSalarisAdminAttach: formDataState.selectSalarisAdminAttach ? "on" : "off",
            informEmail: formDataState.informEmail,
            informText: (formDataState?.selectSalarisAdmin || formDataState.selectCoordinator || formDataState.selectSchool) ? formDataState.informText : "",
            dBeginDate: data.dBegin,
            dEndDate: data.dEnd,
            iCommunityId: data.iCommunityId,
        }
    }, [currentIndex, isDataUpdated])

    useEffect(() => {
        setShowLoader(true)
        WeekstatenService.getWeeklyLists(requestData).then((response) => {
            if (response.data.status === true) {
                setShowLoader(false)
                setWeekReportData(response.data.data)
            }
        }).catch(() => console.error);
    }, [requestData])

    return (
        <>
            <Default
                updateHandler={setDataUpdated}
                totalWeekstats={totalWeekstats}
                setCurrentIndex={setCurrentIndex}
                currentIndex={currentIndex}
                weekReportData={weekReportData}
                setStep={setStep}
                showLoader={showLoader}
                setProcessData={setProcessData}
                setOverviewPage={setOverviewPage} 
                setWeekReportData={setWeekReportData}
            />
        </>

    )
};

export default Step3;

