import { useMemo, useState } from 'react';
import { Button, Card, CardBody, CardFooter } from 'reactstrap'
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/utils/images/svgs/addBlue.svg"
import DocTable from './DocTable';
import SubstituteTable from './SubstituteTable';
import DocumentPopup from 'Components/Modal/DocumentModel';
import { useSelector } from 'react-redux';
import { userTabs } from '../utils';
import Loader from 'react-loaders';

const Step1 = ({ documentData = [], currentUser, isLoading, filterCommunity, setFilterCommunity, adminCommunityList, setActiveTab, setDataUpdated }) => {
    const userProfileImage = useSelector((state) => state.UserProfile.profileImage);
    const [openModel, setOpenModel] = useState(false);
    const [file, setFile] = useState("");
    const [image, setImage] = useState(userProfileImage);

    const toggleProfileCropPopup = val => {
        setOpenModel(val);
        if (val === false) {
            setFile("");
        }
    }
    const handleCroppedImage = (val) => {
        if (val !== '') {
            setImage(val);
        }
    }

    const saveFile = (file) => {
        setFile(file);
    }

    const { docsTableList, subsTableList } = useMemo(() => {
        if (!documentData || !Array.isArray(documentData)) return { docsTableList: [], subsTableList: [] };

        return {
            docsTableList: documentData
                .map(item => item.docs)
                .filter(item => item !== null && item !== undefined && item !== ""),
            subsTableList: documentData
                .map(item => item.toegevoegd)
                .filter(item => item !== null && item !== undefined && item !== "")
        };
    }, [documentData]);

    return (
        <>
            {
                isLoading ?
                    <Card className="main-card">
                        <CardBody className="d-flex flex-column">
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </CardBody>
                    </Card> : <>
                        <div className='row documenten'>
                            <div className='col-12'>
                                <DocTable data={docsTableList} community={filterCommunity[0]} currentUser={currentUser} setDataUpdated={setDataUpdated}/>
                            </div>
                            <div className='col-12'>
                                <SubstituteTable data={subsTableList} community={filterCommunity[0]} currentUser={currentUser} setDataUpdated={setDataUpdated}/>
                            </div>
                        </div>
                        <div className="chat-layout-footer">
                            <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                <div className="d-flex gap-3">
                                    <select className={`form-select`} value={filterCommunity[0]?.iCommunityId} onChange={(e) => { setFilterCommunity(adminCommunityList.filter(item => item?.iCommunityId === Number(e.target.value))) }}>
                                        <option value={''} disabled>Alle besturen</option>
                                        {adminCommunityList.map(item => {
                                            return <option value={item.iCommunityId} key={item.iCommunityId}>{item.vCommunityName}</option>
                                        })}
                                    </select>
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue d-flex justify-content-center align-items-center gap-2"
                                        onClick={toggleProfileCropPopup}
                                        type="button"
                                        color="primary"
                                    >
                                        Document <AddIcon />
                                    </Button>
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue ms-auto"
                                        onClick={() => {
                                            setActiveTab(userTabs.Instellingen)
                                        }}
                                        type="button"
                                        color="primary"
                                    >
                                        <BackIcon />
                                    </Button>
                                    <DocumentPopup type="Gebruikers" filterCommunity={filterCommunity} currentUser={currentUser} isOpen={openModel} togglePopup={toggleProfileCropPopup} croppedFile={handleCroppedImage} saveFile={saveFile} doc={file} updateData={setDataUpdated} classes="document-modal-style modal-centered" />
                                </div>
                            </CardFooter>
                        </div>
                    </>
            }

        </>
    )
}

export default Step1