import {Card, CardBody, CardHeader,} from "reactstrap";

import ReactTableGeneric from "Components/ReactTableGeneric";

const BehandeldePlanningTable = (props) => {

    if (!props.data || props.data === "undefined" || props.data.handled_requests.length === 0) return null;

    return (
        <Card className="main-card mb-3">
            <CardHeader className="head-text-color">
                <div>
                    <h5 className="menu-header-title text-capitalize">
                        {props.name} ({props.data.handled_requests.length})
                    </h5>
                </div>

            </CardHeader>
            <CardBody>
                <ReactTableGeneric data={props.data.handled_requests} tableClassName="mb-0 table table-sm table-hover table-heading-left"
                            columns={props.columns} filters={{search: false, pagination: true, sorting: true}} rowCount={60}
                />
            </CardBody>
        </Card>
    )
}

export default BehandeldePlanningTable;