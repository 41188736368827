import React, {useEffect, useMemo, useState} from "react";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Card, CardBody, CardHeader, Col, Row,} from "reactstrap";

import axiosInstance from "Api";
import AbsentDatePicker from "Pages/Melder/Substitutions/Overview/AbsentDatePicker";
import SchoolPlanningTable from "Pages/Melder/Substitutions/Overview/SchoolPlanningTable";
import CentralPlanningTable from "Pages/Melder/Substitutions/Overview/CentralPlanningTable";
import BehandeldePlanningTable from "Pages/Melder/Substitutions/Overview/BehandeldePlanningTable";
import AbsentFilter from "../AbsentFilter";
import {convertHeaders} from "Pages/Melder/Substitutions/Overview/AbsentTableUtils";
import PageHeading from "Layout/AppMain/PageHeading";
import SubstituterSearch from "../../../../../Components/Search/SubRequest"
import Loader from "react-loaders";
import {Link} from "react-router-dom";
import moment from "moment";
import AbsentReactTable from "./AbsentReactTable";
import Tippy from "@tippyjs/react";
import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";
import {BsCheckCircle} from "react-icons/bs";
import Historie from "../../../../../assets/utils/images/svgs/Historie.svg";
import TextLimit from "Api/TextLimit";

const AbsentDashboard = () => {
    const dataNullObject = {
        schools: [],
        central_schedule: [],
        school_schedule: [],
        handled_requests: [],
        absents: {
            headerDates: [],
            data: []
        }
    };
    const [data, setData] = useState(dataNullObject);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [tooltipOpen, setTooltipOpen] = useState();
    const [school, setSchool] = useState(null);
    const [schools, setSchools] = useState([]);
    const [absentData, setAbsentData] = useState([]);
    const [absentHeaders, setAbsentHeaders] = useState([]);
    const [filterData, setFilter] = useState([]);
    const [spinner, setSpinner] = useState(false);

    const absentDataRows = React.useMemo(
        () => absentData,
        [absentData]
    );
    
    const absentSchools = React.useMemo(
        () => schools,
        [schools]
    );

    const absentCols = React.useMemo(
        () => absentHeaders,
        [absentHeaders]
    );

    const handleCallback = (childData) => {
        const dt = currentDate.toLocaleDateString("es-CL");
        let url = new URL(axiosInstance.defaults.baseURL + "requests");
        url.searchParams.append('start-date', dt);
        if (childData) {
            url.searchParams.append('school', childData);
        }

        axiosInstance.get(url).then((response) => {
            setData(response.data);
            setSchool(childData);
            console.log('schools');
            console.log(response.data.schools);
            setAbsentData(response.data.absents.data);
            const headers = convertHeaders(response.data.absents.headerDates);
            setAbsentHeaders(headers);
        }).catch(() => console.error);
    }

    const fetchAbsents = async () => {
        const dt = currentDate.toLocaleDateString("es-CL");
        let url = new URL(axiosInstance.defaults.baseURL + "requests");

        url.searchParams.append('start-date', dt);

        if (school) {
            url.searchParams.append('school', school);
        }

        setSpinner(true);

        const response = await axiosInstance
            .get(url)
            .catch((err) => console.log(err));



        if (response && response.status === 200) {
            if (response.data === null) {
                setAbsentData({errorMessage: "No data"});
                setSpinner(false);
            } else if (
                !response.data.absents.headerDates
                || !response.data.absents.data
                || response.data.absents.headerDates.length !== 5 

            ) {
                setAbsentData({errorMessage:"There is no enough data to build table(Server error)"});
                setSpinner(false);
            } else {
                setData(response.data);

                setFilter(response.data);

                setAbsentData(response.data.absents.data);
                setSchools(response.data.schools);

                const headerDates = JSON.parse(JSON.stringify(response.data.absents.headerDates));
                headerDates.map(data => data.date !== null && (data.date = new Date(moment(data.date, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' }).concat(moment(data.date, 'DD-MM-YYYY').format(" DD-MM-YYYY"))));
                const headers = convertHeaders(headerDates);
                setAbsentHeaders(headers);

                setSpinner(false)
            }
        } else {
            setAbsentData({errorMessage: "Internal Server Error"});
            setSpinner(false);
        }
    }

    const iconSwitch = (param) => {
        switch(param) {
            case 'Openstaand':
                return <Tippy content={param} placement="bottom"><span><HiOutlineDotsCircleHorizontal font-size={"24px"} /></span></Tippy>;
            case 'Afgesloten':
                return <Tippy content={param} placement="bottom"><span><img src={Historie} width="24" /></span></Tippy >;
            case 'Behandeld':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"21px"}/></span></Tippy>;
            default:
              return '--';
        }
    }

    const areEqual = (prevProps, nextProps) => true;

    const Filter = React.memo(props => {
        return (
            props.data.schools != undefined && props.data.schools.length > 0 ?
                <div className="d-flex justify-content-between">
                    <div className="d-flex col-md-3 mb-3">
                        <select className="form-select" onChange={(event) => handleCallback(event.target.value)}>
                            <option value=''>Alle scholen</option>
                            {props.data.schools.map((school, i) => {
                                return (<option value={school.id} key={school.id}> {school.name} </option>)
                            })}
                        </select>
                    </div>
                    <AbsentDatePicker currentDate={currentDate} setCurrentDate={setCurrentDate}/>
                </div> : null
        );
    }, areEqual);

    useEffect(() => {
        fetchAbsents();
    }, [currentDate]);

    const planingColumns = useMemo(
        () => [
            {
                header: 'Id',
                cell: value => {
                    return (
                        <Link
                            to={`/verzoeken/${value.row.original.iSubstitutionId}`}
                            key={value}
                        >
                            {value.row.original.iSubstitutionId}
                        </Link>)
                },
                accessorKey: 'iSubstitutionId',
            },
            {
                header: 'Datum',
                accessorKey: 'dAddedByDir',
            },
            {
                header: 'School',
                accessorKey: 'vSchoolName',
                cell: value => {
                    return (
                        window.innerWidth > 1250 ?
                        TextLimit.limitStringLargeScreen(value.row.original.vSchoolName)
                        : TextLimit.limitStringSmallScreen(value.row.original.vSchoolName)
                        )
                },
            },
            {
                header: 'Afwezige',
                accessorKey: 'vFirstName',
                cell: value => {
                    return (window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    :TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    )
                },
            },
            {
                header: '1e dag',
                accessorKey: 'vSubsDate',
            },
            {
                header: '1e niet ingepland',
                accessorKey: 'vFirstDateNotPlanned',
            },
            {
                header: 'Laatste dag',
                accessorKey: 'vLastDate',
            },
            {
                header: 'Code',
                accessorKey: 'vCode',
                cell: value =>  TextLimit.codeLimitOptionOne(value.row.original.vCode)
            },
            {
                header: 'Status',
                accessorKey: 'eStatus',
                cell: value =>  iconSwitch(value.row.original.eStatus)
            },
        ],
        []
    );

    return (
        <div className="app-main__inner">
            <TransitionGroup>

                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                               timeout={1500} enter={false} exit={false}>
                    <div>
                        <div className="filterRow">
                            <PageHeading>
                                <div className="filter-subsitution-cols d-flex">
                                    <SubstituterSearch/>
                                    <AbsentFilter schoolsListing={data} handleCallback={handleCallback}/>
                                </div>
                            </PageHeading>

                        </div>
                        <Row className="tableMainRow">
                            <Col md="12" lg="12">
                                <Card className="main-card mb-3">
                                    <CardHeader className="row m-0">
                                        <div className="col-md-3">
                                            <div className="row">
                                                <span className="col-md-9 ps-0 head-text-color menu-header-title planbord">
                                                    <span className="text-planbord">Planbord</span>
                                                    <span className="planbord-count">({data.absents.data.length})</span>
                                                </span>
                                            </div>
                                        </div>
                                        <AbsentDatePicker currentDate={currentDate} setCurrentDate={setCurrentDate}/>

                                        {/* <div className="filter-school">
                                            <AbsentFilter schoolsListing={data} handleCallback={handleCallback} />
                                        </div> */}
                                        {/* <div className="row float-end">
                                            <AbsentDatePicker currentDate={currentDate} setCurrentDate={setCurrentDate} />
                                        </div> */}

                                    </CardHeader>
                                    <CardBody className="p-3 d-flex justify-content-center text-capitalize">
                                        {spinner ?
                                            <Loader type="ball-grid-pulse"/>
                                            : absentData.errorMessage ? <div>{absentData.errorMessage}</div> 
                                            : <AbsentReactTable data={absentDataRows} columns={absentCols}
                                                          filters={{search: false, pagination: false, sorting: false}} 
                                                          school={absentSchools}
                                                          tableClassName="absent-table-dashboard mb-0 table table-borderless table-sm subs-main-table" />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="12">
                                <SchoolPlanningTable columns={planingColumns} name="School planning" data={data}
                                                     filters={{search: false, pagination: false, sorting: true}} />
                            </Col>
                            <Col md="12">
                                <CentralPlanningTable columns={planingColumns} name="Centrale planning" data={data}
                                                      filters={{search: false, pagination: true, sorting: true}}/>
                            </Col>
                            <Col md="12">
                                <BehandeldePlanningTable columns={planingColumns} name="Behandelde verzoeken"
                                                         data={data}
                                                         filters={{search: false, pagination: true, sorting: true}}/>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    )
}
export default AbsentDashboard;
