import React, {Fragment} from "react";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Card, CardBody} from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import Compose from "./export";

export default class TabsExample extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <PageHeading/>
                <TransitionGroup>
                    <CSSTransition component="div" className="TabsAnimation" appear={true}
                                   timeout={1500} enter={false} exit={false}>
                        <Card className="main-card mb-3">
                            <CardBody>
                                <Compose/>
                            </CardBody>
                        </Card>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        )
    }
}
