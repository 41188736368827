import React, { useState, useRef, useEffect } from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, Nav, DropdownMenu } from 'reactstrap';
import { ReactComponent as InfoIcon } from '../../../assets/utils/images/svgs/InformationBlue.svg';

const TitleModal = ({ data, title, Icon=<></>}) => {
  const [infoMenu, setInfoMenu] = useState(false);
  const modalRef = useRef(null);
  const btnRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        btnRef.current &&
        !btnRef.current.contains(event.target)
      ) {
        setInfoMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <UncontrolledButtonDropdown isOpen={infoMenu} toggle={() => setInfoMenu((prev) => !prev)}>
      <DropdownToggle color="link" className="p-0" innerRef={btnRef}>
        <div className="cursor-pointer">
          {title === 'Bericht' ? <Icon /> : <InfoIcon />}
        </div>
      </DropdownToggle>
      <DropdownMenu className="rm-pointers p-0 weekstaten-popup-border" innerRef={modalRef}>
        <Nav className="p-2 weekstaten-popup-width flex-column">
          <div className="btn-color-style travelCost-blue-text mb-2 ps-2">{title}</div>
          <div className="travelCost-light-text ps-2 text-break">{data}</div>
        </Nav>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default TitleModal;
