import PageHeading from "Layout/AppMain/PageHeading";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsCheckCircle, BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { Col, Row, Table, CardFooter, Button, Input } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import Loader from "react-loaders";
import TextLimit from "Api/TextLimit";
import { useNavigate } from "react-router-dom";
import ReactTableGeneric from "Components/ReactTableGeneric";
import CompileService from "Api/Weekstaten/Compile";
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";

const Step4 = ({ setStep, formDataState, setFormDataState, communityId, currentIndex, setCurrentIndex, loading, setLoading, getWeeklyschedulelist, weekstaatId }) => {

    const [communityName, setCommunityName] = useState('');
    const [schoolData, setSchoolData] = useState('');
    let navigate = useNavigate();

    useEffect(() => {
        getCommunityName();
        getSchoolData()
    }, [currentIndex])

    const getCommunityName = () => {
        const community = formDataState.communityData.communityList.find(community => community.iCommunityId === communityId);
        if (community) {
            setCommunityName(community.name);
        } else {
            setCommunityName('Community not found');
        }
    };

    const getSchoolData = () => {
        if (formDataState.schoolList.length > 0) {
            const filteredData = formDataState.schoolList.filter(obj => formDataState.selectedIds.includes(obj.iSchoolId));
            const getSchoolData = filteredData.find(data => data.iSchoolId === formDataState.selectedIds[currentIndex]);
            setSchoolData(getSchoolData)
        }
    }

    const columns = [
        {
            header: () => 'Vervanger',
            accessorKey: 'vSubstName',
            cell: (value) => <div>
                <span>{window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    : TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)}
                </span>
            </div>,
        },
        {
            header: () => 'Begindatum',
            accessorKey: 'dDateStart',
            cell: (value) => <div>{value.row.original.dDateStart}</div>,
        },
        {
            header: () => 'Einddatum',
            accessorKey: 'dDateEnd',
            cell: (value) => <div>{value.row.original.dDateEnd}</div>,
        },
        {
            header: () => 'WTF MA',
            accessorKey: 'iWtfMa',
            cell: (value) => <div>{value.row.original.iWtfMa}</div>,
        },
        {
            header: () => 'WTF DI',
            accessorKey: 'iWtfDi',
            cell: (value) => <div>{value.row.original.iWtfDi}</div>,
        },
        {
            header: () => 'WTF WO',
            accessorKey: 'iWtfWo',
            cell: (value) => <div>{value.row.original.iWtfWo}</div>,
        },
        {
            header: () => 'WTF DO',
            accessorKey: 'iWtfDo',
            cell: (value) => <div>{value.row.original.iWtfDo}</div>,
        },
        {
            header: () => 'WTF VR',
            accessorKey: 'iWtfVr',
            cell: (value) => <div>{value.row.original.iWtfVr}</div>,
        },
        {
            header: () => 'WTF TOT',
            accessorKey: 'iWtfSum',
            cell: (value) => <div>{value.row.original.iWtfSum}</div>,
        },
        {
            header: () => 'Salaris',
            accessorKey: 'iAdminId',
            cell: (value) => (
                <>
                    {value.row.original.ePAStatus === "open" ?
                        <Input type="checkbox" className="col-md-12" checked={formDataState.items.includes(value.row.original.id)} onChange={(e) => toggleCheckbox(e.target.checked, value.row.original.id)} />
                        :
                        <BsCheckCircle color='#39A825' fontSize={"16px"} />
                    }
                </>
            ),
        },
    ];

    const toggleCheckbox = (checked, id) => {
        let updatedIds = [...formDataState.itemsp];
        if (checked) {
            updatedIds.push(id);
        } else {
            updatedIds = updatedIds.filter((selectedId) => selectedId !== id);
        }
        setFormDataState({ ...formDataState, itemsp: updatedIds })
    };

    const toggleItemCheckbox = (checked, id) => {
        let updatedIds = [...formDataState.items];
        if (checked) {
            updatedIds.push(id);
        } else {
            updatedIds = updatedIds.filter((selectedId) => selectedId !== id);
        }
        setFormDataState({ ...formDataState, items: updatedIds })
    };

    const iconSwitch = (param) => {
        switch (param) {
            case 'Ingepland':
                return <BsCheckCircle color='#39A825' fontSize={"18px"} />;
            case 'Aangevraagd':
                return <BsQuestionCircle color='#F2983E' font-size={"18px"} />;
            case 'Geannuleerd':
                return <TiDeleteOutline color='#FB2E5F' font-size={"24px"} />;
            default:
                return <span>----</span>;
        }
    }

    const handleButtonClick = () => {
        setLoading(true)
        if (currentIndex == formDataState.selectedIds.length - 1) {
            sessionStorage.setItem('pdfDownloading', true);
            setLoading(false);
            CompileService.generatePDF().then((response) => {
                console.log(response, "this is the response from API")
            }).catch(error => {
                console.log(error)
            });
            navigate("/weekstaten/overzicht");
        }
        else {
            const nextIndex = (currentIndex + 1) % formDataState.selectedIds.length;
            setCurrentIndex(nextIndex);
            getWeeklyschedulelist(nextIndex, weekstaatId, "nextId")
        }
    };

    const handleBackbtn = () => {
        setStep('step3')
        setCurrentIndex(0)
    }

    return (
        <div>
            <Row>
                <Col md="10" lg="10">
                    <PageHeading />
                </Col>
            </Row>
            {loading ?
                <Card className="main-card mb-3 sub-detail-table-style py-3">
                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                </Card>
                :
                (
                    <Row>
                        <Col md="12" lg="12" className="mb-4">
                            <Card className="main-card mb-3 sub-detail-table-style tb-sm-data">
                                <CardBody className="p-3">
                                    <Table size="sm" borderless className="mb-0 ">
                                        <tbody>
                                            <tr className="border-bottom-0">
                                                <th className="fw-bold col-2">Organisatie</th>
                                                <th className="fw-bold col-2">School </th>
                                                <th className="fw-bold col-2">Brin</th>
                                                <th className="fw-bold col-2">Periode</th>
                                            </tr>
                                            <tr className="border-bottom-0">
                                                <td className="wl">
                                                    {communityName}
                                                </td>
                                                <td className="wl">
                                                    {schoolData.vSchoolName}
                                                </td>
                                                <td className="wl">
                                                    {schoolData.vBrinnummer}
                                                </td>
                                                <td className="wl">
                                                    {moment(formDataState.startDate, 'YYYY-MM-DD').format('DD-MM-YY') + " tot " + moment(formDataState.endDate, 'YYYY-MM-DD').format('DD-MM-YY')}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                            {(formDataState.weekList.length === 0) &&
                                <Card className="main-card mb-3 sub-detail-table-style">
                                    <CardBody className="p-3">
                                        <Table size="sm" borderless className="mb-0 ">
                                            <tbody>
                                                <tr className="border-bottom-0">
                                                    <th className="fw-bold col-2">Geen verzoeken met dagdelen en/of langdurige verzoeken gevonden binnen de gewenste periode.</th>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            }
                            {
                                Object.keys(formDataState.weekList && formDataState.weekList).map((key) => (
                                    <>
                                        {
                                            formDataState.weekList[key].items.length !== 0 &&
                                            <Card className="main-card mb-3 sub-detail-table-style">
                                                <CardBody className="p-3">
                                                    <div className="btn-color-style travelCost-blue-text my-2">
                                                        Verzoek {formDataState?.weekList[key].info.iSubstitutionId}
                                                    </div>
                                                    {weekReportData(formDataState, key)}
                                                    <div className="btn-color-style travelCost-blue-text my-2">
                                                        Dagdelen
                                                    </div>
                                                    <Table hover size="sm" className="mb-0 coor-verzoeken-table mt-4">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-lef table-right-border table-bottom-border" colSpan="1">&nbsp;</th>
                                                                <th colSpan="7" className="table-right-border text-center table-bottom-border py-2">Ochtend</th>
                                                                <th colSpan="7" className="text-center table-bottom-border py-2">Middag</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-left table-right-border py-2">Datum</th>

                                                                <th className="py-2">Vervanger</th>
                                                                <th className="py-2">Salaris ID</th>
                                                                <th className="py-2">CT</th>
                                                                <th className="py-2">DT</th>
                                                                <th className="py-2">WTF</th>
                                                                <th className="py-2">Status</th>
                                                                <th className="table-right-border py-2">Salaris</th>

                                                                <th className="py-2">Vervanger</th>
                                                                <th className="py-2">Salaris ID</th>
                                                                <th className="py-2">CT</th>
                                                                <th className="py-2">DT</th>
                                                                <th className="py-2">WTF</th>
                                                                <th className="py-2">Status</th>
                                                                <th className="py-2">Salaris</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.keys(formDataState.weekList[key].items).map((subKey) => (
                                                                <tr>
                                                                    <td className="cursor-pointer table-right-border text-capitalize">
                                                                        {new Date(moment(formDataState.weekList[key].items[subKey].day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}
                                                                        {" " + formDataState.weekList[key].items[subKey].day}
                                                                    </td>

                                                                    {
                                                                        formDataState.weekList[key].items[subKey].data.Ochtend ?
                                                                            <>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Ochtend.vAbsentName}
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Ochtend.vSalarisId}
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Ochtend.ct}
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Ochtend.dt}
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Ochtend.vWtf}
                                                                                </td>
                                                                                <td className="py-1">
                                                                                    {iconSwitch(formDataState.weekList[key].items[subKey].data.Ochtend.vStatus)}
                                                                                </td>
                                                                                <td className="table-right-border py-1">
                                                                                    {
                                                                                        formDataState.weekList[key].items[subKey].data.Ochtend.bBlock ?
                                                                                            "--" :
                                                                                            <>
                                                                                                {formDataState.weekList[key].items[subKey].data.Ochtend.bluebox_status === "idle" ?
                                                                                                    <Input type="checkbox" className="col-md-12"
                                                                                                        checked={formDataState.items.includes(formDataState.weekList[key].items[subKey].data.Ochtend.iSubsDateId)}
                                                                                                        onChange={(e) => toggleItemCheckbox(e.target.checked, formDataState.weekList[key].items[subKey].data.Ochtend.iSubsDateId)} />
                                                                                                    :
                                                                                                    <Input type="checkbox" className="col-md-12"
                                                                                                        checked={true} disabled={true} />
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                            </>
                                                                            :
                                                                            <td className="table-right-border py-1" colSpan={7}></td>
                                                                    }

                                                                    {
                                                                        formDataState.weekList[key].items[subKey].data.Middag ?
                                                                            <>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Middag.vAbsentName}
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Middag.vSalarisId}
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Middag.ct}
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Middag.dt}
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    {formDataState.weekList[key].items[subKey].data.Middag.vWtf}
                                                                                </td>
                                                                                <td className="py-1">
                                                                                    {iconSwitch(formDataState.weekList[key].items[subKey].data.Middag.vStatus)}
                                                                                </td>
                                                                                <td className="py-1">
                                                                                    {
                                                                                        formDataState.weekList[key].items[subKey].data.Middag.bBlock ?
                                                                                            "--" :
                                                                                            <>
                                                                                                {formDataState.weekList[key].items[subKey].data.Middag.bluebox_status === "idle" ?
                                                                                                    <Input type="checkbox" className="col-md-12"
                                                                                                        checked={formDataState.items.includes(formDataState.weekList[key].items[subKey].data.Middag.iSubsDateId)}
                                                                                                        onChange={(e) => toggleItemCheckbox(e.target.checked, formDataState.weekList[key].items[subKey].data.Middag.iSubsDateId)} />
                                                                                                    :
                                                                                                    <Input type="checkbox" className="col-md-12"
                                                                                                        checked={true} disabled={true} />
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                            </>
                                                                            : <td colSpan={7} className="py-1"> </td>
                                                                    }
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        }
                                        {
                                            (formDataState.weekList[key].prolonged.length !== 0) &&
                                            <Card className="main-card mb-3 sub-detail-table-style">
                                                <CardBody className="p-3 table-responsive">
                                                    <div className="btn-color-style travelCost-blue-text my-2">
                                                        Verzoek {formDataState?.weekList[key].info.iSubstitutionId}
                                                    </div>
                                                    {weekReportData(formDataState, key)}
                                                    <div className="btn-color-style travelCost-blue-text my-2 mb-4">
                                                        Lange contracten
                                                    </div>
                                                    <ReactTableGeneric
                                                        data={formDataState.weekList[key].prolonged}
                                                        columns={columns}
                                                        tableClassName="mb-0 table table-sm table-hover table-borderless tb-contract"
                                                        filters={{ search: false, pagination: false, sorting: false }}
                                                    />
                                                </CardBody>
                                            </Card>
                                        }
                                    </>
                                ))
                            }
                        </Col>
                    </Row>
                )
            }
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-end">
                        <div>
                            <Button
                                outline
                                className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn"
                                onClick={() => {
                                    getWeeklyschedulelist(currentIndex, weekstaatId, "refresh")
                                }}
                                type="button"
                                color="primary"
                            >
                                Verversen
                            </Button>
                            <Button
                                outline
                                className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn mx-2"
                                onClick={() => { handleButtonClick() }}
                                type="button"
                                color="primary"
                            >
                                Samenstellen ({currentIndex + 1}/{formDataState.selectedIds.length})
                            </Button>
                            <Button
                                outline
                                className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn mx-0"
                                onClick={() => handleBackbtn()}
                                type="button"
                                color="primary"
                            >
                                <BackIcon />
                            </Button>
                        </div>
                    </div>
                </CardFooter>
            </div>
        </div>
    )
};

export default Step4;

const weekReportData = (formDataState, key) => {
    return (
        <Table size="sm" borderless className="mb-0 tb-sm-data">
            <tbody>
                <tr className="border-bottom-0">
                    <th className="fw-bold col-2">Afwezige</th>
                    <th className="fw-bold col-2">Code </th>
                    <th className="fw-bold col-2">1e Dagdeel</th>
                    <th className="fw-bold col-2">Laatste dagdeel</th>
                    <th className="fw-bold col-2">Planning</th>
                </tr>
                <tr className="border-bottom-0">

                    <td className="wl">
                        {formDataState?.weekList[key].info.vAbsentName}
                    </td>

                    <td className="wl">
                        {formDataState?.weekList[key].info.code}
                    </td>

                    <td className="wl">
                        {formDataState?.weekList[key].info.first_day}
                    </td>

                    <td className="wl">
                        {formDataState?.weekList[key].info.last_day}
                    </td>
                    <td className="wl">
                        {formDataState?.weekList[key].info.iPlanOption}
                    </td>
                </tr>

                <tr className="border-bottom-0 ">
                    <td className="p-0" colSpan="5">
                        <Table size="sm" borderless className="mb-0 mt-2 verzoeken-inner-table">
                            <tbody>
                                <tr className="border-bottom-0">
                                    <th className="fw-bold col-2">Verlof/Verzuim</th>
                                    <th className="fw-bold col-2">Kostenplaats Project</th>
                                    <th className="fw-bold col-2">Memo t.b.v. weekstaat</th>
                                    <th className="fw-bold col-2">Memo t.b.v. salaris administratie</th>
                                    <th className="fw-bold col-2"></th>
                                </tr>
                                <tr className="border-bottom-0">
                                    <td className="wl">
                                        {formDataState?.weekList[key].info.eType}
                                    </td>

                                    <td className="wl">
                                        {formDataState?.weekList[key].info.vKostenplaats}
                                    </td>

                                    <td className="wl">
                                        {formDataState?.weekList[key].info.vMemoWeekstaat}
                                    </td>

                                    <td className="wl">
                                        {formDataState?.weekList[key].info.vMemoSalaris}
                                    </td>
                                    <td className="wl">
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}