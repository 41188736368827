import axiosInstance from "Api";

const communityData = (id) => {
    return axiosInstance.get('weekstaten/samenstellen?iCommunityId=' + id);
};

const schoolList = (id) => {
    return axiosInstance.get('weekstaten/samenstellen/school-list/' + id);
};

const saveWeekstaatSchools = (data) => {
    return axiosInstance.post('weekstaten/samenstellen', data);
};

const weeklyschedulelist = (data) => {
    return axiosInstance.post('weekstaten/samenstellen/get-Weekly-schedule-list', data);
};

const generatePDF = ()=>{
    return axiosInstance.get('weekstaten/generate-pdf');
}

const CompileService = {
    communityData,
    schoolList,
    saveWeekstaatSchools,
    weeklyschedulelist,
    generatePDF
};

export default CompileService;