import axiosInstance from 'Api';

const updateDocumentDate = (data) => {
    return axiosInstance.post("gebruiker/update-date", data)
};

const updateLockState = (data) => {
    return axiosInstance.post("gebruiker/update-lock", data)
}

const moveDocument = (data) => {
    return axiosInstance.post("gebruiker/move-document", data)
}

const deleteDocument = (id, iDocTypeId, iAdminId, iCommunityId, property = "") => {
    return axiosInstance.get(`gebruiker/deleteDocument/${id}?iDocTypeId=${iDocTypeId}&iAdminId=${iAdminId}&iCommunityId=${iCommunityId}&property=${property}`)
}

const GebruikerDocumentenService = {
    updateDocumentDate,
    updateLockState,
    moveDocument,
    deleteDocument
};
export default GebruikerDocumentenService;