import React, { useEffect, useState } from "react";
import { Card, CardBody, Input } from "reactstrap";
import { ReactComponent as PDF_disabled } from "../../../../assets/utils/images/svgs/PDF_disabled.svg";
import { ReactComponent as PDF_upload } from "../../../../assets/utils/images/svgs/PDF_upload.svg";
import { ReactComponent as HistorieBlack } from "../../../../assets/utils/images/svgs/HistorieBlack.svg";
import { BsCheckCircle } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import Tippy from "@tippyjs/react";
import WeekstaatService from "Api/Weekstaat";
import TextLimit from "Api/TextLimit";
import ReactTableForWeekstaten from "Components/ReactTableForWeekstaten";

const Records = ({ data, showLoader, setShowLoader, pageNumber, setPageNumber, totalPageCount, pageSizeCount, setPageSizeCount, processData,processDataHandler, searchHandler}) => {
    
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const iconSwitch = (param) => {
        switch (param) {
            case 'Afgekeurd':
                return <Tippy content={param} placement="bottom"><span><TiDeleteOutline color='#FB2E5F' font-size={"24px"} className="weekstaten-dlt-icon"/></span></Tippy>;
            case 'Goedgekeurd':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"18px"} className="weekstaten-check-icon"/></span></Tippy>;
            case 'Openstaand':
                return <Tippy content={param} placement="bottom"><span><BsQuestionCircle color='#F2983E' font-size={"18px"} /></span></Tippy>;
            case 'Historie':
                return <Tippy content={param} placement="bottom"><span><HistorieBlack /></span></Tippy>;
            default:
                return '--';
        }
    }

    const downloadReport = (id) => {
        WeekstaatService.downloadReport(id).then((response) => {
            if (response.data.file.download !== '') {
                const linkSource = response.data.file.download;
                const downloadLink = document.createElement("a");
                const fileName = "weekstaat-report.pdf";

                downloadLink.href = linkSource;
                
                    downloadLink.download = fileName;
                downloadLink.click();
            }
        }).catch(() => console.error);
    }

    const columns = [
        {
            header: () => 'ID',
            accessorKey: 'id',
            cell: value => (
                <div className="cursor-pointer" onClick={()=>processDataHandler(value.row.original, 'ID_CLICK')}>
                    {value.row.original.id}
                </div>
            )
        },
        {
            header: () => 'Organisatie',
            accessorKey: 'Organisatie',
            cell: value => (
                <div>
                    {value.row.original.vCommunityName}
                </div>
            )
        },
        {
            header: () => 'School',
            accessorKey: 'School',
            cell: (value) => (
                <div>
                    {value.row.original.vSchoolName}
                </div>
            )
        },
        {
            header: () => 'Begindatum',
            accessorKey: 'Begindatum',
            cell: (value) => (
                <div>
                    {value.row.original.dStart}
                </div>
            )
        },
        {
            header: () => 'Einddatum',
            accessorKey: 'Einddatum',
            cell: (value) => (
                <div>
                    {value.row.original.dEnd}
                </div>
            )
        },
        {
            header: () => 'Datum/tijd weekstaat',
            accessorKey: 'Datum/tijd weekstaat',
            cell: (value) => (
                <div>
                    {value.row.original.dCreated}
                </div>
            )
        },
        {
            header: () => 'Status',
            accessorKey: 'Status',
            cell: (value) => (
                <div>
                    {iconSwitch(value.row.original.status)}
                </div>
            )
        },
        {
            header: () => 'Acties',
            accessorKey: 'Acties',
            cell: (value) => (
                <div>
                    <a className="me-3 anchor-action-link"><PDF_disabled onClick={() => { downloadReport(value.row.original.id)}} /></a>
                    <a className="anchor-action-link"><PDF_upload onClick={() => { downloadReport(value.row.original.id) }} /></a>
                </div>
            )
        },
        {
            header: () =>
                <div>
                    <Input
                    type="checkbox"
                    className="col-md-12 me-1"
                    checked={selectAllChecked}
                    onChange={(e) => selectAll(e.target.checked)}
                    />
                    Verwerken
                </div>,
            accessorKey: 'Verwerken',
            cell: (value) => (
                <Input
                    type="checkbox"
                    className="col-md-12"
                    checked={processData?.iWeekstaatIds?.includes(value.row.original.id)}
                    onChange={(e)=>{if(e.target.checked){
                        processDataHandler(value.row.original, 'ADD')
                    }else{
                        processDataHandler(value.row.original, 'REMOVE')
                    }}}
                />
            ),
        }
    ];

    const userTablePageSize = (val) => {
        setPageNumber(1);
        setShowLoader(true);
        setPageSizeCount(val);
    }

    const paginate = (val) => {
        setShowLoader(true);
        setPageNumber(val);
    }

    
    function selectAll(checked){
        setSelectAllChecked(checked);
        if(checked){
            processDataHandler(data.list, 'ADD_ALL')}
        else{
            processDataHandler(data.list, 'REMOVE_ALL')
        }
    };

    useEffect(() => {
        if ((processData?.iWeekstaatIds?.length) !== (data?.list?.length)) {
            setSelectAllChecked(false)
        }
        else {
            setSelectAllChecked(true)
        }
    }, [processData.iWeekstaatIds])

    return (
        <>
            <Card className="main-card mb-3">
                <CardBody className="p-3 table-responsive">
                    <ReactTableForWeekstaten
                        data={data.list}
                        columns={columns}
                        tableClassName="mb-0 table table-sm table-hover table-borderless"
                        filters={{ search: true, pagination: true, sorting: true }}
                        totalPageCount={totalPageCount}
                        userTablePageSize={userTablePageSize}
                        paginate={paginate}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        pageSizeCount={pageSizeCount}
                        showLoader={showLoader}
                        searchHandler={searchHandler}
                    />
                </CardBody>
            </Card>
        </>
    );
};
export default Records;