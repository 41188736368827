import React, { useState } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    Row,
    Button,
    Label,
    Input
} from "reactstrap";
import WeekReportTables from "./WeekReportTables";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import { BsCheckCircle } from "react-icons/bs";
import WeekReportDetails from "./WeekReportDetails";
import WeekReportForm from "./WeekReportForm";
import Loader from "react-loaders";
import { useLocation, useNavigate } from 'react-router';
import axiosInstance from 'Api';
import { toast } from 'react-toastify';
import WeekstatenService from 'Api/Weekstaten';

const Default = ({ weekReportData, showLoader, setStep, setCurrentIndex, currentIndex, totalWeekstats, setOverviewPage, setProcessData, setWeekReportData, updateHandler }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectValue, setSelectValue] = useState('0');
    const [text, setText] = useState(null);
    const [textError, setTextError] = useState('');
    const [unsubscribe, setUnSubscribe] = useState(false);

    const columns = [
        {
            header: () => 'Vervanger',
            accessorKey: 'vSubstName',
        },
        {
            header: () => 'Salaris ID',
            accessorKey: 'vSalarisId',
        },
        {
            header: () => 'Begindatum',
            accessorKey: 'dDateStart',
        },
        {
            header: () => 'Einddatum',
            accessorKey: 'dDateEnd',
        },
        {
            header: () => 'WTF Ma',
            accessorKey: 'iWtfMa',
        },
        {
            header: () => 'WTF Di',
            accessorKey: 'iWtfDi',
        },
        {
            header: () => 'WTF Wo',
            accessorKey: 'iWtfWo',
        },
        {
            header: () => 'WTF Do',
            accessorKey: 'iWtfDo',
        },
        {
            header: () => 'WTF Vr',
            accessorKey: 'iWtfVr',
        },
        {
            header: () => 'Total',
            accessorKey: 'iWtfSum',
        },
        {
            header: () => 'Salaris',
            accessorKey: 'vFake',
            cell: (value) => (
                <>
                    {
                        weekReportData?.disableSalary === "N" ? (value.row.original.ePAStatus === "open" ? (
                            (weekReportData?.chckSalaris === "Y" ? <BsCheckCircle color='#39A825' fontSize={"14px"} /> : <input className="mx-3" type="checkbox" value={value.row.original.id} />)

                        ) : (<BsCheckCircle color='#39A825' fontSize={"14px"} />)) : (
                            value.row.original.ePAStatus !== "open" ? (<BsCheckCircle color='#39A825' fontSize={"14px"} />) : (<input className="mx-3" type="checkbox" disabled />)
                        )
                    }
                </>
            )
        },
    ];

    const updateCurrentIndex = () => {
        if (currentIndex === totalWeekstats - 1) {
            setOverviewPage(true);
            setProcessData({
                iCommunityId: [],
                iSchoolIds: [],
                iWeekstaatIds: []
            })
        }
        else {
            const nextIndex = (currentIndex + 1) % totalWeekstats;
            setCurrentIndex(nextIndex);
            setWeekReportData()
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (selectValue === '0' && !unsubscribe) {
            setTextError('Selecteer een van de opties.')
            return;
        }
        if (selectValue === '2' && !text) {
            setTextError("Het veld 'Weigerbericht' is verplicht.");
            return;
        }
        setTextError(false);

        if (location.pathname !== "/weekstaten/overzicht") {
            const body = {
                accepted: unsubscribe ? 3 : Number(selectValue),
                id: weekReportData.wssid,
                declineMessage: text
            }
            WeekstatenService.weekstatenUpdateStatus(body)
                .then((response) => {
                    if (response.status) {
                        toast.success('Status succesvol bijgewerkt');
                        navigate("/weekstaten/overzicht");
                    }
                })
                .catch(error => {
                    if (error.status === 422) {
                        console.error(error.data.message);
                    } console.error(error);
                });
        } else {
            const msg = {
                declineMessage: text,
                accepted: Number(selectValue)
            }
            axiosInstance.patch('weekstaat-schools/' + weekReportData.wssid, msg)
                .then((response) => {
                    if (response.status) {
                        toast.success('Status succesvol bijgewerkt');
                        updateHandler((prev) => !prev)
                    }
                })
                .catch(error => {
                    if (error.status === 422) {
                        console.error(error.data.message);
                    } console.error(error);
                });
        }
    }

    return (
        <>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div className="app-inner-layout" >
                        <div className="app-inner-layout__wrapper">
                            <Card className="app-inner-layout__content">
                                <>
                                    {(showLoader) ? (
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                                    ) :
                                        <Row className="mb-4">
                                            <Col md={window.innerWidth < 1400 ? "12" : "9"} sm="12"
                                                className={window.innerWidth < 1400 ? "order-2" : "order-1"}
                                            >
                                                {weekReportData && (weekReportData.records?.length === 0) &&
                                                    <CardBody className="p-0">
                                                        <p className="alert alert-secondary">Geen verzoeken met dagdelen en/of langdurige verzoeken gevonden binnen de gewenste periode.</p>
                                                    </CardBody>
                                                }

                                                {weekReportData && weekReportData.records?.map((data, id) => (
                                                    <WeekReportTables key={id} data={data} columns={columns} />
                                                ))}

                                            </Col>
                                            <Col sm="12" md={window.innerWidth < 1400 ? "12" : "3"}
                                                className={window.innerWidth < 1400 ? "order-1" : "order-2"}
                                            >   
                                                <Row>
                                                    <Col xl={12} md={6} sm={12}>
                                                        <WeekReportDetails reportData={weekReportData} />
                                                    </Col>
                                                    <Col xl={12} md={6} sm={12}>
                                                        <WeekReportForm reportData={weekReportData} updateHandler={updateHandler} currentIndex={currentIndex}
                                                        unsubscribe={unsubscribe} selectValue={selectValue} text={text} handleSubmit={handleSubmit} setSelectValue={setSelectValue} setText={setText} setTextError={setTextError} textError={textError} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>}
                                </>
                            </Card>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer" style={{ marginBottom: (location.pathname !== "/weekstaten/overzicht" && weekReportData?.status === "Openstaand") ? '6px' : '-2px' }}>
                <CardFooter className="chat-layout-footer__inner d-flex justify-content-end">
                    {location.pathname !== "/weekstaten/overzicht" ? 
                    (weekReportData?.status === "Openstaand" ? <div className='w-100 d-flex justify-content-between'>
                        <Row className="custom-checkbox">
                            <Label className="col-md-12">Afmelden</Label>
                            <Input type="checkbox" checked={unsubscribe} className="col-md-12"
                                onChange={(e) => {
                                    if (e.target.checked) { setSelectValue('0'); setTextError('') }
                                    setUnSubscribe(prev => !prev)
                                }}
                                defaultChecked
                            />
                        </Row>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn me-2"
                            onClick={handleSubmit}
                            type="button"
                            color="primary"
                        >
                            Toepassen
                        </Button></div> : <></>)
                         : <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn me-2"
                            onClick={updateCurrentIndex}
                            type="button"
                            color="primary"
                        >
                        Verwerken ({currentIndex + 1}/{totalWeekstats})
                    </Button>}
                    <Button
                        outline
                        className="btn-outline-primary-blue btn-color-style d-flex justify-content-center align-items-center gap-2"
                        onClick={() => {
                            if (location.pathname !== "/weekstaten/overzicht") {
                                navigate("/weekstaten/overzicht")
                            } else { setStep('step2') }
                        }}
                        type="button"
                        color="primary"
                    >
                        <BackIcon />
                    </Button>
                </CardFooter>

            </div>
        </>

    )
}
export default Default;