import React, { Fragment, useEffect, useState } from "react";
import AppHeader from "../../../../Layout/AppHeader";
import AppSidebar from "../../../../Layout/AppSidebar";

// Components
import AppFooter from "Layout/AppFooter";
import { ReactComponent as Calendar } from "../../../../assets/utils/images/svgs/calendar-gray.svg";
import { ReactComponent as Setting } from "../../../../assets/utils/images/svgs/setting-gray.svg";
import { ReactComponent as Dashboard } from "../../../../assets/utils/images/svgs/db-gray.svg";
import { ReactComponent as CalendarCheck } from "../../../../assets/utils/images/svgs/calendar-check.svg";
import { ReactComponent as Clock } from "../../../../assets/utils/images/svgs/clock-gray.svg";

import Algemeen from "./Algemeen"
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageHeading from "Layout/AppMain/PageHeading";
import Instellingen from "./Instellingen";
import Documenten from "./Documenten";
import Functies from "./Functies";
import Matchcodes from "./Matchcodes";
import Urenoverzicht from "./Urenoverzicht";
import Beschikbaarheid from "./Beschikbaarheid";
import { useLocation } from "react-router";
import GebruikerViewService from "Api/Gebruikers/GebruikersView";
import { ToastContainer } from "react-toastify";
import { userTabs } from "./utils";

const Gebruikers = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(userTabs.Algemeen);
    const [filterCommunity, setFilterCommunity] = useState([]);
    const [adminCommunityList, setAdminCommunityList] = useState([]);
    const [userDetails, setUserDetails] = useState();
    const [isLoading, setLoader] = useState(true);
    const [tabsLoader, setTabsLoader] = useState(true);
    const currentUser = location.state || {};
    const [isDataUpdated, setDataUpdated] = useState(false);
    useEffect(() => {
        fetchAdminCommunityList();
    }, []);

    const fetchAdminCommunityList = async () => {
        try {
            const response = await GebruikerViewService.communityAdminList(currentUser.data.clooserId);
            if (response.data.status) {
                setAdminCommunityList(response.data.data);
                setTabsLoader(false);
            }
        } catch (error) {
            console.error(`API error:`, error);
            setTabsLoader(false);
        }
    };

    useEffect(() => {
        if (activeTab === userTabs.Algemeen) {
            setFilterCommunity(currentUser.data.communityList);
        } else if (activeTab === userTabs.Matchcodes ||
            activeTab === userTabs.Urenoverzicht) {
            setFilterCommunity(adminCommunityList);
        } else {
            setFilterCommunity([adminCommunityList[0]]);
        }
    }, [activeTab, currentUser]);

    useEffect(() => {
        if (!filterCommunity.length) return;
        fetchUserDetails();
    }, [filterCommunity, isDataUpdated]);

    const fetchUserDetails = async () => {
        if (activeTab === userTabs.Functies || activeTab === userTabs.Beschikbaarheid || 
            activeTab === userTabs.Matchcodes || activeTab === userTabs.Urenoverzicht) {
            return;
        }
        setLoader(true);
        const data = {
            iAdminId: currentUser.data.clooserId,
            iCommunityIds: filterCommunity.map((item) => item.iCommunityId).join(','),
            type: activeTab,
            disableOrg: ""
        };
        try {
            const response = await GebruikerViewService.getGebruikerDetail(data);
            if (response.status === 200) {
                setUserDetails(response.data.data);
                setLoader(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateDataHandler = () => {
        setDataUpdated(prev => !prev)
    }

    const getActiveTab = (tabState) => {
        switch (tabState) {
            case userTabs.Algemeen:
                return <Algemeen data={userDetails} currentUser={currentUser} isLoading={isLoading} setDataUpdated={setDataUpdated}/>
            case userTabs.Instellingen:
                return <Instellingen data={userDetails} setActiveTab={setActiveTab} isLoading={isLoading} adminCommunityList={adminCommunityList} currentUser={currentUser} filterCommunity={filterCommunity} setFilterCommunity={setFilterCommunity} setDataUpdated={updateDataHandler} />
            case userTabs.Documenten:
                return <Documenten currentUser={currentUser} data={userDetails} isLoading={isLoading} setActiveTab={setActiveTab} adminCommunityList={adminCommunityList} filterCommunity={filterCommunity} setFilterCommunity={setFilterCommunity} setDataUpdated={updateDataHandler} />
            case userTabs.Functies:
                return <Functies adminCommunityList={adminCommunityList} setActiveTab={setActiveTab} currentUser={currentUser} filterCommunity={filterCommunity} setFilterCommunity={setFilterCommunity} />
            case userTabs.Beschikbaarheid:
                return <Beschikbaarheid currentUser={currentUser} setActiveTab={setActiveTab} />
            case userTabs.Matchcodes:
                return <Matchcodes setActiveTab={setActiveTab} adminCommunityList={adminCommunityList} currentUser={currentUser} filterCommunity={filterCommunity} setFilterCommunity={setFilterCommunity}/>
            case userTabs.Urenoverzicht:
                return <Urenoverzicht setActiveTab={setActiveTab} adminCommunityList={adminCommunityList} currentUser={currentUser} filterCommunity={filterCommunity} setFilterCommunity={setFilterCommunity} />
            default:
                break;
        }
    }

    const isActiveTab = (currentTab) => {
        return activeTab === currentTab
    }

    return (
        <Fragment>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner gebruikers-view">
                        <PageHeading data={currentUser}/>
                        <TransitionGroup>
                            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                                timeout={1500} enter={false} exit={false}>
                                <Fragment>
                                    <div className="row" style={{ marginBottom: `${activeTab === userTabs.Urenoverzicht || activeTab === userTabs.Beschikbaarheid ? '50px' : '20px'}` }}>
                                        <div className={activeTab === userTabs.Beschikbaarheid ? "col-12" : "col-xxl-10 col-9"}>
                                            {getActiveTab(activeTab)}
                                        </div>
                                        {
                                            activeTab !== userTabs.Beschikbaarheid &&
                                            <div className="col-xxl-2 col-3">
                                                <div className="users-tab">
                                                    {!tabsLoader && <>
                                                        <p className={isActiveTab(userTabs.Algemeen) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Algemeen) }}><Calendar />Algemeen</p>
                                                        {adminCommunityList.length > 0 && <>
                                                            <p className={isActiveTab(userTabs.Instellingen) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Instellingen); setUserDetails(); }}><Setting />Instellingen</p>
                                                            <p className={isActiveTab(userTabs.Documenten) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Documenten); setUserDetails(); }}><Setting />Documenten</p>
                                                            <p className={isActiveTab(userTabs.Functies) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Functies); setUserDetails(); }}><Dashboard />Functies</p>
                                                            <p className={isActiveTab(userTabs.Beschikbaarheid) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Beschikbaarheid); setUserDetails(); }}><CalendarCheck />Beschikbaarheid</p>
                                                            <p className={isActiveTab(userTabs.Matchcodes) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Matchcodes); setUserDetails(); }}><CalendarCheck />Matchcodes</p>
                                                            <p className={isActiveTab(userTabs.Urenoverzicht) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Urenoverzicht); setUserDetails(); }}><Clock />Urenoverzicht</p>
                                                        </>}
                                                    </>}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            </CSSTransition>
                        </TransitionGroup>
                        <AppFooter />
                    </div>
                </div>
            </div>
            <AppFooter />
            <ToastContainer style={{ top: "70px" }} />
        </Fragment >
    )
};

export default Gebruikers;