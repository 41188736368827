import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const PersonalDetails = ({ data, currentUser, changeHandler, errorField }) => {
    const defaultValues = {
        vFirstName: data?.firstName || '',
        vMiddleName: data?.vMiddleName || '',
        vLastName: data?.vLastName || '',
        tAddress: data?.tAddress || '',
        vPostCode: data?.vPostCode || '',
        vCity: data?.vCity || '',
        vCountry: data?.vCountry || '',
        vSex: data?.vSex || '',
        dBirthdate: data?.dBirthdate && data?.dBirthdate !== "Invalid Da" ? moment(data?.dBirthdate, 'DD-MM-YYYY').toDate() : null,
    };

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Persoonsgegevens</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, handleChange, setFieldValue }) => (
                        <Form>
                            <FormGroup className={`form-element ${errorField === 'vFirstName' ? 'error' : ''}`}>
                                <Label>Clooser ID</Label>
                                <h6 className="mb-0">{currentUser.data.clooserId}</h6>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vFirstName' ? 'error' : ''}`}>
                                <Label for="vFirstName">Voornaam</Label>
                                <Input
                                    name="vFirstName"
                                    type="text"
                                    value={values.vFirstName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vFirstName', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vMiddleName' ? 'error' : ''}`}>
                                <Label for="vMiddleName">Tussenvoegsel</Label>
                                <Input
                                    name="vMiddleName"
                                    type="text"
                                    value={values.vMiddleName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vMiddleName', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vLastName' ? 'error' : ''}`}>
                                <Label for="vLastName">Achternaam</Label>
                                <Input
                                    name="vLastName"
                                    type="text"
                                    value={values.vLastName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vLastName', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'tAddress' ? 'error' : ''}`}>
                                <Label for="tAddress">Adres</Label>
                                <Input
                                    name="tAddress"
                                    type="text"
                                    value={values.tAddress}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('tAddress', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vPostCode' ? 'error' : ''}`}>
                                <Label for="vPostCode">Postcode</Label>
                                <Input
                                    name="vPostCode"
                                    type="text"
                                    value={values.vPostCode}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vPostCode', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vCity' ? 'error' : ''}`}>
                                <Label for="vCity">Woonplaats</Label>
                                <Input
                                    name="vCity"
                                    type="text"
                                    value={values.vCity}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vCity', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vCountry' ? 'error' : ''}`}>
                                <Label for="vCountry">Land</Label>
                                <select
                                    className="form-select"
                                    name="vCountry"
                                    value={values.vCountry}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vCountry', e.target.value);
                                    }}
                                >
                                    <option value="">Kies land</option>
                                    <option value="NL">The Netherlands</option>
                                    <option value="BE">Belgium</option>
                                    <option value="GE">Germany</option>
                                </select>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vSex' ? 'error' : ''}`}>
                                <Label for="vSex">Geslacht</Label>
                                <select
                                    className="form-select"
                                    name="vSex"
                                    value={values.vSex}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vSex', e.target.value);
                                    }}
                                >
                                    <option value="">Kies geslacht</option>
                                    <option value="Man">Man</option>
                                    <option value="Female">Vrouw</option>
                                </select>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'dBirthdate' ? 'error' : ''}`}>
                                <Label for="dBirthdate">Geboortedatum</Label>
                                <DatePicker
                                    selected={values.dBirthdate}
                                    onChange={(date) => {
                                        const formattedDate = moment(date).format('DD-MM-YYYY');
                                        setFieldValue("dBirthdate", date);
                                        changeHandler('dBirthdate', formattedDate);
                                    }}
                                    placeholderText={'dd-mm-jjjj'}
                                    locale="nl"
                                    dateFormat="dd-MM-yyyy" 
                                    className="form-control"
                                />
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default PersonalDetails;
