import React, { useState } from "react";
import {
    Button, Card, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import axiosInstance from "Api";
import { useParams } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { ReactComponent as RoundPlus } from "../../../../../assets/utils/images/svgs/RoundPlus.svg";
import { ReactComponent as Save } from "../../../../../assets/utils/images/svgs/save_two.svg";
import { ReactComponent as Small_Arrow } from "../../../../../assets/utils/images/svgs/Small_Arrow.svg";
import { Form, Formik } from "formik";
import { ErrorMessage } from "formik";
import * as Yup from "yup";

const Chat = ({ substitutionComments, setSubstitutionComments, changeColMdSize }) => {

    const [message, setMessage] = useState("");
    const [isEmpty, setIsEmpty] = useState(false);
    const params = useParams();
    const substitutionId = params.id || '';
    const [isShrink, setIsShrink] = useState(true);
    const [isSmall, setIsSmall] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [formDataState, setFormDataState] = useState({
        message: '',
    });

    console.log('formDataState----------------', formDataState);

    const validationSchema = Yup.object().shape({
        message: Yup.string().required("veld is verplicht"),
    });

    const onChangeMessage = (value) => {
        setMessage(value);
        setIsEmpty(false);
    }

    const handleSubmit = () => {
       

        if (message.trim().length) {

            const msg = {
                text: message,
                substitution: substitutionId
            }

            axiosInstance.post('substitutions/' + substitutionId + '/comments', msg)
                .then((response) => {
                    if (response.data.status === "success") {
                        setIsOpen(false)
                        setSubstitutionComments([...substitutionComments, response.data.message])
                        setMessage("")
                        setFormDataState({ ...formDataState, message: '' })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            setIsEmpty(true)
        }
    }

    // if(data.length === 0 ) return null;

    return (
        <Card className={cx("app-inner-layout__sidebar chatbox-fixed chat-box-styling melder-chatbox-scroll", { "position-absolute height-100 end-0": !isShrink }, { "w-50": isSmall })} >
            <div className="app-inner-layout__sidebar-inner d-flex flex-column overflow-hidden coor-chat-memo-box">
                <div className="chat-box-header">
                    <div className="mx-2 d-flex align-items-center">

                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <Button size="sm" outline className="me-4 btn-color-style opmerking-chat-btn" color="primary" onClick={() => setIsOpen(true)}>
                            <RoundPlus />
                            Opmerking
                        </Button>
                    </div>
                </div>

                <div className="scroll-area-lg">
                    <PerfectScrollbar>
                        <div className="p-1 ">
                            <div className="chat-wrapper p-1 d-flex flex-column-reverse melder-chatbox-heigth">
                                {
                                    substitutionComments.map((msg, i) => {
                                        return (
                                            <div key={i} className="chat-box-wrapper mb-3">
                                                <div>
                                                    <small className="opacity-6 text-capitalize">
                                                        <span className="fw-bold me-1 chat-time">{moment(msg.iTimestamp * 1000).format("DD-MM-YYYY")}</span>
                                                        <span class="me-2 chat-time">{moment(msg.iTimestamp * 1000).format("HH:mm")}</span>
                                                        {msg.vMelderName}
                                                    </small>
                                                    <div className="chat-box text-pre-wrap ">
                                                        {msg.vDirComments}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
            <Modal id={"tooltip"} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling">
                <ModalHeader>
                    <div className="modal-header-styling">
                        Opmerking
                    </div>
                    <div className="cross-icon-color">
                        <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setIsOpen(false)}> </i>
                    </div>
                </ModalHeader>

                <ModalBody className="d-flex bg-white rounded">
                    <Formik
                        enableReinitialize
                        initialValues={formDataState}
                        validationSchema={validationSchema}
                        onSubmit={
                            (values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <textarea onChange={(e) => {
                                    onChangeMessage(e.target.value)
                                    setFormDataState({ ...formDataState, message: e.target.value })
                                }}
                                    className="form-style opmerking-input-styling"
                                    rows="6"
                                ></textarea>
                                  <ErrorMessage name="message" component="small" className="block mt-2 invalid-feedback" />
                                <ModalFooter>
                                    <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" type="submit">
                                        <Save />
                                        Opslaan
                                    </Button>
                                    <Button className="d-flex align-items-center btn-color-style back-btn" size="sm" outline color="primary" onClick={() => setIsOpen(false)}>
                                        <Small_Arrow />
                                        Terug
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

            </Modal>
        </Card>
    );
}

export default Chat;
