import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

const HomeItemNavItem = () => {
  const homeItemCount = useSelector((state) => state.VervangerMenuCount?.homeItemCount);
  
  return (
    <div>Home <span className='show-item-count'>
      {homeItemCount > 0 && '(' + homeItemCount + ')'}  </span></div>
  );
}

const styles= {
  marginRight: "10px",
  marginBottom:"10px"
}
export default HomeItemNavItem;
