import { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";
import axiosInstance from "Api";


const UserInfoModal = ({ id, name, substitutionId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [substitution, setSubstitution] = useState();

    useEffect(() => {
        if (!substitution && isOpen) {
            axiosInstance.get('users/' + id + '/' + substitutionId).then((response) => {
                if (response.status == 200) {
                    if (response.data != null && response.data != undefined) {
                        setSubstitution(response.data);
                    }
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }, [substitution, isOpen]);

    const data = [
        {
            title: "Naam",
            value: name
        },
        {
            title: "Woonplaats",
            value: substitution && substitution.vCity
        },
        {
            title: "Geboortedatum",
            value: substitution && substitution.dBirthdate
        },
        {
            title: "ContractType",
            value: substitution && substitution.contractType
        },
        {
            title: "E-mail",
            value: substitution && substitution.vEmail
        },
        {
            title: "Privé telefoon",
            value: substitution && substitution.vPhonePrivate
        },
        {
            title: "Mobiel",
            value: substitution && substitution.iMobileNumber
        },
    ]

    if (id <= 0 && name == '') return "--";
    return (
        <>{substitution &&
            <Modal id={"tooltip-" + id} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className="modal-dialog__over feedback-modal-style">
                <ModalHeader>
                    Gegevens vervanger
                    <div className="cross-icon-color">
                        <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setIsOpen(false)}> </i>
                    </div>
                </ModalHeader>

                <ModalBody className="d-flex bg-white rounded">
                    <div className="tooltip-img me-2">
                        <img src={substitution.vPhoto} alt="user photo" />
                    </div>

                    <Table borderless className="mb-0">
                        <tbody>
                            {data && data.map(({ title, value }) =>
                                <tr key={value}>
                                    <th className="py-2 table-heading" scope="row">{title}</th>
                                    <td className="d-block pt-2 p-0 mx-4 table-data">{value}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
        }
            <span className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>{name} </span>
        </>
    )

}


export default UserInfoModal;
