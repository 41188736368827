import PageHeading from "Layout/AppMain/PageHeading";
import React, { useRef, useState } from "react";
import { Col, Row, Card, CardBody, Label, InputGroup, CardFooter, Button, Input } from "reactstrap";
import InputWrapper from "Pages/Profile/Forms/InputWrapper";
import DropdownList from "react-widgets/DropdownList";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker, { registerLocale } from "react-datepicker";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import nl from "date-fns/locale/nl";
import moment from "moment";
registerLocale("nl", nl);

const BasicInformation = ({ getTravelCostData, getSubstitutorList, formDataState, setFormDataState, selectedTravel, setSelectedTravel, communityId, setCommunityId, setStep }) => {

    const inputRefs = useRef([null, null, null]);
    const [focusedIndex, setFocusedIndex] = useState(null);

    const handleRadioChange = (id, value) => {
        setSelectedTravel(prevState => {
            const updatedTravel = prevState.map(item => {
                if (item.id === id) {
                    return { ...item, value: value };
                }
                return item;
            });
            return updatedTravel;
        });
    };

    const handleEditClick = (index) => {
        if (inputRefs.current[index]) {
            inputRefs.current[index].focus();
            setFocusedIndex(index);
        }
    };

    const handleBlur = () => {
        setFocusedIndex(null);
    };

    const handleSubmit = () => {
        if (formDataState.startDate != "" && formDataState.endDate != "" && communityId != "") {
            setFormDataState({ ...formDataState, datePickerStyle: "travelCost-card-text-width form-control travelCost-datePicker" })
            setStep('step2');
            getSubstitutorList()
        }
        else {
            setFormDataState({ ...formDataState, datePickerStyle: "travelCost-datePicker-error" })
        }
    };

    return (
        <div className="chat-layout-wrapper">
            <div className="app-inner-layout chat-layout">
                <PageHeading />
                <div className="app-inner-layout__wrapper overflow-hidden">
                    <div className="row">
                        <div className="col-xxl-5 col-md-6 col-12">
                            <Card className="main-card mb-3">
                                <CardBody className="p-xl-5 p-4 table-responsive travelCost-card-height berekenen">
                                    <InputWrapper label="Selecteer Organisatie*" className="text-start travelCost-light-text">
                                        <InputGroup>
                                            <DropdownList
                                                placeholder="Kies Organisatie"
                                                className={"travelCost-dropdownList"}
                                                data={formDataState.communityList}
                                                value={communityId && communityId}
                                                dataKey='iCommunityId'
                                                textField='name'
                                                onChange={(nextValue) => {
                                                    getTravelCostData(nextValue.iCommunityId)
                                                    setCommunityId(nextValue.iCommunityId)
                                                }}
                                                required="true"
                                            />
                                        </InputGroup>
                                    </InputWrapper>

                                    <InputWrapper label="Selecteer periode*" className="text-start travelCost-light-text">
                                        <Row>
                                            <Col xxl="6" className="pt-2">
                                                <Label className="export-label-style travelCost-light-text">{"Begindatum"}</Label>
                                                <Col sm={12}>
                                                    <InputGroup>
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </div>
                                                        <DatePicker
                                                            locale="nl"
                                                            dateFormat="dd-MM-yyyy"
                                                            className={formDataState.datePickerStyle}
                                                            selected={(formDataState.startDate !== '' && formDataState.startDate !== undefined) ? new Date(formDataState.startDate) : ''}
                                                            placeholderText={'dd-mm-jjjj'}
                                                            onChange={(val) => {
                                                                setFormDataState({ ...formDataState, startDate: moment(val).format('yyyy-MM-DD') })
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Col>
                                            <Col xxl="6" className="pt-2">
                                                <Label className="export-label-style travelCost-light-text">{"Einddatum"}</Label>
                                                <Col sm={12}>
                                                    <InputGroup>
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </div>
                                                        <DatePicker
                                                            locale="nl"
                                                            dateFormat="dd-MM-yyyy"
                                                            className={formDataState.datePickerStyle}
                                                            selected={(formDataState.endDate !== '' && formDataState.endDate !== undefined) ? new Date(formDataState.endDate) : ''}
                                                            placeholderText={'dd-mm-jjjj'}
                                                            onChange={(val) => {
                                                                setFormDataState({ ...formDataState, endDate: moment(val).format('yyyy-MM-DD') })
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Col>
                                        </Row>
                                        {formDataState.datePickerStyle == "travelCost-datePicker-error" &&
                                            <Row className="text-danger ms-1 mt-2">
                                                Verplicht veld
                                            </Row>
                                        }
                                    </InputWrapper>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-xxl-7 col-md-6 col-12">
                            <Card className="main-card mb-3">
                                <CardBody className="p-xl-5 p-4 table-responsive travelCost-card-height berekenen">
                                    <Row>
                                        <Col xl="12" xxl="6">
                                            <div className="btn-color-style travelCost-blue-text mb-2">
                                                Instellingen
                                            </div>
                                            <Row className="mt-3 mb-2">
                                                <Col md="8" lg="8" className="travelCost-bold-text">
                                                    Dagtypen
                                                </Col>
                                                <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text">
                                                    Ja
                                                </Col>
                                                <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text">
                                                    Nee
                                                </Col>
                                            </Row>
                                            {Object.keys(formDataState.travelData && formDataState.travelData.daytypes && selectedTravel).map((key) => (
                                                <Row className="align-items-center">
                                                    <Col md="8" lg="8" className="travelCost-light-text">
                                                        {formDataState.travelData.daytypes[key]?.desc && formDataState.travelData.daytypes[key].desc}
                                                    </Col>
                                                    <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                        <input type="radio" className="travelCost-customRadio" checked={selectedTravel[key]['value'] == 1} onChange={() => handleRadioChange(formDataState.travelData.daytypes[key].id, 1)} />
                                                    </Col>
                                                    <Col md="2" lg="2" className="d-flex justify-content-center">
                                                        <input type="radio" className="travelCost-customRadio" checked={selectedTravel[key]['value'] == 0} onChange={() => handleRadioChange(formDataState.travelData.daytypes[key].id, 0)} />
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Row className="mt-3 mb-2">
                                                <Col md="8" lg="8" className="travelCost-bold-text">
                                                    Informeren vervanger
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center">
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    E-mail
                                                </Col>
                                                <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                    <input type="radio" className="travelCost-customRadio" checked={formDataState.email} onChange={() => { setFormDataState({ ...formDataState, email: 1 }) }} />
                                                </Col>
                                                <Col md="2" lg="2" className="d-flex justify-content-center">
                                                    <input type="radio" className="travelCost-customRadio" checked={!formDataState.email} onChange={() => { setFormDataState({ ...formDataState, email: 0 }) }} />
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center">
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    PDF in Clooser.nl
                                                </Col>
                                                <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                    <input type="radio" className="travelCost-customRadio" checked={formDataState.pdf} onChange={() => { setFormDataState({ ...formDataState, pdf: 1 }) }} />
                                                </Col>
                                                <Col md="2" lg="2" className="d-flex justify-content-center">
                                                    <input type="radio" className="travelCost-customRadio" checked={!formDataState.pdf} onChange={() => { setFormDataState({ ...formDataState, pdf: 0 }) }} />
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                        <Col xl="12" xxl="6">
                                            <div className="mb-2">
                                            </div>
                                            <Row className="travelCost-card-top">
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    Vergoeding per km afstand
                                                </Col>
                                                <Col md="3" lg="3" className="d-flex">
                                                    <span className="mt-1 me-2">€</span>
                                                    <Input
                                                        name="vPrice"
                                                        ref={(el) => (inputRefs.current[0] = el)}
                                                        style={
                                                            focusedIndex === 0
                                                                ? { width: '60px', height: "28px", border: '1px solid #878EA6', color: '#878EA6', fontWeight: 600 }
                                                                : { width: '60px', height: "28px", border: 'none', color: '#878EA6', fontWeight: 600 }
                                                        }
                                                        className="travelCost-disabled-text"
                                                        value={String(formDataState.vPrice).replace('.', ',')}
                                                        onChange={(e) => {
                                                            setFormDataState({ ...formDataState, vPrice: e.target.value })
                                                        }}
                                                        onBlur={handleBlur}
                                                        disabled={focusedIndex === 0 ? false : true}
                                                    />
                                                </Col>
                                                <Col md="1" lg="1">
                                                    <EditPen onClick={() => handleEditClick(0)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    Aftrek aantal km
                                                </Col>
                                                <Col md="3" lg="3">
                                                    <Input
                                                        name="vKm"
                                                        ref={(el) => (inputRefs.current[1] = el)}
                                                        style={
                                                            focusedIndex === 1
                                                                ? { width: '60px', height: "28px", border: '1px solid #878EA6', color: '#878EA6', fontWeight: 600, marginLeft: "16px" }
                                                                : { width: '60px', height: "28px", border: 'none', color: '#878EA6', fontWeight: 600, marginLeft: "16px" }
                                                        }
                                                        className="travelCost-disabled-text"
                                                        value={formDataState.vKm}
                                                        onChange={(e) => {
                                                            setFormDataState({ ...formDataState, vKm: e.target.value })
                                                        }}
                                                        onBlur={handleBlur}
                                                        disabled={focusedIndex === 1 ? false : true}
                                                    />
                                                </Col>
                                                <Col md="1" lg="1">
                                                    <EditPen onClick={() => handleEditClick(1)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    Maximumbedrag per dag
                                                </Col>
                                                <Col md="3" lg="3" className="d-flex">
                                                    <span className="mt-1 me-2">€</span>
                                                    <Input
                                                        ref={(el) => (inputRefs.current[2] = el)}
                                                        style={
                                                            focusedIndex === 2
                                                                ? { width: '60px', height: "28px", border: '1px solid #878EA6', color: '#878EA6', fontWeight: 600 }
                                                                : { width: '60px', height: "28px", border: 'none', color: '#878EA6', fontWeight: 600 }
                                                        }
                                                        className="travelCost-disabled-text"
                                                        value={String(formDataState.vBedrag).replace('.', ',')}
                                                        onChange={(e) => {
                                                            setFormDataState({ ...formDataState, vBedrag: e.target.value })
                                                        }}
                                                        onBlur={handleBlur}
                                                        disabled={focusedIndex === 2 ? false : true}
                                                    />
                                                </Col>
                                                <Col md="1" lg="1">
                                                    <EditPen onClick={() => handleEditClick(2)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Row className="mt-4 mb-2">
                                            <Col md="12" lg="12" className="travelCost-light-text">
                                                Let op: <span className="travelCost-bold-text">Deze wijziging geldt alleen voor deze reiskostenberekening</span>
                                            </Col>
                                        </Row>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-end">
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn"
                            onClick={handleSubmit}
                            type="button"
                            color="primary"
                        >
                            Volgende
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </div>
    )
};

export default BasicInformation;
