import React from 'react';
import {Col, FormGroup, Label} from "reactstrap";

function InputWrapper(props) {
    const labelCol = props.labelCol || 4;
    return (
        <FormGroup row>
            <Label for={props.id} sm={labelCol} className={props.className}>
                {props.label}
            </Label>
            <Col sm={8} className="">
                {props.children}
            </Col>
        </FormGroup>
    );
}

const customStyle = {
    color: 'red',
}
export default InputWrapper;
